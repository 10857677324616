import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import Locations from "../../../../services/Locations";
import AuthorityService from "@/services/AuthorityService";

export default {
    loadingON({commit}) {
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async fetchListLocations({dispatch, commit,state}, [filter, toast]) {
        state.locationsList = [];
        dispatch('loadingON');
        try {
            const {data} = await Locations.locations(filter);
            commit(types.SET_LOCATIONS_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('locations.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchAllLocationsByIsActive({dispatch, commit, state}, [payload, toast]) {
        state.authoritiesLocations = [];
        try {
            const {data} = await AuthorityService.authoritiesLocations(payload);
            commit(types.SET_LOCATIONS_FOR_FILTERS, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchFilteredLocations({ commit, dispatch }, [filters, toast]) {
        try {
            const {data} = await Locations.locationsFiltered(filters);
            commit(types.SET_LOCATIONS_LIST, data);
        } catch (error) {
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('locations.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchSLocations({ commit, dispatch }, [filters, toast]) {
        try {
            const {data} = await Locations.locationsFiltered({...filters, light: true, paginated: false});
            commit(types.SET_S_LOCATIONS, data);
        } catch (error) {
            commit(types.SET_S_LOCATIONS, []);
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('locations.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchFilteredLocationsForFilters({ commit }, [filters, toast]) {
        try {
            const {data} = await Locations.locationsFiltered(filters);
            commit(types.SET_LOCATIONS_LIST_FOR_FILTERS, data);
        } catch (error) {
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('locations.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchLocationById({dispatch}, [id, toast]) {
        dispatch('loadingON', true);
        try {
            const {data} = await Locations.getById(id);
            return data;
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('locations.notification.listError'), {
                    queueable: true
                });
        } finally {
            dispatch('loadingOFF', false);
        }
    },
    async fetchListVersions({dispatch, commit, state}, [filters, toast]) {
        try {
            const {data} = await Locations.locationsVersions(filters);
            commit(types.SET_VERSIONS_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchPersonsInCharge({dispatch, commit,state}, [_data, toast]) {
        return new Promise((resolve, reject) => {
            Locations.getPersonsInChargeByLocation(_data)
              .then(res => {
                  commit(types.SET_PERSONS_IN_CHARGE, res.data);
                  resolve(res.data)
              }).catch((err) => {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('locations.notification.listError'), {
                        queueable: true
                    });

                commit(types.RESTORE_PERSONS_IN_CHARGE, data);
                reject(err)
            })
        })
    },
    async fetchAccessLocationByUser({dispatch, commit,state}, [filters, toast]) {
        return new Promise((resolve, reject) => {
            Locations.locationsFiltered(filters)
              .then(res => {
                  commit(types.SET_LOCATION_BY_USER_ACCESS, res.data);
                  resolve(res.data)
              })
              .catch((err) => {
                  if (error.code !== 'ERR_CANCELED')
                    toast.error(i18n.t('locations.notification.listError'), {
                        queueable: true
                    });

                commit(types.RESTORE_LOCATION_BY_USER_ACCESS);
                reject(err)
            })
        })
    },
    async exportCSV({dispatch, commit,state}, [language, toast]) {
        try {
            const { data } = await Locations.exportCSV(language);
            const FileSaver = require('file-saver');
            const blob = new Blob([data], {
                type: 'application/xlsx'
            });
            FileSaver.saveAs(blob, `${i18n.t('locations.label').toLowerCase()}.xlsx`);

            toast.success(i18n.t('locations.notification.export'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('locations.notification.exportError'), {
                    queueable: true
                });
        }
    },
    async fetchListActiveLocations({dispatch, commit, state}, [filter, toast]) {
        state.activeLocationsList = [];
        dispatch('loadingON');
        try {
            const {data} = await Locations.activeLocations(filter);
            commit(types.SET_ACTIVE_LOCATIONS_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('locations.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveLocations({commit, state}, [item, editOrSave, document_logo, logo_file, document_json, document_file, badges_file, geobufData, toast]) {
        try {
            const data = await Locations.save(item, editOrSave, document_logo, logo_file, document_json, document_file, badges_file, geobufData);
            toast.success(i18n.t('locations.notification.save'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });

            return !!data;
        } catch (error) {
            switch (error.response.data) {
                case "LOCATION_IN_USE":
                    toast.error(i18n.t('locations.notification.inUse'), {
                        queueable: true,
                        color: 'gray'
                    });
                    break;
                case "LOCATION_NAME_ALREADY_EXISTS":
                case "LOCATION_GLN_ALREADY_EXISTS":
                    toast.error(i18n.t(`notifications.locations.406.${error.response.data}`), {
                        queueable: true,
                        color: 'gray'
                    })
                    break;
                default:
                    toast.error(i18n.t('locations.notification.saveError'), {
                        queueable: true
                    });
                    break;
            }
        }
    },
    async changeActiveValue({dispatch, commit, state}, [id_store, status_value, toast]) {
        dispatch('loadingON');
        try {
            await Locations.changeActiveValue(id_store, status_value);
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('locations.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('locations.notification.saveError'), {
                        queueable: true
                    });
            }
        }
    },
    async fetchClassificationTypes({commit}) {
        await Locations.getClassificationTypes()
          .then(res => {
              console.log(res)
              commit(types.SET_CLASSIFICATIONS, res.data);
          })
    },

    async fetchBadgeClassifications({commit}) {
        await Locations.getBadgeClassifications()
          .then(res => {
              console.log(res)
              commit(types.SET_BADGE_CLASSIFICATIONS, res.data);
          })
    },

    async deleteLocation({commit, state}, [id, toast]) {
        try {
            await Locations.delete(id);
            toast.success(i18n.t('locations.notification.deleted'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.info(i18n.t('locations.notification.inUse'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true,
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('locations.notification.deletedError'), {
                        icon: "mdi-alert-circle",
                        queueable: true
                    });
            }
        }
    },

    async authorizeLocationOrNot({ dispatch }, [payload, toast]) {
        try {
            await Locations.authorizeOrNot(payload);

            dispatch('fetchFilteredLocations', [{
                pageableDTO: {
                    page:0,
                    size:10,
                    sortBy: "id",
                    direction: "desc"
                }
            }, toast]);

            toast.success(i18n.t('reports.notification.activeOrDeactiveSuccess'), {
                icon: 'mdi-check-circle',
                queueable: false
            });
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('reports.notification.activeOrDeactiveError'), {
                    queueable: true
                });
            }
        }
    },

    async fetchTemplate({ commit, state }, [payload, toast]) {
        try {
            const { data } = await Locations.fetchTemplate(payload)

            const FileSaver = require('file-saver')
            const blob = new Blob([data], {
                type: 'application/xlsx',
            })
            FileSaver.saveAs(blob, `location-template.xlsx`)

            toast.success(i18n.t('common.notification.downloadTemplateSuccess'), {
                queueable: true,
                icon: 'mdi-check-circle',
            })
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('common.notification.downloadTemplateError'), {
                    queueable: true,
                })
        }
    },

    importLocations({ commit, state }, [options, toast]) {
        return new Promise((resolve, reject) => {
            Locations.import(options)
                .then((res) => {
                    switch (res.data.importState) {
                        case "ERRORED":
                            toast.error(i18n.t('records.notification.importError'), {
                                queueable: true,
                            })
                            reject(res.data)
                            break
                        case "SUCCESSFUL":
                            toast.success(i18n.t('records.notification.importSuccess'), {
                                queueable: true,
                                icon: 'mdi-check-circle',
                            })
                            resolve(res.data)
                            break
                        case "PARTIALLY":
                            toast.warning(i18n.t('records.notification.importSuccess'), {
                                icon: "mdi-alert",
                                queueable: true,
                            })
                            resolve(res.data)
                            break
                    }
                })
                .catch((err) => {
                    reject(err)

                    if (err.message !== 'cancelRequest')
                        toast.error(i18n.t('records.notification.importError'), {
                            queueable: true,
                        })
                })
        })
    },

    async fetchExtraColumns({ getters, commit }) {
        try {
            const { data } = await Locations.getExtraColumns();

            const extraColumnsSet = new Set(data.extraColumns);

            const filteredExtraColumns = getters['GET_EXTRA_COLUMNS'].filter(({ key }) => extraColumnsSet.has(key));

            const keyToNameMap = filteredExtraColumns.reduce((acc, {key, name}) => {
                acc[key] = name;
                return acc;
            }, {});
            const selectedColumns = data.extraColumns.map(key => {
                return { key, name: keyToNameMap[key] || key };
            });

            commit(types.SET_SELECTED_COLUMNS, selectedColumns);
        } catch (error) {
            console.log(error);
        }
    },

    async setExtraColumns({ dispatch }, payload) {
        try {
            await Locations.setExtraColumns(payload);
            await dispatch('fetchExtraColumns')
        } catch (error) {
            console.log(error)
        }
    }
}
