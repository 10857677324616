<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    temporary
    right
    clipped
    width="650px"
    v-if="structuresDetails"
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-title class="neuter--text">
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
          {{ $t('common.titles.filtersMenu') }}
          {{ setMenuTitle(type) | toFormatCase('upper') }}
        </v-list-item-title>
        <v-list-item-action
          style="
            display: contents;
            justify-content: end;
            align-items: center;
            align-content: center;
            text-align: end;
          "
        >
          <v-btn small text color="neuter" class="mr-1" @click="cancelFilterDialog">
            {{ $t('common.buttons.clean') }}
          </v-btn>
          <v-btn small elevation="0" class="t-bw-primary--text" color="primary" @click="confirmFilters" :disabled="invalidSectionsCount > 0 || !isValidSelection">
            {{ $t('common.buttons.confirm') }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        v-if="invalidSectionsCount > 0 || !isValidSelection"
      >
        <v-alert
          border="left"
          type="error"
          dense
          tile
          text
        >
          {{ $t('common.notification.selectedItemsExceeded', { max: 1000 }) }}
        </v-alert>
      </v-list-item>
    </template>

    <v-divider />

    <v-list dense style="padding: 0 30px"
        v-if="(newArrayFilters.length > 0)">
      <v-list-item-content
        class="pa-0"
        v-for="item in newArrayFilters"
        :key="item.title"
        link
      >
        <v-list-item-content class="pa-0">
          <span v-if="item.title === 'Stage'           && (type === 'pre-records' || type === 'traceability' || type === 'locations' || type === 'storages')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('pre-records.fields.stage') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  ref="storageDeviceTypesActiveByCompanyFilters"
                  :label="$t('pre-records.fields.stage')"
                  multiple
                  clearable
                  item-color="primary"
                  item-text="storage_type_name"
                  item-value="storage_device_type_id"
                  return-object
                  :items="storageDeviceTypesActiveByCompany"
                  v-model="filtersObjects.storage_device_types_ids"
                  small-chips
                  color="primary"
                  @mouseup="
                    checkState(
                      'storageDevice',
                      $refs.storageDeviceTypesActiveByCompanyFilters[0]
                        .filteredItems,
                      filtersObjects.storage_device_types_ids
                    )
                  "
                  @click:clear="checkboxState.storageDevice = false"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item.storage_type_name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{
                        filtersObjects.storage_device_types_ids.length - 1
                      }}
                      {{ $t('common.titles.others') }})</span
                    >
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="primary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="item.storage_type_name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.storageDevice"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="filtersObjects.storage_device_types_ids = selectAll(
                          'storageDevice',
                          filtersObjects.storage_device_types_ids,
                          $refs.storageDeviceTypesActiveByCompanyFilters[0].filteredItems,
                          'storage_device_type_id',
                          checkboxState.storageDevice
                      )"
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'StringFilter'    && (type === 'locations'   || type === 'storages' || type === 'records')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('common.fields.filterText') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    :label="$t('common.fields.enterText')"
                    clearable
                    v-model="filtersObjects.stringFilter"
                >

                </v-text-field>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'Template'        &&  type === 'inspections'"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('survey.fields.name') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  ref="allTemplatesFilters"
                  color="primary"
                  :label="$t('survey.fields.name')"
                  clearable
                  multiple
                  item-color="primary"
                  :items="sortArray(allTemplates)"
                  v-model="filters.template_names"
                  small-chips
                  @change="showQuestionsByTypes($event)"
                  @mouseup="
                    checkState(
                      'name',
                      $refs.allTemplatesFilters[0].filteredItems,
                      filters.template_names
                    )
                  "
                  @click:clear="checkboxState.name = false"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{ filters.template_names.length - 1 }}
                      {{ $t('common.titles.others') }})</span
                    >
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="primary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="item"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.name"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="
                        filters.template_names = selectAll(
                          'name',
                          filters.template_names,
                          $refs.allTemplatesFilters[0].filteredItems,
                          null,
                          checkboxState.name
                        )
                      "
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'Locations'       && (type === 'pre-records' || type === 'records'      || type === 'inspections'   || type === 'traceability' || type === 'traceabilityV2' || type === 'dashboard')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center justify-space-between" style="width: 100%">
              <div class="d-flex align-center">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                {{ $t('records.filters.locations') }}
              </div>
                <v-tooltip bottom v-if="type === 'records' && filtersObjects.locationStrict === false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      right
                      size="18"
                      class="ml-1 mr-1"
                      :color="'grey darken-2'"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('common.filters.flexible_combination') }}</span>
                </v-tooltip>
            </v-list-item-title>         
            <v-row class="px-4 my-2 d-block">
              <LocationOriginFilters
                :location-label="
                  type === 'inspections'
                    ? $t('locations.label')
                    : $t('records.fields.location_origin')
                "
                location-ref="locationsFilters"
                @update:locations:origin="$set(locations, 'origin_locations_filters', $event)"
                :storage-label="
                  type === 'inspections'
                    ? $t('documents.fields.storage_device')
                    : $t('records.fields.originReg')
                "
                storage-ref="locationsFiltersOrigin"
                @update:storages:origin="$set(filtersObjects, 'sdi_origin_ids', $event)"
                @on:valid-form="isValidSelection = $event"
                key="originFiltersMenu"
              />

              <LocationDestinationFilters
                v-if="!['inspections', 'dashboard'].includes(type)"
                :location-label="$t('records.fields.location_destination')"
                location-ref="destinationFilters"
                @update:locations:destination="$set(locations, 'destination_locations_filters', $event)"
                :storage-label="$t('records.fields.destinationRed')"
                storage-ref="destinationFiltersDestination"
                @update:storages:destination="$set(filtersObjects, 'sdi_destination_ids', $event)"
                @on:valid-form="isValidSelection = $event"
                key="destinationFiltersMenu"
              />
            </v-row>
          </span>
          <span v-if="item.title === 'Locations'       && (type === 'locations')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.locations') }}
            </v-list-item-title>
            <v-row>
              <LocationFilters
                class="py-2 px-3"
                :location-label="$t('locations.label')"
                location-ref="primaryLocationOriginFilters"
                @update:locations:origin="$set(filtersObjects.locations, 'ids', $event)"
                :show-origin="true"
                :show-destination="false"
                key="locationsFilters"
              />
            </v-row>
          </span>
          <span v-if="item.title === 'Locations'       && (type === 'storages')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.locations') }}
            </v-list-item-title>
            <v-row>
              <LocationFilters
                class="py-2 px-3"
                :storage-label="$t('storage.label')"
                :location-label="$t('locations.label')"
                storage-ref="storagesFilters"
                location-ref="primaryLocationOriginFilters"
                @update:locations:origin="$set(filtersObjects.storages, 'primaryLocationIds', $event)"
                @update:storages:origin="applyStorageFiltersLocations($event)"
                @on:valid-form="isValidSelection = $event"
                :show-origin="true"
                :show-destination="true"
                key="storagesFilters"
              />
            </v-row>
          </span>
          <span v-if="item.title === 'Process'         && (type === 'pre-records' || type === 'records'      || type === 'traceability')"
              class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.process') }}
            </v-list-item-title>
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              ref="processFilter"
              :label="$t('records.fields.process')"
              multiple
              clearable
              item-color="primary"
              item-text="proccess_name"
              item-value="id"
              return-object
              :items="orderData(process, 'proccess_name')"
              v-model="filtersObjects.process_ids"
              small-chips
              color="primary"
              @mouseup="
                checkState(
                  'proccess',
                  $refs.processFilter[0].filteredItems,
                  filtersObjects.process_ids
                )
              "
              @click:clear="checkboxState.proccess = false"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ translate(item.language_key, true) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ filtersObjects.process_ids.length - 1 }}
                  {{ $t('common.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="translate(item.language_key, true)"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-checkbox
                  v-model="checkboxState.proccess"
                  color="primary"
                  class="pt-2 pb-2 mt-0 pl-4"
                  :hide-details="true"
                  @change="
                    filtersObjects.process_ids = selectAll(
                      'proccess',
                      filtersObjects.process_ids,
                      $refs.processFilter[0].filteredItems,
                      'id',
                      checkboxState.proccess
                    )
                  "
                >
                  <template v-slot:label>
                    <v-btn small color="primary" x-small text>
                      {{ $t('common.titles.selectAll') }}
                    </v-btn>
                  </template>
                </v-checkbox>
                <v-divider class="mb-2"></v-divider>
              </template>
            </v-autocomplete>
          </span>
          <span v-if="item.title === 'IsActiveOnMobile'&&  type === 'pre-records'"
              class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('pre-records.fields.mobile') }}
            </v-list-item-title>
            <v-autocomplete
                small
                flat
                solo
                background-color="#EAEAEA80"
                ref="processFilter"
                :label="$t('pre-records.fields.mobile')"
                clearable
                item-color="primary"
                item-text="label"
                item-value="value"
                :items="[
                {label: $t('pre-records.fields.isActiveOnMobile.active'), value: true},
                {label: $t('pre-records.fields.isActiveOnMobile.inactive'), value: false},
              ]"
                v-model="filtersObjects.isActiveOnMobile"
                small-chips
                color="primary"
            >
            </v-autocomplete>
          </span>
          <span v-if="item.title === 'Products'        && (type === 'records'     || type === 'traceability-qr')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.product') }}
            </v-list-item-title>
            <v-autocomplete
              style="width: 582px"
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              ref="productFilters"
              :label="$t('records.titles.labels.product')"
              multiple
              clearable
              item-color="primary"
              item-text="product_name"
              return-object
              :items="orderData(products, 'product_name')"
              v-model="filtersObjects.products_ids"
              small-chips
              color="primary"
              @update:search-input="searchNomenclator($event, 'products')"
              :loading="loadings.products"
              @mouseup="
                checkState(
                  'products',
                  $refs.productFilters[0].filteredItems,
                  filtersObjects.products_ids
                )
              "
              @click:clear="checkboxState.products = false"
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.product_name }}</span>
                </v-chip>
                <span @click.stop="" small v-if="index === 1" class="grey--text caption"
                  >(+{{ filtersObjects.products_ids.length - 1 }}
                  {{ $t('common.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pa-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.product_name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-checkbox
                  v-model="checkboxState.products"
                  color="primary"
                  class="pt-2 pb-2 mt-0 pl-4"
                  :hide-details="true"
                  @change="
                    filtersObjects.products_ids = selectAll(
                      'products',
                      filtersObjects.products_ids,
                      $refs.productFilters[0].filteredItems,
                      'id',
                      checkboxState.products
                    )
                  "
                >
                  <template v-slot:label>
                    <v-btn small color="primary" x-small text>
                      {{ $t('common.titles.selectAll') }}
                    </v-btn>
                  </template>
                </v-checkbox>
              </template>
            </v-autocomplete>
            <v-sheet
                style="margin-top: -1px; width: 582px; border-radius: 0 0 .3em .3em"
                :outlined="filtersObjects.products_ids && filtersObjects.products_ids.length > 0"
                elevation="10"
                class="py-4 px-1 elevation-0"
                max-width="100%"
            >
              <v-chip-group
                  color="primary"
                  active-class="primary--text"
                  style="width: 100%"
              >
                <v-chip

                    close
                    active-class="primary--text"
                    v-for="product in filtersObjects.products_ids"
                    @click:close="deleteNomenclator(product, 'products')"
                >
                  {{ product.product_name || product }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </span>
          <span v-if="item.title === 'Seasons'         && (type === 'records'     || type === 'traceability' || type === 'traceabilityV2'|| type === 'dashboard' || type === 'documents')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.season') }}
            </v-list-item-title>
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              ref="recordFilters"
              :label="$t('records.fields.season')"
              multiple
              clearable
              item-color="primary"
              item-text="season_name"
              return-object
              :items="orderData(seasons, 'season_name')"
              v-model="filtersObjects.seasons_ids"
              small-chips
              color="primary"
              @update:search-input="searchNomenclator($event, 'seasons')"
              @mouseup="
                checkState(
                  'seasons',
                  $refs.recordFilters[0].filteredItems,
                  filtersObjects.seasons_ids
                )
              "
              :loading="loadings.seasons"
              @click:clear="checkboxState.seasons = false"
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.season_name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ filtersObjects.seasons_ids.length - 1 }}
                  {{ $t('common.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.season_name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-checkbox
                  v-model="checkboxState.seasons"
                  color="primary"
                  class="pt-2 pb-2 mt-0 pl-4"
                  :hide-details="true"
                  @change="
                    filtersObjects.seasons_ids = selectAll(
                      'seasons',
                      filtersObjects.seasons_ids,
                      $refs.recordFilters[0].filteredItems,
                      'id',
                      checkboxState.seasons
                    )
                  "
                >
                  <template v-slot:label>
                    <v-btn small color="primary" x-small text>
                      {{ $t('common.titles.selectAll') }}
                    </v-btn>
                  </template>
                </v-checkbox>
                <v-divider class="mb-2"></v-divider>
              </template>
            </v-autocomplete>
            <v-sheet
                style="margin-top: -5px; width: 582px; border-radius: 0 0 .3em .3em"
                :outlined="filtersObjects.seasons_ids && filtersObjects.seasons_ids.length > 0"
                elevation="10"
                class="py-4 px-1 elevation-0"
                max-width="100%"
            >
              <v-chip-group
                  color="primary"
                  active-class="primary--text"
                  style="width: 100%"
              >
                <v-chip

                    close
                    active-class="primary--text"
                    v-for="season in filtersObjects.seasons_ids"
                    @click:close="deleteNomenclator(season, 'seasons')"
                >
                  {{ season.season_name }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </span>
          <span v-if="item.title === 'Date'            && (type === 'records'     || type === 'inspections'  || type === 'traceability'  || type === 'traceabilityV2'|| type === 'dashboard')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ ['records', 'traceability', 'traceabilityV2', 'dashboard'].includes(type) ? $t('records.fields.date') : $t('survey.fields.date') }}
            </v-list-item-title>
            <v-menu
              v-model="date.record_date_time"
              :ref="date['record_date_time']"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  v-model="date.filtersDate"
                  :label="['records', 'traceability', 'traceabilityV2', 'dashboard'].includes(type) ? $t('records.fields.date') : $t('survey.fields.date')"
                  readonly
                  v-on="on"
                  clearable
                  color="primary"
                  append-icon="mdi-calendar"
                  @click:clear="clearDate('recordDateRef')"
                  @click:append="
                    () => (date.record_date_time = !date.record_date_time)
                  "
                >
                </v-text-field>
              </template>
              <v-date-picker
                :locale="$i18n.locale"
                color="primary"
                v-model="date.filtersRecordDate"
                range
                :min="date.filtersRecordDate[0]"
                :max="
                  $router.currentRoute.name == 'traceability-report'
                    ? today
                    : null
                "
                @change="dateRangeText()"
                ref="recordDateRef"
                :selected-items-text="
                  '{0} ' + $t('records.filters.selected_items_text')
                "
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="neuter" small text
                  @click="cleanDatePicker('recordDateRef')"
                >
                  {{ $t('common.buttons.clean') }}
                </v-btn>
                <v-btn
                  :disabled="date.filtersRecordDate.length < 2"
                  color="primary" small elevation="0" class="t-bw-primary--text"
                  @click="filterByDate('record_date_time')"
                >
                  {{ $t('common.buttons.confirm') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </span>
          <span v-if="item.title === 'RegisterDate'    && (type === 'records'     || type === 'traceability' || type === 'traceabilityV2')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.registerDate') }}
            </v-list-item-title>
            <v-menu
              v-model="date.sent_date_time"
              :ref="date['sent_date_time']"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  v-model="date.filtersRegisterDate"
                  :label="$t('records.fields.record_date')"
                  readonly
                  v-on="on"
                  clearable
                  color="primary"
                  append-icon="mdi-calendar"
                  @click:clear="clearDate('registerDateRef')"
                  @click:append="
                    () => (date.sent_date_time = !date.sent_date_time)
                  "
                >
                </v-text-field>
              </template>
              <v-date-picker
                :locale="$i18n.locale"
                color="primary"
                v-model="date.filtersRecordRegisterDate"
                range
                :min="date.filtersRecordRegisterDate[0]"
                :max="
                  $router.currentRoute.name == 'traceability-report'
                    ? today
                    : null
                "
                @change="deconstructRangeTextForFilters(date.filtersRecordRegisterDate, 'sent_date_time', 'filtersRegisterDate')"
                ref="registerDateRef"
                :selected-items-text="
                  '{0} ' + $t('records.filters.selected_items_text')
                "
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="neuter" small text
                  @click="cleanDatePicker('registerDateRef')"
                >
                  {{ $t('common.buttons.clean') }}
                </v-btn>
                <v-btn
                  :disabled="date.filtersRecordRegisterDate.length < 2"
                  color="primary" small elevation="0" class="t-bw-primary--text"
                  @click="filterByDate('sent_date_time')"
                >
                  {{ $t('common.buttons.confirm') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </span>
          <span v-if="item.title === 'Responsável'     && (type === 'records'     || type === 'inspections'  || type === 'traceability'  || type === 'traceabilityV2')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.responsable') }}
            </v-list-item-title>
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              ref="authoritiesFilters"
              :label="$t('records.fields.person')"
              multiple
              clearable
              item-color="primary"
              item-text="name"
              item-value="id"
              return-object
              :items="orderData(authorities, 'name')"
              v-model="filtersObjects.person_in_charge_ids"
              small-chips
              color="primary"
              @mouseup="
                checkState(
                  'authorities',
                  $refs.authoritiesFilters[0].filteredItems,
                  filtersObjects.person_in_charge_ids
                )
              "
              @click:clear="checkboxState.authorities = false"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ filtersObjects.person_in_charge_ids.length - 1 }}
                  {{ $t('common.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-checkbox
                  v-model="checkboxState.authorities"
                  color="primary"
                  class="pt-2 pb-2 mt-0 pl-4"
                  :hide-details="true"
                  @change="
                    filtersObjects.person_in_charge_ids = selectAll(
                      'authorities',
                      filtersObjects.person_in_charge_ids,
                      $refs.authoritiesFilters[0].filteredItems,
                      'id',
                      checkboxState.authorities
                    )
                  "
                >
                  <template v-slot:label>
                    <v-btn small color="primary" x-small text>
                      {{ $t('common.titles.selectAll') }}
                    </v-btn>
                  </template>
                </v-checkbox>
                <v-divider class="mb-2"></v-divider>
              </template>
            </v-autocomplete>
          </span>
          <span v-if="item.title === 'Average'         &&  type === 'inspections' && filtersObjects.range">
            <v-list dense>
              <v-list-item-content class="pa-0">
                <v-form ref="form">
                  <v-list-item-title class="d-inline-flex align-center">
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-collapse</v-icon>
                    </v-list-item-icon>
                    {{ $t('survey.fields.score') }}
                  </v-list-item-title>
                  <v-row
                    class="ma-1"
                    style="
                      background-color: rgba(234, 234, 234, 0.5);
                      border-radius: 5px;
                    "
                  >
                    <v-col cols="12">
                      <v-range-slider
                        thumb-label
                        hide-details
                        color="primary"
                        v-model="filtersObjects.range"
                        :min="score[0]"
                        :max="score[1]"
                        :step="0.1"
                      >
                        <template v-slot:prepend>
                          <v-chip
                            disabled
                            class="text-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(filtersObjects.range, 0, $event)"
                          >
                            <span>{{ filtersObjects.range[0] }}</span>
                          </v-chip>
                        </template>
                        <template v-slot:append>
                          <v-chip
                            disabled
                            class="text-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(filtersObjects.range, 1, $event)"
                          >
                            <span>{{ filtersObjects.range[1] }}</span>
                          </v-chip>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-content>
            </v-list>
          </span>
          <span v-if="item.title === 'Area'            &&  type === 'locations'   && (filtersObjects.locations && filtersObjects.locations.area)">
            <v-list dense>
              <v-list-item-content class="pa-0">
                <v-form ref="form">
                  <v-list-item-title class="d-inline-flex align-center">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    {{ $t('locations.fields.area') }}
                  </v-list-item-title>
                  <v-row
                    class="ma-1"
                    style="
                      background-color: rgba(234, 234, 234, 0.5);
                      border-radius: 5px;
                    "
                  >
                    <v-col cols="12">
                      <v-range-slider
                        hide-details
                        color="primary"
                        track-fill-color="primary"
                        v-model="filtersObjects.locations.area"
                        :min="areaRanges.primary[0]"
                        :max="areaRanges.primary[1]"
                        :step="1"
                        :disabled="areaRanges.primary[1] === 0"
                      >
                        <template v-slot:prepend>
                          <v-chip
                            disabled
                            class="d-flex justify-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 120px"
                            @change="$set(filtersObjects.locations.area, 0, $event)"
                          >
                            <span>{{ filtersObjects.locations.area[0] }}</span>
                          </v-chip>
                        </template>
                        <template v-slot:append>
                          <v-chip
                            disabled
                            class="d-flex justify-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 120px"
                            @change="$set(filtersObjects.locations.area, 1, $event)"
                          >
                            <span>{{ filtersObjects.locations.area[1] }}</span>
                          </v-chip>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-content>
            </v-list>
          </span>
          <span v-if="item.title === 'Area'            &&  type === 'storages'    && (filtersObjects.locations && filtersObjects.storages.area)">
            <v-list dense>
              <v-list-item-content class="pa-0">
                <v-form ref="form">
                  <v-list-item-title class="d-inline-flex align-center">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    {{ $t('locations.fields.area') }}
                  </v-list-item-title>
                  <v-row
                    class="ma-1"
                    style="
                      background-color: rgba(234, 234, 234, 0.5);
                      border-radius: 5px;
                    "
                  >
                    <v-col cols="12">
                      <v-range-slider
                        hide-details
                        color="primary"
                        track-fill-color="primary"
                        v-model="filtersObjects.storages.area"
                        :min="areaRanges.secondary[0]"
                        :max="areaRanges.secondary[1]"
                        :step="1"
                        :disabled="areaRanges.secondary[1] === 0"
                      >
                        <template v-slot:prepend>
                          <v-chip
                            disabled
                            class="d-flex justify-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 120px"
                            @change="$set(filtersObjects.storages.area, 0, $event)"
                          >
                            <span>{{ filtersObjects.storages.area[0] }}</span>
                          </v-chip>
                        </template>
                        <template v-slot:append>
                          <v-chip
                            disabled
                            class="d-flex justify-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 120px"
                            @change="$set(filtersObjects.storages.area, 1, $event)"
                          >
                            <span>{{ filtersObjects.storages.area[1] }}</span>
                          </v-chip>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-content>
            </v-list>
          </span>
          <span v-if="item.title === 'Authority'       &&  type === 'documents'"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.titles.selectAuthority') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  ref="documentAuthoritiesFilters"
                  :label="$t('documents.fields.authority')"
                  clearable
                  item-color="primary"
                  :items="documentAuthorities"
                  item-value="id"
                  item-text="name"
                  v-model="filters.document_authority_ids"
                  small-chips
                  multiple
                  color="primary"
                  @mouseup="
                    checkState(
                      'documentAuthorities',
                      $refs.documentAuthoritiesFilters[0].filteredItems,
                      filters.document_authority_ids
                    )
                  "
                  @click:clear="checkboxState.documentAuthorities = false"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{ filters.document_authority_ids.length - 1 }}
                      {{ $t('common.titles.others') }})</span
                    >
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="primary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="item.name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.documentAuthorities"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="
                        filters.document_authority_ids = selectAll(
                          'documentAuthorities',
                          filters.document_authority_ids,
                          getByProperty(
                            $refs.documentAuthoritiesFilters[0].filteredItems,
                            'id'
                          ),
                          'id',
                          checkboxState.documentAuthorities
                        )
                      "
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2" />
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'DocumentType'    &&  type === 'documents'"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.titles.selectType') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  ref="documentTypesFilters"
                  :label="$t('documents.fields.type')"
                  multiple
                  clearable
                  item-color="primary"
                  item-value="id"
                  item-text="document_type_name"
                  :items="documentTypes"
                  @change="
                    documentDescriptionFiltered(filters.document_type_ids)
                  "
                  @click:clear="clearDocumentType()"
                  v-model="filters.document_type_ids"
                  small-chips
                  color="primary"
                  @mouseup="
                    checkState(
                      'document_type',
                      $refs.documentTypesFilters[0].filteredItems,
                      filters.document_type_ids
                    )
                  "
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item.document_type_name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{ filters.document_type_ids.length - 1 }}
                      {{ $t('common.titles.others') }})</span
                    >
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="primary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="item.document_type_name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.document_type"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="
                        filters.document_type_ids = getDescriptionsType(
                          filters.document_type_ids,
                          $refs.documentTypesFilters[0].filteredItems
                        )
                      "
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2" />
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'Description'     &&  type === 'documents'   && filters.document_type_ids && filters.document_type_ids.length > 0"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.titles.selectDescription') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  ref="documentDescriptionsFilters"
                  :label="$t('documents.fields.description')"
                  :loading="loadingDescription"
                  :disabled="
                    !(
                      filters.document_type_ids &&
                      filters.document_type_ids.length > 0
                    )
                  "
                  multiple
                  clearable
                  item-color="primary"
                  item-value="id"
                  item-text="document_description_name"
                  :items="documentDescriptions"
                  v-model="filters.document_description_ids"
                  small-chips
                  color="primary"
                  @mouseup="
                    checkState(
                      'document_description',
                      $refs.documentDescriptionsFilters[0].filteredItems,
                      filters.document_description_ids
                    )
                  "
                  @click:clear="checkboxState.document_description = false"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ translate(item.language_key) }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{ filters.document_description_ids.length - 1 }}
                      {{ $t('common.titles.others') }})</span
                    >
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="primary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="translate(item.language_key)"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.document_description"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="
                        filters.document_description_ids = selectAll(
                          'document_description',
                          filters.document_description_ids,
                          getByProperty(
                            $refs.documentDescriptionsFilters[0].filteredItems,
                            'id'
                          ),
                          'id',
                          checkboxState.document_description
                        )
                      "
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2" />
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>

          <span v-if="item.title === 'DocumentDate'    &&  type === 'documents'   && filtersObjects.document"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.fields.range_date') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12" class="ma-0">
                <v-menu
                  v-model="date.document_date"
                  :ref="date['document_date']"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      small
                      flat
                      solo
                      background-color="#EAEAEA80"
                      v-model="filtersObjects.document.document_date"
                      :label="$t('documents.fields.document_date')"
                      readonly
                      v-on="on"
                      clearable
                      no-title
                      color="primary"
                      append-icon="mdi-calendar-import"
                      @click:clear="clearDate('documentDateRef')"
                      @click:append="
                        () =>
                          (date.filtersDocumentDate['document_date'] =
                            !date.filtersDocumentDate['document_date'])
                      "
                    />
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    color="primary"
                    v-model="date.filtersDocumentDate.document_date"
                    range
                    :min="date.filtersDocumentDate.document_date[0]"
                    @change="dateRangeTextDocument('document_date')"
                    ref="documentDateRef"
                    :selected-items-text="
                      '{0} ' + $t('records.filters.selected_items_text')
                    "
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="neuter" small text
                      @click="cleanDatePicker('documentDateRef')"
                    >
                      {{ $t('common.buttons.clean') }}
                    </v-btn>
                    <v-btn
                      :disabled="
                        date.filtersDocumentDate.document_date.length < 2
                      "
                      color="primary" small elevation="0" class="t-bw-primary--text"
                      @click="filterByDate('document_date')"
                    >
                      {{ $t('common.buttons.confirm') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="ma-0">
                <v-menu
                  v-model="date.expiration_date"
                  :ref="date['expiration_date']"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      small
                      flat
                      solo
                      background-color="#EAEAEA80"
                      v-model="filtersObjects.document.expiration_date"
                      :label="$t('documents.fields.expiration_date')"
                      readonly
                      v-on="on"
                      clearable
                      no-title
                      color="primary"
                      append-icon="mdi-calendar-end"
                      @click:clear="clearDate('expirationDateRef')"
                      @click:append="
                        () =>
                          (date.filtersDocumentDate['expiration_date'] =
                            !date.filtersDocumentDate['expiration_date'])
                      "
                    />
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    color="primary"
                    v-model="date.filtersDocumentDate.expiration_date"
                    range
                    :min="date.filtersDocumentDate.expiration_date[0]"
                    @change="dateRangeTextDocument('expiration_date')"
                    ref="expirationDateRef"
                    :selected-items-text="
                      '{0} ' + $t('records.filters.selected_items_text')
                    "
                  >
                    <v-spacer />
                    <v-btn
                      color="neuter" small text
                      @click="cleanDatePicker('expirationDateRef')"
                    >
                      {{ $t('common.buttons.clean') }}
                    </v-btn>
                    <v-btn
                      :disabled="
                        date.filtersDocumentDate.expiration_date.length < 2
                      "
                      color="primary" small elevation="0" class="t-bw-primary--text"
                      @click="filterByDate('expiration_date')"
                    >
                      {{ $t('common.buttons.confirm') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'Visibility'      &&  type === 'documents'"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.fields.visibility') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    :label="$t('documents.titles.selectVisibility')"
                    clearable
                    item-color="primary"
                    item-value="value"
                    item-text="name"
                    :items="visibility"
                    v-model="filters.withExternalVisibility"
                    small-chips
                    color="primary"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'StorageDevices'  &&  type === 'documents'"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.filters.locations') }}
            </v-list-item-title>
            <LocationOriginFilters
              :location-label="$t('documents.fields_add.locations')"
              location-ref="locationsFilters"
              @update:locations:origin="$set(locations, 'destination_locations_filters', $event)"
              :storage-label="$t('storage.label')"
              storage-ref="secondaryOriginStorageByLocationsFiltered"
              @update:storages:origin="$set(filtersObjects, 'sdi_destination_ids', $event)"
              @on:valid-form="isValidSelection = $event"
              key="originDocumentFiltersMenu"
            />
          </span>
          <span v-if="item.title === 'Lote'            && (type === 'records'     || type === 'traceability-qr')">
            <v-list>
              <v-list-item-title class="d-inline-flex align-center">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                {{ $t('records.filters.lot') }}
              </v-list-item-title>

              <LotFilterComponent
                @data:change="filtersObjects.lots = $event" item-text="=name" item-value="name"></LotFilterComponent>
            </v-list>
          </span>
          <span v-if="item.title === 'Status'          && (type === 'records'     || type === 'traceability-qr' || type === 'traceability')     && (filtersObjects.record && filtersObjects.record.status)"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.fields.status') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="status"
                    multiple
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.record.status"
                    small-chips
                    ref="status"
                    :label="$t('records.titles.selectStatus')"
                    @change="onChangeStatus()"
                    @mouseup="
                    checkState(
                      'classifications',
                      $refs.status[0]
                        .filteredItems,
                      filtersObjects.record.status
                    )"
                    @click:clear="checkboxState.status = false"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.status"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="selectAllStatus(status)"
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'LocationClassifications' && (type === 'locations' || type === 'storages')"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('locations.fields.classification') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-if="type === 'storages'"
                    ref="classifications"
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="sClassifications"
                    multiple
                    item-text="name"
                    item-value="id"
                    v-model="filtersObjects.storages.classificationTypes"
                    small-chips
                    :label="$t('locations.fields.selectClassification')"
                    @mouseup="
                    checkState(
                      'sClassifications',
                      $refs.classifications[0]
                        .filteredItems,
                      filtersObjects.storages.classificationTypes
                    )"
                    @click:clear="checkboxState.sClassifications = false"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.sClassifications"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="selectAllClassifications(sClassifications)"
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-if="type === 'locations'"
                  ref="classifications"
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  color="primary"
                  clearable
                  item-color="primary"
                  :items="lClassifications"
                  multiple
                  item-text="name"
                  item-value="id"
                  v-model="filtersObjects.locations.classificationTypes"
                  small-chips
                  :label="$t('locations.fields.selectClassification')"
                  @mouseup="
                    checkState(
                      'lClassifications',
                      $refs.classifications[0]
                        .filteredItems,
                      filtersObjects.locations.classificationTypes
                    )"
                  @click:clear="checkboxState.lClassifications = false"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.lClassifications"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="selectAllClassifications(lClassifications)"
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'Status'          && (type === 'locations'   || type === 'storages')"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('records.fields.status') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="status"
                    multiple
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.locations.status"
                    small-chips
                    :label="$t('records.titles.selectStatus')"
                    @change="onChangeStatus()"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.status"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="selectAllStatus(status)"
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'Structures' && type === 'records'">
            <v-list-item class="d-flex align-center ma-0 pa-0 my-4" @click="isStructureOpen = !isStructureOpen">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="neuter--text">{{ $t('records.filters.others') }}</v-list-item-title>
              <v-spacer></v-spacer>
              <v-icon>{{ isStructureOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-list-item>
          
            <span v-if="isStructureOpen">
              <v-autocomplete
                :no-data-text="$t('common.fields.noDataAvailable')"
                small
                flat
                solo
                background-color="#EAEAEA80"
                ref="structuresDetailsFilters"
                multiple
                clearable
                item-color="primary"
                :label="'+ ' + $t('records.fields.filters')"
                :items="orderData(structuresDetails, 'structure_name')"
                return-object
                item-text="structure_name"
                item-value="structure_id"
                @change="selectStructures($event)"
                v-model="structureFilters"
                color="primary"
                :loading="loading"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index === 0">
                    <span>{{ item.structure_name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ structureFilters.length - 1 }}
                    {{ $t('common.titles.others') }})
                  </span>
                </template>
          
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 360px">
                    <v-checkbox
                      :input-value="attrs.inputValue"
                      color="primary"
                      class="pt-2 pb-2 mt-0"
                      :hide-details="true"
                    >
                      <template v-slot:label>
                        <read-more
                          :classes="'mb-0'"
                          :text="item.structure_name"
                          :max-chars="80"
                          :font-size="14"
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </template>
          
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="row">
                        <div class="col-12 py-0 text-center">
                          <v-btn
                            small
                            id="selectOthersFiltersClean"
                            @click="clearSelectedStructures()"
                            color="primary"
                            x-small
                            text
                          >
                            {{ $t('common.titles.clean') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mb-2"></v-divider>
                </template>
              </v-autocomplete>
          
              <div
                class="mb-1"
                v-if="structuresByIds && structuresByIds.length > 0"
                v-for="structure in structuresByIds"
                :key="structure.structure_id"
              >
                <v-autocomplete
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  :label="structure.structure_name"
                  multiple
                  ref="structureFiltersAutocomplete"
                  clearable
                  item-color="primary"
                  v-model="data[structure.structure_id]"
                  @change="setData(structure)"
                  :items="transformItems(structure)"
                  small-chips
                  color="primary"
                  :disabled="structure['structure_values'].length < 0 ||
                            (structure['structure_values'].length === 1 &&
                             structure['structure_values'][0] === null)"
                  @click:clear="checkboxState.structure = false"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ data[structure.structure_id].length - 1 }}
                      {{ $t('common.titles.others') }})
                    </span>
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="primary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                        @change="compare(structure, data)"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="item"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
          
                  <template v-slot:prepend-item>
                    <v-checkbox
                      v-model="checkboxState.structure"
                      color="primary"
                      class="pt-2 pb-2 mt-0 pl-4"
                      :hide-details="true"
                      @change="selectAllOrNot('all', structure, $event)"
                    >
                      <template v-slot:label>
                        <v-btn small color="primary" x-small text>
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </template>
                    </v-checkbox>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </div>
            </span>
          </span>

          <span v-if="item.title === 'Questions'       &&  type === 'inspections'">
            <v-list>
              <v-list-group :value="true" :prepend-icon="item.icon">
                <template v-slot:activator>
                  <v-list-item-title class="d-inline-flex align-center">
                    {{ $t('inspections.labels.questions') }}
                  </v-list-item-title>
                </template>
                <v-autocomplete
                  :no-data-text="$t('common.fields.noDataAvailable')"
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  ref="question"
                  multiple
                  clearable
                  item-color="primary"
                  :label="$t('inspections.labels.questions')"
                  :items="questions"
                  return-object
                  item-text="questionAndSection"
                  @change="selectQuestions($event)"
                  v-model="selectedQuestions"
                  color="primary"
                  :loading="loading"
                  :disabled="!(filters.template_names && filters.template_names.length > 0)"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item.questionAndSection | truncate(40) }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedQuestions.length - 1 }}
                      {{ $t('common.titles.others') }})
                    </span>
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="primary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="item.questionAndSection"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
                  <!-- SELECT ALL OR NOT -->
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="row">
                          <div class="col-12 text-center">
                            <v-btn
                              small
                              id="selectQuestionFiltersClean"
                              @click="clearSelectedQuestions()"
                              color="primary"
                              x-small
                              text
                            >
                              {{ $t('common.titles.clean') }}
                            </v-btn>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
                <div
                  class="mb-1"
                  v-if="selectedQuestions && selectedQuestions.length > 0"
                  v-for="(question, key) in selectedQuestions"
                >
                  <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    ref="answers"
                    multiple
                    clearable
                    item-color="primary"
                    :label="question.questionAndSection"
                    :items="answers[key]"
                    return-object
                    item-text="answer"
                    @change="setAnswers($event)"
                    v-model="selectedAnswers[key]"
                    color="primary"
                    :disabled="!(answers[key] && answers[key].length > 0)"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.answer | truncate(40) }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ selectedAnswers[key].length - 1 }}
                        {{ $t('common.titles.others') }})
                      </span>
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width: 360px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="primary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="item.answer"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
              </v-list-group>
            </v-list>
          </span>
          <span v-if="item.title === 'Sellers'         && (type === 'contracts'   || type === 'sellers')"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('reports.titles.selectSellers') }}
            </v-list-item-title>
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              :label="$t('reports.titles.sellers')"
              ref="sellersNamesFilters"
              multiple
              clearable
              item-color="primary"
              item-text="name"
              item-value="id"
              :items="orderData(sellersNames, 'name')"
              v-model="filtersObjects.sellers_ids"
              small-chips
              color="primary"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ filtersObjects.sellers_ids.length - 1 }}
                  {{ $t('common.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content class="py-0">
                    <div class="row">
                      <div class="col-6 py-0 text-right">
                        <v-btn
                          small
                          @click="
                            filtersObjects.sellers_ids =
                              $refs.sellersNamesFilters[0].filteredItems
                          "
                          color="primary"
                          x-small
                          text
                        >
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="col-5 py-0 text-left">
                        <v-btn
                          small
                          @click="filtersObjects.sellers_ids = []"
                          color="primary"
                          x-small
                          text
                        >
                          {{ $t('common.titles.clean') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mb-2"></v-divider>
              </template>
            </v-autocomplete>
          </span>
          <span v-if="item.title === 'Contracts'       && (type === 'contracts'   || type === 'sellers')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('reports.titles.selectContracts') }}
            </v-list-item-title>
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              ref="contractsNamesFilters"
              :label="$t('reports.titles.contracts')"
              multiple
              clearable
              item-color="primary"
              item-text="contractName"
              item-value="name"
              :items="orderData(contractsNames, 'contractName')"
              v-model="filtersObjects.contracts_names"
              small-chips
              color="primary"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.contractName }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ filtersObjects.contracts_names.length - 1 }}
                  {{ $t('common.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.contractName"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content class="py-0">
                    <div class="row">
                      <div class="col-6 py-0 text-right">
                        <v-btn
                          small
                          @click="
                            filtersObjects.contracts_names =
                              $refs.contractsNamesFilters[0].filteredItems
                          "
                          color="primary"
                          x-small
                          text
                        >
                          {{ $t('common.titles.selectAll') }}
                        </v-btn>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="col-5 py-0 text-left">
                        <v-btn
                          small
                          @click="filtersObjects.contracts_names = []"
                          color="primary"
                          x-small
                          text
                        >
                          {{ $t('common.titles.clean') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mb-2"></v-divider>
              </template>
            </v-autocomplete>
          </span>
          <span v-if="item.title === 'VolumeN'         && (type === 'contracts'   || type === 'sellers')">
            <v-list dense>
              <v-list-item-content class="pa-0">
                <v-form ref="form">
                  <v-list-item-title class="d-inline-flex align-center">
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-collapse</v-icon>
                    </v-list-item-icon>
                    {{ $t('reports.titles.selectRangeVolumeN') }}
                  </v-list-item-title>
                  <v-row
                    class="ma-1"
                    style="
                      background-color: rgba(234, 234, 234, 0.5);
                      border-radius: 5px;
                    "
                  >
                    <v-col cols="12">
                      <v-range-slider
                        thumb-label
                        hide-details
                        color="primary"
                        v-model="contractsRanges.volumeN"
                        :min="
                          contractsRanges.volumeN.length <= 1
                            ? 0
                            : volumesN['volumeN_min']
                        "
                        :max="volumesN['volumeN_max']"
                        :disabled="volumesN.length <= 1"
                        step="1"
                      >
                        <template v-slot:prepend>
                          <v-chip
                            disabled
                            class="text-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(contractsRanges.volumeN, 0, $event)"
                          >
                            <span>{{
                              contractsRanges.volumeN[0] ===
                              contractsRanges.volumeN[1]
                                ? 0
                                : contractsRanges.volumeN[0]
                            }}</span>
                          </v-chip>
                        </template>
                        <template v-slot:append>
                          <v-chip
                            disabled
                            class="text-center"
                            :value="contractsRanges.volumeN[1]"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(contractsRanges.volumeN, 1, $event)"
                          >
                            <span>{{ contractsRanges.volumeN[1] }}</span>
                          </v-chip>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-content>
            </v-list>
          </span>
          <span v-if="item.title === 'VolumeB'         && (type === 'contracts'   || type === 'sellers')">
            <v-list dense>
              <v-list-item-content class="pa-0">
                <v-form ref="form">
                  <v-list-item-title class="d-inline-flex align-center">
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-collapse</v-icon>
                    </v-list-item-icon>
                    {{ $t('reports.titles.selectRangeVolumeB') }}
                  </v-list-item-title>
                  <v-row
                    class="ma-1"
                    style="
                      background-color: rgba(234, 234, 234, 0.5);
                      border-radius: 5px;
                    "
                  >
                    <v-col cols="12">
                      <v-range-slider
                        thumb-label
                        hide-details
                        color="primary"
                        v-model="contractsRanges.volumeB"
                        :disabled="volumesB.length <= 1"
                        :min="
                          contractsRanges.volumeB.length <= 1
                            ? 0
                            : volumesB['volumeB_min']
                        "
                        :max="volumesB['volumeB_max']"
                        step="1"
                      >
                        <template v-slot:prepend>
                          <v-chip
                            disabled
                            class="text-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(contractsRanges.volumeB, 0, $event)"
                          >
                            <span>{{
                              contractsRanges.volumeB[0] ===
                              contractsRanges.volumeB[1]
                                ? 0
                                : contractsRanges.volumeB[0]
                            }}</span>
                          </v-chip>
                        </template>
                        <template v-slot:append>
                          <v-chip
                            disabled
                            class="text-center"
                            :value="contractsRanges.volumeB[1]"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(contractsRanges.volumeB, 1, $event)"
                          >
                            <span>{{ contractsRanges.volumeB[1] }}</span>
                          </v-chip>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-content>
            </v-list>
          </span>
          <span v-if="item.title === 'QuebraContracts' && (type === 'contracts'   || type === 'sellers')">
            <v-list dense>
              <v-list-item-content class="pa-0">
                <v-form ref="form">
                  <v-list-item-title class="d-inline-flex align-center">
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-collapse</v-icon>
                    </v-list-item-icon>
                    {{ $t('reports.titles.selectRangeQuebra') }}
                  </v-list-item-title>
                  <v-row
                    class="ma-1"
                    style="
                      background-color: rgba(234, 234, 234, 0.5);
                      border-radius: 5px;
                    "
                  >
                    <v-col cols="12">
                      <v-range-slider
                        thumb-label
                        hide-details
                        color="primary"
                        v-model="contractsRanges.quebra"
                        :disabled="quebra.length <= 1"
                        :min="
                          contractsRanges.quebra.length <= 1
                            ? 0
                            : quebra['quebra_min']
                        "
                        :max="quebra['quebra_max']"
                        step="1"
                      >
                        <template v-slot:prepend>
                          <v-chip
                            disabled
                            class="text-center"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(contractsRanges.quebra, 0, $event)"
                          >
                            <span>{{
                              contractsRanges.quebra[0] ===
                              contractsRanges.quebra[1]
                                ? 0
                                : contractsRanges.quebra[0]
                            }}</span>
                          </v-chip>
                        </template>
                        <template v-slot:append>
                          <v-chip
                            disabled
                            class="text-center"
                            :value="contractsRanges.quebra[1]"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(contractsRanges.quebra, 1, $event)"
                          >
                            <span>{{ contractsRanges.quebra[1] }}</span>
                          </v-chip>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-content>
            </v-list>
          </span>
          <span v-if="item.title === 'ContractsDates'  && (type === 'contracts'   || type === 'sellers')"
            class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.fields.range_date') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12" class="ma-0">
                <v-menu
                  v-model="date.contracts_dates"
                  :ref="date['contracts_dates']"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      small
                      v-on:keyup.86="replaceContractsDates"
                      v-on:keyup.ctrl.86="replaceContractsDates"
                      flat
                      solo
                      background-color="#EAEAEA80"
                      v-model="filtersObjects.contracts_dates"
                      :label="$t('reports.titles.date')"
                      readonly
                      v-on="on"
                      clearable
                      no-title
                      append-icon="mdi-calendar-import"
                      color="primary"
                      @click:clear="clearDate('contractsDateRef')"
                      @click:append="
                        () => (date.contracts_dates = !date.contracts_dates)
                      "
                    />
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    color="primary"
                    v-model="date.filtersContractsDate"
                    range
                    :min="date.filtersContractsDate[0]"
                    :selected-items-text="
                      '{0} ' + $t('records.filters.selected_items_text')
                    "
                    @change="
                      processRangeDates(
                        'contracts_dates',
                        'filtersContractsDate'
                      )
                    "
                    ref="contractsDateRef"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="neuter" small text
                      @click="cleanDatePicker('contractsDateRef')"
                    >
                      {{ $t('common.buttons.clean') }}
                    </v-btn>
                    <v-btn
                      :disabled="date.filtersContractsDate.length < 2"
                      color="primary" small elevation="0" class="t-bw-primary--text"
                      @click="filterByDate('contracts_dates')"
                    >
                      {{ $t('common.buttons.confirm') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'IsActive'        &&  type === 'documents'"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.fields.status') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="docItemsFilters.isActive"
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.document.isActive"
                    small-chips
                    :label="$t('documents.titles.selectStatus')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'IsActive'        && (type === 'locations'   || type === 'storages')"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('locations.fields.active') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="isActive"
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.locations.isActive"
                    small-chips
                    :label="$t('documents.titles.selectStatus')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'IsProductive'    &&  type === 'storages'"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('storage.fields.productive') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="isProductive"
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.locations.productive"
                    small-chips
                    :label="$t('documents.titles.selectStatus')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'IsExpired'       &&  type === 'documents'"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.fields.expired') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="docItemsFilters.isExpirated"
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.document.isExpirated"
                    small-chips
                    :label="$t('documents.titles.selectExpired')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'IsPublic'        &&  type === 'documents'"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('documents.fields.public') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="docItemsFilters.isPublic"
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.document.isPublic"
                    small-chips
                    :label="$t('documents.titles.selectStatus')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'ReportType'      &&  type === 'traceability-qr'"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              Report Type
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="reportTypes"
                    item-text="title"
                    item-value="value"
                    v-model="filtersObjects.reportType"
                    small-chips
                    :label="$t('documents.titles.selectExpired')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.title }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
          <span v-if="item.title === 'IsActive'        &&  type === 'traceability-qr'"
                class="pt-4"
          >
            <v-list-item-title class="d-inline-flex align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              {{ $t('common.fields.active') }}
            </v-list-item-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    flat
                    solo
                    background-color="#EAEAEA80"
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="qrFilters.isActive"
                    item-text="name"
                    item-value="value"
                    v-model="filtersObjects.qr.isActive"
                    small-chips
                    :label="$t('documents.titles.selectStatus')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </span>
        </v-list-item-content>
      </v-list-item-content>
    </v-list>
    <producer-filters v-if="type === 'producer'" @on:valid-form="isValidSelection = $event" />
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import _ from 'lodash'
import moment from 'moment'

import i18n from '@/plugins/i18n'
import LanguajeService from '@/services/LanguajeService'
import sortList from '@/services/OrderListService'
import ReadMore from '@/components/ReadMoreComponent'
import ASelectLots from "@/components/common/AAutocomplete/ASelectLots.vue";
import LocationOriginFilters from "@/components/filters/LocationOriginFilters.vue";
import LocationDestinationFilters from "@/components/filters/LocationDestinationFilters.vue";
import LocationFilters from "@/components/filters/LocationFilters.vue";
import ProducerFilters from "@/module/configuration/producer/components/filters/ProducerFilters.vue"
import { uuidv4 } from "@/services/Web3IntegrationService"
import LotFilterComponent from "@/components/common/nomenclators/LotFilterComponent.vue"

  export default {
    name: 'FiltersMenu',
    components: {
      LotFilterComponent,
      ProducerFilters,
      LocationFilters, LocationDestinationFilters, LocationOriginFilters, ASelectLots, ReadMore },
    data() {
      return {
        today: '',
        loadings: {
          products: false,
          seasons: false,
          origin: false,
          destination: false
        },
        pageDL: 1,
        pageOL: 1,
        pageSL: 1,
        pageLots: 1,
        itemsPerPage: 10,
        isStructureOpen: false,
        checkboxState: {
          storageDevice: false,
          name: false,
          location: false,
          locationOrigin: false,
          destination: false,
          destinationOrigin: false,
          proccess: false,
          products: false,
          seasons: false,
          authorities: false,
          documentAuthorities: false,
          document_type: false,
          document_description: false,
          locationsList: false,
          destinationList: false,
          lots: false,
          structure: false,
          questions: false,
          answers: false,
          status: false,
          primaryLocation: false,
          secondaryLocation: false,
          locationTypes: false,
          sClassifications: false,
          lClassifications: false
        },
        isActive: [
          {
            id: 1,
            value: true,
            name: i18n.t('documents.filters.isActive.activated')
          },
          {
            id: 2,
            value: false,
            name: i18n.t('documents.filters.isActive.desactivated')
          }
        ],
        isProductive: [
          {
            id: 1,
            value: true,
            name: i18n.t('storage.fields.isProductive.yes')
          },
          {
            id: 2,
            value: false,
            name: i18n.t('storage.fields.isProductive.no')
          }
        ],
        qrFilters: {
          isActive: [
            {
              id: 1,
              value: true,
              name: i18n.t('documents.filters.isActive.activated')
            },
            {
              id: 2,
              value: false,
              name: i18n.t('documents.filters.isActive.desactivated')
            }
          ]
        },
        docItemsFilters: {
          isActive: [
            {
              id: 1,
              value: true,
              name: i18n.t('documents.filters.isActive.activated')
            },
            {
              id: 2,
              value: false,
              name: i18n.t('documents.filters.isActive.desactivated')
            }
          ],
          isExpirated: [
            {
              id: 1,
              value: true,
              name: i18n.t('documents.filters.isExpired.expired')
            },
            {
              id: 2,
              value: false,
              name: i18n.t('documents.filters.isExpired.active')
            }
          ],
          isPublic: [
            {
              id: 1,
              value: true,
              name: i18n.t('documents.titles.options.visible')
            },
            {
              id: 2,
              value: false,
              name: i18n.t('documents.titles.options.notVisible')
            }
          ]
        },
        visibility: [
          {
            id: 0,
            name: i18n.t('documents.titles.options.visible'),
            value: 'true'
          },
          {
            id: 1,
            name: i18n.t('documents.titles.options.notVisible'),
            value: 'false'
          }
        ],
        loadingDescription: false,
        locations_filters: [],
        fieldsFilters: [],
        disabled: false,
        reportTypes: [{
          title: i18n.t('reports.titles.traceability-report'),
          value: "TRACEABILITY"
        }, {
          title: i18n.t('reports.titles.recall-report'),
          value: "RECALL"
        }],
        items: [
          {
            title: 'Sellers',
            icon: 'mdi-package-variant-closed-outline',
            section: null,
            disposition: 0
          },
          {
            title: 'Contracts',
            icon: 'mdi-package-variant-closed-outline',
            section: null,
            disposition: 1
          },
          {
            title: 'VolumeN',
            icon: 'mdi-package-variant-closed-outline',
            section: null,
            disposition: 2
          },
          {
            title: 'VolumeB',
            icon: 'mdi-package-variant-closed-outline',
            section: null,
            disposition: 3
          },
          {
            title: 'QuebraContracts',
            icon: 'mdi-arrange-bring-to-front-outline',
            section: null,
            disposition: 4
          },
          {
            title: 'ContractsDates',
            icon: 'mdi-calendar-cursor-outline',
            section: null,
            disposition: 5
          },
          { title: 'Stage', icon: 'mdi-apps', section: null,
          disposition: 6},
          { title: 'Template', icon: 'mdi-file-check-outline', section: null,
          disposition: 7},
          { title: 'Locations', icon: 'mdi-map-marker-radius-outline', section: null,
          disposition: 8},
          { title: 'Process', icon: 'mdi-all-inclusive', section: null,
          disposition: 9},
          {
            title: 'IsActiveOnMobile',
            icon: 'mdi-cellphone-text',
            section: null,
            disposition: 10
          },
          {
            title: 'Products',
            icon: 'mdi-package-variant-closed',
            section: null,
            disposition: 11
          },
          { title: 'Lote', icon: 'mdi-view-grid-outline', section: null,
          disposition: 12},
          { title: 'Seasons', icon: 'mdi-barley', section: null,
          disposition: 13},
          { title: 'Date', icon: 'mdi-calendar-outline', section: null,
          disposition: 14},
          { title: 'Responsável', icon: 'mdi-account-hard-hat-outline', section: null,
          disposition: 15},
          {
            title: 'Status',
            icon: 'mdi-bookmark-check-outline',
            section: null,
            disposition: 16
          },
          { title: 'Structures', icon: 'mdi-dots-horizontal', section: null,
          disposition: 17},
          {
            title: 'Average',
            icon: 'mdi-arrange-bring-to-front-outline',
            section: null,
            disposition: 18
          },
          { title: 'Questions', icon: 'mdi-dots-horizontal', section: null,
          disposition: 19},
          {
            title: 'Volume',
            icon: 'mdi-arrange-bring-to-front-outline',
            section: null,
            disposition: 20
          },
          {
            title: 'Quebra',
            icon: 'mdi-arrange-bring-to-front-outline',
            section: null,
            disposition: 21
          },
          {
            title: 'Authority',
            icon: 'mdi-account-check-outline',
            section: null,
            disposition: 22
          },
          {
            title: 'DocumentType',
            icon: 'mdi-file-cog-outline',
            section: null,
            disposition: 23
          },
          {
            title: 'Description',
            icon: 'mdi-format-page-break-outline',
            section: null,
            disposition: 24
          },
          {
            title: 'StorageDevices',
            icon: 'mdi-map-marker-radius-outline',
            section: null,
            disposition: 25
          },
          {
            title: 'DocumentDate',
            icon: 'mdi-calendar-cursor-outline',
            section: null,
            disposition: 26
          },
          {
            title: 'Visibility',
            icon: 'mdi-fingerprint',
            section: null,
            disposition: 27
          },
          {
            title: 'IsActive',
            icon: 'mdi-check-circle-outline',
            section: null,
            disposition: 28
          },
          {
            title: 'IsExpired',
            icon: 'mdi-text-box-remove-outline',
            section: null,
            disposition: 29
          },
          {
            title: 'ReportType',
            icon: 'mdi-chart-gantt',
            section: null,
            disposition: 30
          },
          {
            title: 'IsProductive',
            icon: 'mdi-account-hard-hat-outline',
            section: null,
            disposition: 31
          },
          {
            title: 'Area',
            icon: 'mdi-texture-box',
            section: null,
            disposition: 32
          },
          {
            title: 'StringFilter',
            icon: 'mdi-text',
            section: null,
            disposition: 33
          },
          {
            title: 'IsPublic',
            icon: 'mdi-eye-outline',
            section: null,
            disposition: 34
          },
          {
            title: 'LocationClassifications',
            icon: 'mdi-book-open',
            section: null,
            disposition: 35
          },
          { title: 'RegisterDate', icon: 'mdi-calendar-outline', section: null,
            disposition: 36},
        ],
        newArrayFilters: [],
        profile: null,
        lotsIds: [],
        lotFilters: {
          stringFilter: null,
          pageableDTO: {
            page:0,
            size:50,
            sortBy: "lot",
            direction: "desc"
          }
        },
        invalidSelection: {},
        invalidSectionsCount: 0,
        isValidSelection: true
      }
    },
    async created() {
      this.data = {}

      this.profile = JSON.parse(localStorage.getItem('profile'))

      const promises = [];
      // Fetch location (Primary & Secondary) area ranges
      promises.push(this.$store.dispatch('ui/fetchPrimaryRange'));
      promises.push(this.$store.dispatch('ui/fetchSecondaryRange'));

      const filters = {
        userId: this.profile.id,
        companyId: this.profile.company_id,
        isActive: null
      }
      promises.push(this.$store.dispatch('locations/fetchAllLocationsByIsActive', [filters, this.$toast]));
      promises.push(this.$store.dispatch('storage/fetchAllStoragesByIsActive', [filters, this.$toast]));

      await Promise.all(promises).finally(() => {
        console.log(this.areaRanges);
      })

      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))
      this.today = moment().format('YYYY-MM-DD')
      if (
        filtersRecords &&
        filtersRecords.structures.structuresByIds &&
        filtersRecords.structures.structuresByIds.length > 0
      ) {
        await _.forEach(
          filtersRecords.structures.structuresByIds,
          structure => {
            this.$set(
              this.data,
              structure.structure_id,
              structure.structures_selected
            )
          }
        )
      }
    },
    watch: {
      filtersSort: {
        handler() {
          this.filterAndSortItems(this.items, this.filtersSort)
        },
        deep: true,
      },
      filtersObjects() {
        this.checkboxState.status = this.filtersObjects.record && (this.filtersObjects.record.status.length === this.status.length)
      },
    },
    async mounted() {
      if (this.type === "inspections") {
        let filtersInspections = JSON.parse(localStorage.getItem('filtersInspections'))

        if (filtersInspections.answers && filtersInspections.answers.length > 0)
          this.selectedQuestions = filtersInspections.questions;
          this.selectedAnswers = filtersInspections.answers;
      } else {
        this.selectedQuestions = [];
        this.selectedAnswers = [];
      }
    },
    computed: {
      storageDeviceTypesActiveByCompany() {
        return sortList.orderListByUppercase(
          this.$store.getters['storage_type/storageDeviceTypesByCompanyAccess'],
          'storage_type_name'
        )
      },
      paginatedSLFilterItems() {
        const start = (this.pageSL - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.storages.slice(start, end);
      },
      maxPageSL() {
        return Math.ceil(this.storages.length / this.itemsPerPage);
      },
      paginatedDLFilterItems() {
        const start = (this.pageDL - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.locationsList.slice(start, end);
      },
      maxPageDL() {
        return Math.ceil(this.locationsList.length / this.itemsPerPage);
      },
      paginatedOLFilterItems() {
        const start = (this.pageOL - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.orderData(this.locationsList.slice(start, end), 'name');
      },
      maxPageOL() {
        return Math.ceil(this.locationsList.length / this.itemsPerPage);
      },
      paginatedLotsFilterItems() {
        const start = (this.pageLots - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.orderData(this.lotsIds.slice(start, end));
      },
      maxPageLots() {
        return Math.ceil(this.lotsIds.length / this.itemsPerPage);
      },
      ...mapGetters({
        filtersSort: 'ui/GET_FILTERS_SORT',
        score: 'inspections/score',
        process: 'proccess/proccess',
        products: 'product/products',
        seasons: 'season/seasons',
        lots: 'details/lots',
        contracts: 'reports/contracts',
        contractsNames: 'reports/contractsNames',
        sellersNames: 'reports/sellersNames',
        quebra: 'reports/quebra',
        volumesN: 'reports/volumesN',
        volumesB: 'reports/volumesB',
        locationsList: 'locations/GET_LOCATIONS_FOR_FILTERS',
        allTemplates: 'survey/templatesForFilters',
        storages: 'storage/GET_STORAGES_FOR_FILTERS',
        authorities: 'authority/authorities',
        structuresDetails: 'records/structures',
        generalLoading: 'general/loading',
        documents: 'documents/documents',
        allStoragesByCompany: 'authority/authoritiesStoragesDevices',
        documentAuthorities: 'document_authority/documentAuthorities',
        documentTypes: 'document_type/documentTypes',
        documentDescriptions: 'document_description/documentDescriptions',
        loading: 'ui/GET_LOADING',
        areaRanges: 'ui/GET_LOCATION_RANGES',
        sClassifications: "storage/GET_CLASSIFICATIONS",
        lClassifications: "locations/GET_CLASSIFICATIONS"
      }),
      status() {
        return [
          {
            id: 1,
            value: 'VALIDATED',
            name: i18n.t('records.filters.status.validated')
          },
          {
            id: 2,
            value: 'VALIDATING',
            name: i18n.t('records.filters.status.validating')
          },
          {
            id: 3,
            value: 'ERRORED',
            name: i18n.t('records.filters.status.errored')
          },
          {
            id: 4,
            value: 'PENDING',
            name: i18n.t('records.filters.status.pending')
          }
        ]
      },
      data: {
        get() {
          return this.$store.getters['ui/GET_DATA']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATA', val)
        },
      },
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      source: {
        get() {
          return this.$store.getters['ui/GET_FILTER_SOURCE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_SOURCE', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      chartData: {
        get() {
          return this.$store.getters['ui/GET_CHART_DATA']
        },
        set(val) {
          this.$store.dispatch('ui/CHART_DATA', val)
        },
      },
      chartRanges: {
        get() {
          return this.$store.getters['ui/GET_CHART_RANGES']
        },
        set(val) {
          this.$store.commit('ui/CHART_RANGES', val)
        },
      },
      contractsRanges: {
        get() {
          return this.$store.getters['ui/GET_CONTRACTS_RANGES']
        },
        set(val) {
          this.$store.commit('ui/CONTRACTS_RANGES', val)
        },
      },
      contractsData: {
        get() {
          return this.$store.getters['ui/GET_CONTRACTS_DATA']
        },
        set(val) {
          this.$store.commit('ui/CONTRACTS_DATA', val)
        },
      },
      structureFilters: {
        get() {
          return this.$store.getters['ui/GET_STRUCTURE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_STRUCTURE_FILTERS', val)
        },
      },
      structuresByIds: {
        get() {
          return this.$store.getters['ui/GET_STRUCTURES_BY_IDS']
        },
        set(val) {
          this.$store.commit('ui/STRUCTURES_BY_IDS', val)
        },
      },
      othersFilters: {
        get() {
          return this.$store.getters['ui/GET_OTHERS_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OTHERS_FILTERS', val)
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters['ui/GET_OPTIONS_TABLE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
        },
      },
      locations: {
        get() {
          return this.$store.getters['ui/GET_LOCATIONS_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_LOCATIONS_FILTERS', val)
        },
      },
      filterRecord: {
        get() {
          return this.$store.getters['ui/GET_STAGE_ID']
        },
        set(val) {
          this.$store.dispatch('ui/SET_STAGE_ID', val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      producerFilters: {
        get() {
          return this.$store.getters['ui/GET_PRODUCER_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      date: {
        get() {
          return this.$store.getters['ui/GET_DATE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATE_FILTERS', val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters['ui/GET_FILTERS_CHIPS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters['ui/GET_COUNT_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
        },
      },
      recordSelected: {
        get() {
          return this.$store.getters['records/record']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD', val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters['ui/GET_DRAWER']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DRAWER', val)
        },
      },
      questions: {
        get() {
          return this.$store.getters['ui/GET_QUESTIONS']
        },
        set(val) {
          this.$store.commit('ui/QUESTIONS', val)
        }
      },
      selectedQuestions: {
        get() {
          return this.$store.getters['ui/GET_SELECTED_QUESTIONS']
        },
        set(val) {
          this.$store.commit('ui/SELECTED_QUESTIONS', val)
        }
      },
      answers: {
        get() {
          return this.$store.getters['ui/GET_ANSWERS']
        },
        set(val) {
          this.$store.commit('ui/ANSWERS', val)
        }
      },
      selectedAnswers: {
        get() {
          return this.$store.getters['ui/GET_SELECTED_ANSWERS']
        },
        set(val) {
          this.$store.commit('ui/SELECTED_ANSWERS', val)
        }
      },
      getOriginStorageByLocationsFiltered() {
        return sortList.orderListByUppercase(
          this.getStorageByLocationsFilteredAction(
            this.locations.origin_locations_filters
          ),
          'control_label'
        )
      },
      getDestiStorageByLocationsFiltered() {
        return sortList.orderListByUppercase(
          this.getStorageByLocationsFilteredAction(
            this.locations.destination_locations_filters
          ),
          'control_label'
        )
      },
    },
    methods: {
      uuidv4,
      ...mapActions({
        fetchListRecordsByCompanyPaginated:
          'records/fetchListAppointmentsByCompanyPaginated',
        fetchListProducts: 'product/fetchListProduct',
        fetchListSeasons: 'season/fetchListSeasons',
        fetchListContracts: 'reports/getContracts',
        fetchListDocumentDescriptionsByType:
          'document_description/fetchListDocumentDescriptionsByType',
        // fetchListStructureLots: 'details/fetchListStructureLots',
        fetchStructuresByIds: 'ui/SET_STRUCTURES_BY_IDS',
        fetchQuestions: 'ui/SET_QUESTIONS_BY_TYPE',
        fetchAnswers: 'ui/SET_ANSWERS_BY_QUESTIONS',
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchStorageLocations: "storage/fetchFilteredStorages"
      }),
      applyStorageFiltersLocations(event) {
        console.log('applyStorageFiltersLocations:', event)
        if ((this.filtersObjects && this.filtersObjects.storages) && event)
          this.$set(this.filtersObjects.storages, 'ids', event)
      },
      filterAndSortItems(items, orderList = [], sortBy = "disposition") {
        this.$set(this, 'newArrayFilters', []);

        if (!(orderList.length > 0)) {
          this.$set(this, 'newArrayFilters', items);
        } else {
          const filteredItems = items.filter(item => orderList.includes(item[sortBy]));

          const sortedItems = orderList.map(value => filteredItems.find(item => item[sortBy] === value)).filter(item => item !== undefined);

          this.$set(this, 'newArrayFilters', sortedItems);
        }
      },
      updateSecondaryLocations(event) {
        if (event.length > 0) {
          if (typeof event[0] === 'object') {
            const data = _.cloneDeep(this.getByProperty(event, 'id'))
            _.forEach(this.filtersObjects.sdi_destination_ids, (storage, index) => {
              if (storage && !data.includes(storage.primaryLocationId)) {
                this.filtersObjects.sdi_destination_ids.splice(index, 1)
              }
            })
          } else {
            _.forEach(this.filtersObjects.sdi_destination_ids, (storage, index) => {
              if (storage && !event.includes(storage.primaryLocationId)) {
                this.filtersObjects.sdi_destination_ids.splice(index, 1)
              }
            })
          }
        } else {
          this.filtersObjects.sdi_destination_ids = []
        }
      },
      async fetchLocations(e) {
        let byIds = false;

        if (!e) {
          byIds = true;
        }

        this.$set(this.loadings, 'origin', true);

        const filters = {
          stringFilter: e || "",
          ids: byIds ? this.locations.destination_locations_filter : null,
          isActive: true
        }

        await this.fetchPrimaryLocations([filters, this.$toast]).finally(() => {
          this.$set(this.loadings, 'origin', false);
        })
      },
      async fetchStorages(e, pl) {
        this.$set(this.loadings, 'destination', true);

        if (pl && pl.length > 0 && typeof pl[0] === 'object') {
          pl = _.map(pl, l => l.locationId)
        }

        await this.fetchStorageLocations([{
          primaryLocationIds: pl,
          stringFilter: e || "",
          isActive: true
        }, this.$toast]).finally(() => {
          this.$set(this.loadings, 'destination', false);
        })
      },
      async searchNomenclator(event, nomenclator) {
        this.loadings[nomenclator] = true
        const filters = {
          companyId: this.profile.company_id,
          search: event || "",
          pageableDTO: {
            page: 0,
            size: 10,
            sortBy: 'id',
            direction: 'ASC',
          }
        }

        switch (nomenclator) {
          case "products":
            await this.fetchListProducts([filters, this.$toast]).finally(() => {
              this.loadings.products = false
            })
            break
          case "seasons":
            await this.fetchListSeasons([filters, this.$toast]).finally(() => {
              this.loadings.seasons = false
            })
            break
        }
      },
      deleteNomenclator(item, nomenclator) {
        console.log(item)
        _.forEach(this.filtersObjects[`${nomenclator}_ids`], (p, k) => {
          if (item && p)
            if (item.id === p.id) {
              this.filtersObjects[`${nomenclator}_ids`].splice(k, 1)
            }
        })
      },
      orderData(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      sortArray(list) {
        return _.sortBy(list)
      },
      deselectItem(type, item) {
        switch (type) {
          case 'location':
            this.$set(this.locations, 'origin_locations_filters', this.locations.origin_locations_filters.filter(i => i !== item))
            break;
          case 'destination':
            this.$set(this.locations, 'destination_locations_filters', this.locations.destination_locations_filters.filter(i => i !== item))
            break;
          case 'primaryLocation':
            if (type === 'locations')
              this.$set(this.filtersObjects.locations, 'ids', this.filtersObjects.locations.ids.filter(i => i !== item))
            else
              this.$set(this.filtersObjects.storages, 'primaryLocationIds', this.filtersObjects.storages.primaryLocationIds.filter(i => i !== item))
            break;
          case 'secondaryLocation':
            this.$set(this.filtersObjects.storages, 'ids', this.filtersObjects.storages.ids.filter(i => i !== item))
            break;
          case 'lots':
            this.$set(this.filtersObjects, 'lots', this.filtersObjects.lots.filter(i => i !== item))
            break;
        }
      },
      selectPaginatedAll(type, value, idField, checkbox){
        if( checkbox === false ){
          this.checkboxState[type] = false;

          if (this.invalidSelection[type]) {
            this.invalidSectionsCount--
          }

          this.$set(this.invalidSelection, type, false);

          return [];
        }

        let list;

        switch(type) {
          case 'secondaryLocation':
            list = this.storages;
            break;
          case 'location':
          case 'primaryLocation':
          case 'destination':
            list = this.locationsList;
            break;
          case 'lots':
            list = this.lotsIds;
            break;
          // puedes agregar más cases si tienes más listas
          default:
            console.error('Invalid type for selectAll:', type);
            return;
        }

        let aux = [];
        if( list != null ) {

          if (typeof list[0] === 'object') {
            _.forEach(list, (object) => {
              if (!_.find(value, (o) => {
                return o[idField] === object[idField]
              }))
                value.push(object)
            })
          } else {
            _.forEach(list, (text) => {
              if (!value.includes(text))
                value.push(text)
            })
          }

          aux = value;
        }else{
          aux = value;
        }

        this.$set(this.invalidSelection, type, aux.length > 900);

        if (this.invalidSelection[type]) {
          this.invalidSectionsCount++
        }

        return aux;
      },
      selectAll(type, items, value, idField = null, checkbox) {
        if (checkbox === false) {
          this.$nextTick(() => {
            this.checkboxState[type] = false;
          });

          if (this.invalidSelection[type]) {
            this.invalidSectionsCount--;
          }

          this.$set(this.invalidSelection, type, false);

          return [];
        }

        console.log(idField);

        let aux = [];
        if (items != null && items.length > 0) {
          const firstItem = items[0];
          const isObject = typeof firstItem === 'object' && firstItem !== null;

          if (isObject && idField) {
            items.forEach(object => {
              const exists = value.some(o => o[idField] === object[idField]);
              if (!exists) {
                value.push(object);
              }
            });
          } else {
            items.forEach(item => {
              if (!value.includes(item)) {
                value.push(item);
              }
            });
          }

          aux = value;
        } else {
          aux = value;
        }

        if (type === 'locationsList') {
          this.updateSecondaryLocations(aux);
          this.fetchStorages(this.locations.destination_locations_filters);
        }

        this.$set(this.invalidSelection, type, aux.length > 900);

        if (this.invalidSelection[type]) {
          this.invalidSectionsCount++;
        }

        return aux;
      },
      compare(structure, values) {
        setTimeout(() => {
          return this.checkboxState.structure = structure.structure_values.length === values[structure.structure_id].length
        }, 1000)
      },
      checkState( prefix, total, values){

        if( values != null && values.length === total.length ){
          switch( prefix ){
            case 'storageDevice': this.checkboxState.storageDevice = true;
              break;
            case 'location': this.checkboxState.location = true;
              break;
            case 'primaryLocation': this.checkboxState.primaryLocation = true;
              break;
            case 'locationOrigin': this.checkboxState.locationOrigin = true;
              break;
            case 'destination': this.checkboxState.destination = true;
              break;
            case 'destinationOrigin': this.checkboxState.destinationOrigin = true;
              break;
            case 'secondaryLocation': this.checkboxState.secondaryLocation = true;
              break;
            case 'products': this.checkboxState.products = true;
              break;
            case 'seasons': this.checkboxState.seasons = true;
              break;
            case 'lots': this.checkboxState.lots = true;
              break;
            case 'proccess': this.checkboxState.proccess = true;
              break;
            case 'document_type': this.checkboxState.document_type = true;
              break;
            case 'document_description': this.checkboxState.document_description = true;
              break;
            case 'name': this.checkboxState.name = true;
              break;
            case 'authorities': this.checkboxState.authorities = true;
              break;
            case 'documentAuthorities': this.checkboxState.documentAuthorities = true;
              break;
            case 'locationsList': this.checkboxState.locationsList = true;
              break;
            case 'destinationList': this.checkboxState.destinationList = true;
              break;
            case 'status': this.checkboxState.status = true;
              break;
            case 'lClassifications': this.checkboxState.lClassifications = true;
              break;
            case 'sClassifications': this.checkboxState.sClassifications = true;
              break;
          }
        }else{
          switch( prefix ){
            case 'storageDevice': this.checkboxState.storageDevice = false;
              break;
            case 'location': this.checkboxState.location = false;
              break;
            case 'locationOrigin': this.checkboxState.locationOrigin = false;
              break;
            case 'destination': this.checkboxState.destination = false;
              break;
            case 'destinationOrigin': this.checkboxState.destinationOrigin = false;
              break;
            case 'products': this.checkboxState.products = false;
              break;
            case 'seasons': this.checkboxState.seasons = false;
              break;
            case 'lots': this.checkboxState.lots = false;
              break;
            case 'proccess': this.checkboxState.proccess = false;
              break;
            case 'document_type': this.checkboxState.document_type = false;
              break;
            case 'document_description': this.checkboxState.document_description = false;
              break;
            case 'name': this.checkboxState.name = false;
              break;
            case 'authorities': this.checkboxState.authorities = false;
              break;
            case 'documentAuthorities': this.checkboxState.documentAuthorities = false;
              break;
            case 'locationsList': this.checkboxState.locationsList = false;
              break;
            case 'destinationList': this.checkboxState.destinationList = false;
              break;
            case 'status': this.checkboxState.status = false;
              break;
            case 'lClassifications': this.checkboxState.lClassifications = false;
              break;
            case 'sClassifications': this.checkboxState.sClassifications = false;
              break;
          }
        }
      },
      verifyFilters() {
        let keys = []
        let filters = []

        switch (this.type) {
          case 'inspections':
            keys = [
              'surveyStateType',
              'template_names',
              'min_punctuation',
              'max_punctuation',
              'person_in_charge_ids',
              'start_record_date_time',
              'sdi_destination_ids',
              'sdi_origin_ids',
              'answers'
            ]
            filters = _.cloneDeep(_.pick(this.filters, keys))
            break
          case 'contracts':
            keys = [
              'contracts_dates',
              'contracts_names',
              'quebra_range',
              'sellers_ids',
              'volumeB_range',
              'volumeN_range',
            ]
            filters = _.cloneDeep(_.pick(this.filters, keys))
            break
          case 'documents':
            keys = [
              'sdi_origin_ids',
              'document_authority_ids',
              'document_type_ids',
              'document_description_ids',
              'storage_devices_ids',
              'document_date',
              'expiration_date',
              'withExternalVisibility'
            ]
            filters = _.cloneDeep(_.pick(this.filters, keys))
            break
          case 'records':
            keys = [
              'surveyStateType',
              'pageableDTO',
              'company_id',
              'template_names',
              'min_punctuation',
              'max_punctuation',
              'storage_devices_ids',
              'document_authority_ids',
              'document_type_ids',
              'document_description_ids',
              'document_date',
              'expiration_date',
            ]
            filters = _.cloneDeep(_.pick(this.filters, keys))
            break
          case 'preRecords':
            keys = [
              'company_id',
              'pageableDTO',
              'process_ids',
              'sdi_origin_ids',
              'sdi_destination_ids',
              'storage_device_types_ids',
              'isActiveOnMobile'
            ]
            filters = _.cloneDeep(_.pick(this.filters, keys))
            break
          case 'traceability':
            keys = [
              'pageableDTO',
              'company_id',
              'pageableDTO',
              'sellers_ids',
              'contracts_names',
              'volumeB_range',
              'volumeN_range',
              'quebra_range',
              'contracts_dates',
              'surveyStateType',
              'structure',
              'template_names',
              'min_punctuation',
              'max_punctuation',
              'storage_devices_ids',
              'document_authority_ids',
              'document_type_ids',
              'document_description_ids',
              'document_date',
              'expiration_date',
            ]
            filters = _.cloneDeep(_.pick(this.filters, keys))
            break
        }

        let empty = 0

        _.forEach(filters, filter => {
          if (!filter || !(filter.length > 0) || filter === '') {
            empty++
          }
        })

        this.disabled = empty === Object.keys(filters).length
      },
      clearDate(ref) {
        switch (ref) {
          case 'recordDateRef':
            this.date.filtersRecordDate = []
            return
          case 'registerDateRef':
            this.date.filtersRecordRegisterDate = []
            return
          case 'documentDateRef':
            this.date.filtersDocumentDate.document_date = []
            return
          case 'expirationDateRef':
          case 'secondaryLocation':
            this.filtersObjects.storages.ids = []
            this.checkboxState.secondaryLocation = false
            return
          case 'primaryLocation':
            this.filtersObjects.locations.ids = []
            this.filtersObjects.locations.primaryLocationIds = []
            this.checkboxState.primaryLocation = false
            return
        }
      },
      clearDocumentType() {
        this.filters.document_description_ids = []
        this.checkboxState.document_type = false
      },
      setMenuTitle(type) {
        switch (type) {
          case 'storages':
            return i18n.t('storage.label')
          case 'locations':
            return i18n.t('locations.label')
          case 'sellers':
            return i18n.t('reports.titles.sellers')
          case 'contracts':
            return i18n.t('reports.titles.contracts')
          case 'inspections':
            return i18n.t('survey.label')
          case 'pre-records':
            return i18n.t('pre-records.label')
          case 'records':
            return i18n.t('records.label')
          case 'documents':
            return i18n.t('documents.label')
          case 'traceability':
            return i18n.t('reports.titles.traceability-report')
          case 'traceability-qr':
            return i18n.t('qr.titles.traceabilityQr')
          case 'traceabilityV2':
            return i18n.t('reports.titles.traceability-report') + 'v2'
          case 'dashboard':
            return 'Dashboard'
        }
      },
      setData(structure) {
        this.$set(
          structure,
          'structures_selected',
          this.data[structure.structure_id]
        )
      },
      setAnswers(answer, index) {
        this.$set(
          this.selectedAnswers,
          index,
          [...this.selectedAnswers, answer]
        )
      },
      selectAllStatus(status) {
        switch(this.type) {
          case "records":
          case "traceability":
          case "traceability-qr":
            if (this.checkboxState.status)
              this.filtersObjects.record.status = _.map(status, s => s.value)
            else
              this.filtersObjects.record.status = []
            break;
          case "locations":
          case "storages":
            if (this.checkboxState.status)
              this.filtersObjects.locations.status = _.map(status, s => s.value)
            else
              this.filtersObjects.locations.status = []
            break;
        }
      },
      selectAllClassifications(classifications) {
        switch(this.type) {
          case "storages":
            if (this.checkboxState.sClassifications)
              this.$set(this.filtersObjects.storages, 'classificationTypes', [...classifications])
            else
              this.$set(this.filtersObjects.storages, 'classificationTypes', [])
            break;
          case "locations":
            if (this.checkboxState.lClassifications)
              this.$set(this.filtersObjects.locations, 'classificationTypes', [...classifications])
            else
              this.$set(this.filtersObjects.locations, 'classificationTypes', [])
            break;
        }
      },
      onChangeStatus() {
        switch(this.type) {
          case "records":
            this.checkboxState.status = this.filtersObjects.record.status.length === this.status.length;
            break;
          case "locations":
            this.checkboxState.status = this.filtersObjects.locations.status.length === this.status.length;
            break;
        }
      },
      selectAllOrNot(action, structure, ifCheck) {
        if (ifCheck)
          this.$set(
            this.data,
            structure.structure_id,
            this.transformItems(structure)
          )
        else this.$set(this.data, structure.structure_id, [])
      },
      clearSelectedStructures() {
        this.structuresByIds = []
        this.structureFilters = []
      },
      clearSelectedQuestions() {
        this.selectedQuestions = []
        this.selectedAnswers = []
      },
      async selectStructures(event) {
        if (
          event &&
          this.structureFilters &&
          this.structureFilters.length > 0
        ) {
          let ids = []
          _.forEach(event, structure => {
            ids.push(structure.id)
          })
          setTimeout(
            await this.fetchStructuresByIds([this.profile, ids, this.$toast]),
            5000
          )
        } else {
          this.structuresByIds = []
        }
      },
      async selectQuestions(event) {
        if (
          event &&
          this.questions &&
          this.questions.length > 0
        ) {
          let selectedQuestions = []
          _.forEach(event, question => {
            selectedQuestions.push(question)
          })
          setTimeout(
            await this.fetchAnswers([LanguajeService.getLenguajeName(), selectedQuestions, this.$toast]),
            5000
          )
        } else {
          this.selectedQuestions = []
        }
      },
      async showQuestionsByTypes(event) {
        if (
          event &&
          this.filters.template_names &&
          this.filters.template_names.length > 0
        ) {
          this.questions = []
          this.selectedQuestions = []
          this.answers = []
          this.selectedAnswers = []
          this.filters.questions = []
          this.filters.answers = []

          setTimeout(
            await this.fetchQuestions([this.filters.template_names, this.$toast]),
            5000
          )
        } else {
          this.questions = []
          this.selectedQuestions = []
          this.answers = []
          this.selectedAnswers = []
          this.filters.questions = []
          this.filters.answers = []
        }
      },
      transformItems(structure) {
        let data = []
        if (structure.structure_type === 'DATATIME') {
          _.forEach(structure.structure_values, value => {
            if (value && value !== '' && value !== '-') {
              value
                ? data.push(
                    moment(value.slice(0, 19)).format('DD/MM/YYYY HH:mm')
                  )
                : null
            }
          })
        } else if (structure.structure_type === 'DATA') {
          _.forEach(structure.structure_values, value => {
            if (value && value !== '' && value !== '-') {
              value
                ? data.push(moment(value.slice(0, 19)).format('DD/MM/YYYY'))
                : null
            }
          })
        } else if (structure.structure_type === 'TIME') {
          _.forEach(structure.structure_values, value => {
            if (value && value !== '' && value !== '-') {
              value
                ? data.push(moment(value.slice(0, 19)).format('HH:mm'))
                : null
            }
          })
        } else {
          _.forEach(structure.structure_values, value => {
            if (value && value !== '' && value !== '-') {
              value ? data.push(value) : null
            }
          })
        }
        return _.orderBy(data)
      },
      confirmFilters() {
        const page = { no: 1 }

        if (this.type === 'sellers') {
          this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_SELLERS')
          this.$root.$emit('setFiltersSellers')
        } else if (this.type === 'contracts') {
          this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_CONTRACTS')
          this.$root.$emit('setFiltersContracts')
        } else if (this.type === 'pre-records') {
          this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_PRE_RECORDS')
          this.$root.$emit('setFiltersPreRecords')
        } else if (this.type === 'records') {
          this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_RECORDS', this.data)
          this.$root.$emit('setFiltersRecords')
        } else if (this.type === 'inspections') {
          if(this.selectedAnswers && this.selectedAnswers.length > 0)
            this.filters.answers = this.selectedAnswers
          this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_INSPECTIONS')
          this.$root.$emit('setFiltersInspections')
        } else if (this.type === 'chart') {
          this.$root.$emit('setFiltersChart')
        } else if (this.type === 'documents') {
          this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_DOCUMENTS')
          this.$root.$emit('setFiltersDocuments')
        } else if (['locations', 'storages'].includes(this.type)) {
          if (this.type === 'locations') {
            this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_LOCATIONS')
            this.$root.$emit('setFiltersLocations')
          }

          if (this.type === 'storages') {
            this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_STORAGES')
            this.$root.$emit('setFiltersStorages')
          }
        } else if (this.type === 'traceability') {
          this.$root.$emit('setFiltersTraceability', page)
        } else if (this.type === 'producer') {
          this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_PRODUCER')
          this.$root.$emit('setFiltersProducer')
        } else if (this.type === 'traceability-qr') {
          this.$root.$emit('setFiltersQRTraceability', page)
        } else if (this.type === 'traceabilityV2') {
          this.$root.$emit('setFiltersTraceabilityV2')
        } else if (this.type === 'dashboard') {
          this.$root.$emit('setDashboardFilters')
        }

        this.drawer = false
      },
      cancelFilterDialog() {
        let payload = null
        this.$set(this, 'invalidSelection', {});
        this.invalidSectionsCount = 0;

        if (['traceability', 'traceabilityV2', 'traceability-qr', 'dashboard'].includes(this.type)) {
          this.$store.commit('ui/FILTERS_ORIGINAL_STATE', 'traceability')

          if (this.type === 'dashboard') {
            this.$root.$emit('setDashboardFilters')
            this.drawer = false
            return;
          }

          if (this.type === 'traceability') {
            this.$root.$emit('setFiltersTraceability', 1)
            this.drawer = false
            return;
          }

          if (this.type === 'traceability-qr') {
            this.$root.$emit('clear:selected');
          }
          this.$root.$emit('getResults')
        } else {
          this.$store.commit('ui/FILTERS_ORIGINAL_STATE')

          if (this.type === 'inspections') {
            this.questions = []
            this.selectedQuestions = []
            this.filters.questions = []
            this.answers = []
            this.selectedAnswers = []
            this.filters.answers = []

            /*INSPECTIONS SCORE*/
            this.filtersObjects.range = this.score
            this.filters.min_punctuation = this.score[0]
            this.filters.max_punctuation = this.score[1]
          }

          if (this.profile.company_owner === 'VOITER') {
            if (this.type === 'sellers' || this.type === 'contracts') {
              payload = {
                vN: [
                  this.volumesN['volumeN_min'],
                  this.volumesN['volumeN_max'],
                ],
                vB: [
                  this.volumesB['volumeB_min'],
                  this.volumesB['volumeB_max'],
                ],
                bP: [this.quebra['quebra_min'], this.quebra['quebra_max']],
              }

              this.contractsRanges.volumeN = [
                this.volumesN['volumeN_min'],
                this.volumesN['volumeN_max'],
              ]
              this.contractsRanges.volumeB = [
                this.volumesB['volumeB_min'],
                this.volumesB['volumeB_max'],
              ]
              this.contractsRanges.quebra = [
                this.quebra['quebra_min'],
                this.quebra['quebra_max'],
              ]
            }
          }

          this.$store.commit('ui/CLEAR_LOCAL_STORAGE', payload)

          if (this.type === 'locations') {
            this.$root.$emit('setFiltersLocations')
            this.$set(this.filtersObjects.locations, 'area', this.areaRanges.primary);
          }
          else if (this.type === 'storages') {
            this.$root.$emit('setFiltersStorages')
            this.$set(this.filtersObjects.locations, 'area', this.areaRanges.secondary);
          }
          else if (this.type === 'producer')
            this.$root.$emit('setFiltersProducer')
          else
            this.$root.$emit('getResultsPaginatedByCompany')
        }

        this.$root.$emit("clear:selected:lots")

        this.$root.$emit(`set:location:origin`, {
          locations: [],
          storageDevice: []
        })

        this.$root.$emit(`set:location:destination`, {
          locations: [],
          storageDevice: []
        })

        this.$root.$emit(`clear:locations:filters`, {
          locations: [],
          storageDevice: []
        })

        this.$root.$emit(`set:location:origin-${this.source}`, {
          locations: [],
          storageDevice: []
        })

        this.$root.$emit(`set:location:destination-${this.source}`, {
          locations: [],
          storageDevice: []
        })

        this.$root.$emit(`clear:locations:filters-${this.source}`, {
          locations: [],
          storageDevice: []
        })

        for (let check in this.checkboxState) {
          this.checkboxState[check] = false;
        }

        this.drawer = false
      },
      getDescriptionsType(length, data) {
        this.documentDescriptionFiltered(this.getByProperty(data, 'id'))

        return this.selectAll(
          'document_type',
          length,
          this.getByProperty(data, 'id'),
          'id',
          this.checkboxState.document_type
        )
      },
      async documentDescriptionFiltered(type) {
        this.loadingDescription = true
        if (!(type && type.length > 0)) {
          this.filters.document_description_ids = []
          this.loadingDescription = false
          return
        }

        await this.fetchListDocumentDescriptionsByType([
          this.profile.company_id,
          type,
          this.$toast,
        ]).finally(() => {
          this.loadingDescription = false
        })
      },
      getStorageByLocationsFilteredAction(data) {
        return data && data.length > 0
          ? _.filter(this.storages, storage => {
            console.log(storage)
              if (data.includes(storage.primaryLocationId)) {
                return storage
              }
            })
          : []
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      columnValueList(val) {
        return _.uniq(
          this.documents.map(d => {
            return d[val]
          })
        )
      },
      cleanDatePicker(ref) {
        let vm = this

        switch (ref) {
          case 'recordDateRef':
            vm.date.filtersDate = ''
            vm.date.filtersRecordDate = []
            vm.date.record_date_time = false
            break
          case 'registerDateRef':
            vm.date.filtersRegisterDate = ''
            vm.date.filtersRecordRegisterDate = []
            vm.date.sent_date_time = false
            break
          case 'documentDateRef':
            vm.filtersObjects.document.document_date = ''
            vm.date.filtersDocumentDate.document_date = []
            vm.date.document_date = false
            break
          case 'expirationDateRef':
            vm.filtersObjects.document.expiration_date = ''
            vm.date.filtersDocumentDate.expiration_date = []
            vm.date.expiration_date = false
            break
          case 'contractsDateRef':
            vm.filtersObjects.contracts_dates = ''
            vm.date.filtersContractsDate = []
            vm.date['contracts_dates'] = false
            break
        }
      },
      dateRangeTextDocument(field) {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (
          this.date.filtersDocumentDate[field] &&
          this.date.filtersDocumentDate[field].length > 1
        ) {
          if (this.date.filtersDocumentDate[field][0]) {
            ;[year, month, day] =
              this.date.filtersDocumentDate[field][0].split('-')
          }
          if (this.date.filtersDocumentDate[field][1]) {
            ;[year2, month2, day2] =
              this.date.filtersDocumentDate[field][1].split('-')
          }
          this.filtersObjects.document[
            field
          ] = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        } else {
          this.filtersObjects.document[field] = []
        }
      },
      deconstructRangeTextForFilters(dates, filter, field) {
        this.filters[filter] = []
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (dates.length > 1) {
          if (dates[0]) {
            ;[year, month, day] = dates[0].split('-')
            this.filters[filter][0] = moment(dates[0]).format('YYYY-MM-DD') + 'T00:00:01';
          }
          if (dates[1]) {
            ;[year2, month2, day2] = dates[1].split('-')
            this.filters[filter][1] = moment(dates[1]).format('YYYY-MM-DD') + 'T23:59:59';
          }
          this.date[field] = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },
      dateRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (this.date.filtersRecordDate.length > 1) {
          if (this.date.filtersRecordDate[0]) {
            ;[year, month, day] = this.date.filtersRecordDate[0].split('-')
            const currentTime = moment()
            this.filters.start_record_date_time = moment(this.date.filtersRecordDate[0]).format('YYYY-MM-DD') + 'T00:00:01'
          }
          if (this.date.filtersRecordDate[1]) {
            ;[year2, month2, day2] = this.date.filtersRecordDate[1].split('-')
            const currentTime = moment()
            this.filters.end_record_date_time = moment(this.date.filtersRecordDate[1]).format('YYYY-MM-DD') + 'T23:59:59'
            this.filters.start_record_date_time = [
              this.filters.start_record_date_time,
              this.filters.end_record_date_time,
            ]
          }
          this.date.filtersDate = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },
      processRangeDates(stringTag, field) {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'

        if (this.date[field] && this.date[field].length > 1) {
          if (this.date[field][0]) {
            ;[year, month, day] = this.date[field][0].split('-')
          }
          if (this.date[field][1]) {
            ;[year2, month2, day2] = this.date[field][1].split('-')
          }
          this.filtersObjects[
            stringTag
          ] = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        } else {
          this.filtersObjects[stringTag] = []
        }
      },
      filterByDate(field) {
        this.date[field] = false
      },
      getDateFormat(dateString) {
        return moment(new Date(dateString)).format(this.date.dateFormat)
      },
      replaceContractsDates(event) {
        this.filtersObjects.contracts_dates = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
    },
  }
</script>

<style scoped>
  :deep(.v-slide-group__prev),
  :deep(.v-slide-group__next)
  {
    min-width: 0px !important;
  }

  span :deep(.v-chip-group .v-chip--active) {
    color: #4b4b4b !important;
  }
</style>
