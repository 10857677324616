import {default as http} from './HttpService'

class ProcessService {
    constructor() {
    }

    proccess() {
        return http.client.get(`v1/company/web/process`);
    }

    proccessList() {
        return http.client.get(`v1/process/list`);
    }

    searchNomenclatorData(payload) {
        return http.client.post(`v1/process/nomenclators/string_filter`, payload);
    }

    processStructureSearch(search, structureId, source) {
        let options = {}

        if (source)
            options = { cancelToken: source.token }

        return http.client.post(`v1/process/process-structure-string-filter`, {
            "processStructureId": structureId,
            "filter": search || ""
        }, options);
    }

    getById(processId) {
        return http.client.get(`v1/process/${processId}/process-structure`);
    }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/process', item);
        } else {
            return http.client.post('/v1/process', item);
        }
    }

    saveRules(items) {
        return http.client.post('/v1/record_validation', items);
    }

    getRules(processId) {
        return http.client.get(`/v1/record_validation/${processId}`);
    }

    getProcessByVersionList(ids) {
        return http.client.post(`/v1/process/process_version_list`, {
            processVersionIds: ids
        });
    }

    delete(id) {
        return http.client.delete('/v1/process/' + id);
    }
}

export default new ProcessService()
