<template>
  <div class="quick-translation" :class="{ 'is-editing': isEditing }">
    <component 
      :is="tag" 
      v-if="!isEditing" 
      @click="startEditing"
      class="display-text"
      :class="{ 'hoverable': isEditable }"
    >
      {{ $t(translationKey) }}
      <div class="translation-badge" v-if="isEditable">
        <v-icon x-small>mdi-translate</v-icon>
      </div>
    </component>

    <!-- Modo edición -->
    <div v-else class="edit-container">
      <div class="lang-selector">
        <span 
          v-for="(lang, index) in languages" 
          :key="lang.value"
          @click="selectedLanguage = index"
          class="lang-tab"
          :class="{ active: selectedLanguage === index }"
        >
          {{ lang.text }}
        </span>
      </div>

      <div class="input-wrapper">
        <v-text-field
          v-model="editValue"
          :placeholder="$t(translationKey)"
          outlined
          dense
          :disabled="disabled"
          :loading="saving"
          @keyup.enter="saveTranslation"
          @keyup.esc="cancelEdit"
          hide-details
          class="edit-field"
          autofocus
        >
          <template v-slot:append>
            <div class="action-buttons">
              <v-icon 
                small 
                @click="saveTranslation" 
                color="success"
                class="action-icon"
              >
                mdi-check
              </v-icon>
              <v-icon 
                small 
                @click="cancelEdit" 
                color="error"
                class="action-icon"
              >
                mdi-close
              </v-icon>
            </div>
          </template>
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import LanguajeService from '@/services/LanguajeService';
import { getFallbackMessage } from '@/plugins/i18n';
import i18n from '@/plugins/i18n';
import _ from 'lodash';
import PermisionService from '@/services/PermisionService';

export default {
  name: 'QuickTranslation',

  props: {
    translationKey: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'span',
      validator: value => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isEditing: false,
      editValue: '',
      selectedLanguage: 0,
      saving: false,
      languages: [
        { text: 'EN', value: 'ENGLISH' },
        { text: 'ES', value: 'ESPANNOL' },
        { text: 'PT', value: 'PORTUGUESE' }
      ]
    };
  },

  created() {
    this.$nextTick(() => {
      this.selectedLanguage = this.getCurrentLanguageIndex();
    });

    this.$watch(
      () => this.$i18n.locale,
      () => {
        if (!this.isEditing) {
          this.selectedLanguage = this.getCurrentLanguageIndex();
        }
      }
    );
  },

  computed: {
    isEditable() {
      return !this.disabled && (this.checkAdmin() || this.check([{ domain: 'Translations', permisions: ['Write'] }]));
    }
  },

  methods: {
    getCurrentLanguageIndex() {
      const currentLang = LanguajeService.getLenguajeName();
      const index = this.languages.findIndex(lang => lang.value === currentLang);
      return index >= 0 ? index : 0; // Retornar 0 si no se encuentra el idioma
    },

    async startEditing() {
      if (this.disabled || !this.isEditable) return;
      
      this.selectedLanguage = this.getCurrentLanguageIndex();
      const lang = this.languages[this.selectedLanguage].value;
      const locale = LanguajeService.getKeyByLenguajeName(lang);
      
      // Get both server and fallback translations
      const serverTranslations = LanguajeService.customTranslations[locale] || {};
      const serverValue = _.get(serverTranslations, this.translationKey);
      const fallbackValue = getFallbackMessage(locale, this.translationKey);
      
      // Use server value if exists, otherwise fallback
      this.editValue = serverValue !== undefined ? serverValue : fallbackValue;
      this.isEditing = true;
    },

    async saveTranslation() {
      if (this.saving) return;

      const lang = this.languages[this.selectedLanguage].value;
      const locale = LanguajeService.getKeyByLenguajeName(lang);

      this.saving = true;
      try {
        // Si el valor está vacío, usar el valor por defecto
        const trimmedValue = this.editValue.trim();
        if (!trimmedValue) {
          // Obtener el valor por defecto del fallback
          const fallbackValue = getFallbackMessage(locale, this.translationKey);
          this.editValue = fallbackValue;
          
          // Si hay una traducción personalizada, eliminarla para restaurar el valor por defecto
          if (_.get(LanguajeService.customTranslations[locale], this.translationKey) !== undefined) {
            await LanguajeService.saveCustomTranslation(locale, this.translationKey, null);
          }
        } else {
          // Guardar el nuevo valor si no está vacío
          await LanguajeService.saveCustomTranslation(locale, this.translationKey, trimmedValue);
        }

        // Recargar traducciones completas después de guardar
        await LanguajeService.fetchCustomTranslations(locale);
        this.$emit('translation-updated', { locale, key: this.translationKey });
        this.isEditing = false;
      } catch (error) {
        console.error('Error saving translation:', error);
      } finally {
        this.saving = false;
      }
    },

    cancelEdit() {
      this.isEditing = false;
    },

    // Add permission check methods
    check(permissions) {
      return PermisionService.check(permissions);
    },

    checkAdmin() {
      return PermisionService.checkAdmin();
    }
  },

  watch: {
    selectedLanguage() {
      if (this.isEditing) {
        const lang = this.languages[this.selectedLanguage].value;
        const locale = LanguajeService.getKeyByLenguajeName(lang);
        
        // Get both server and fallback translations
        const serverTranslations = LanguajeService.customTranslations[locale] || {};
        const serverValue = _.get(serverTranslations, this.translationKey);
        const fallbackValue = getFallbackMessage(locale, this.translationKey);
        
        // Use server value if exists, otherwise fallback
        this.editValue = serverValue !== undefined ? serverValue : fallbackValue;
      }
    }
  }
};
</script>

<style scoped>
.quick-translation {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.display-text {
  padding: 4px 8px;
  position: relative;
  transition: all 0.2s ease;
  cursor: pointer;
}

.display-text.hoverable:hover {
  color: var(--v-primary-base);
}

.translation-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  padding: 3px;
  opacity: 0;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.translation-badge .v-icon {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 !important;
}

.hoverable:hover .translation-badge {
  opacity: 1;
  background: var(--v-primary-base);
}

.hoverable:hover .translation-badge .v-icon {
  color: white;
}

.edit-container {
  position: relative;
  min-width: 200px;
  max-width: 400px;
  background: white;
  border-radius: 6px;
  padding-top: 24px;
}

.lang-selector {
  position: absolute;
  top: 0px;
  right: 10px;
  display: flex;
  gap: 2px;
  padding: 2px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px 4px 0 0;
  border-bottom: none;
}

.lang-tab {
  padding: 2px 6px;
  font-size: 11px;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  transition: all 0.2s ease;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 16px;
}

.lang-tab:hover {
  background: rgba(0, 0, 0, 0.05);
}

.lang-tab.active {
  background: var(--v-primary-base);
  color: white;
}

.input-wrapper {
  border-radius: .3rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.edit-field {
  width: 100%;
}

.action-buttons {
  display: flex;
  gap: 4px;
  padding-left: 8px;
  margin-bottom: 8px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.action-icon {
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-icon:hover {
  background: rgba(0, 0, 0, 0.04);
}

:deep(.v-input__slot) {
  margin-bottom: 0 !important;
  min-height: 36px !important;
}

:deep(.v-text-field__details) {
  display: none;
}
</style>
