import OrderListService from "@/services/OrderListService";
import i18n from '@/plugins/i18n'
import _ from "lodash";
import LanguajeService from "@/services/LanguajeService"

export default {
    locationsList: state => OrderListService.orderListByUppercase(state.locationsList, 'name'),
    sLocations: state => OrderListService.orderListByUppercase(state.sLocations, 'name'),
    locationsListForFilters: state => OrderListService.orderListByUppercase(state.locationsListForFilters, 'name'),
    GET_LOCATIONS_FOR_FILTERS: state => OrderListService.orderListByUppercase(state.locationsForFilters, 'name'),
    totalFromServer: state => state.totalFromServer,
    versions: state => state.versions,
    totalFromServerVersions: state => state.totalFromServerVersions,
    totalFromServerForFilters: state => state.totalFromServerForFilters,
    personInCharge: state => state.personInCharge,
    locationByUserAccess: state => state.locationByUserAccess,
    activeLocationsList: state => state.activeLocationsList,
    mapConfig: state => state.mapConfig,
    GET_CLASSIFICATIONS: state => _.map(state.classifications, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.languageKey)
        }
    }),
    GET_BADGE_CLASSIFICATIONS: state => _.map(state.badgeClassifications, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.languageKey)
        }
    }),

    // ------------ EXTRA COLUMNS ----------------
    GET_COLUMNS: state => state.columns,
    GET_SELECTED_COLUMNS: state => state.selectedColumns,
    GET_HEADERS: state => (columns) => {
        // Base columns that are always displayed
        const baseColumns = [
            {
                text: "", 
                value: "statusLine",
                sortable: false
            },
            {
                text: i18n.t("records.fields.status"),
                val: "status",
                value: "status",
                sortable: true
            },
            {
                text: i18n.t("locations.fields.name"),
                value: "name",
                sortable: true
            },
            {
                text: i18n.t("locations.fields.storage_type"),
                value: "storageDeviceType",
                val: "storage_device_types_ids",
                sortable: false
            },
            {
                text: i18n.t("locations.fields.gln"),
                value: "gln"
            },
            {
                text: i18n.t("locations.fields.active"),
                value: "active",
                val: "isActive",
                align: "center",
                width: 120,
            }
        ];

        // All available dynamic column configurations
        const allColumnConfigs = {
            'DOCUMENT_KEY': {
                text: i18n.t('locations.fields.identificationType'),
                value: 'document_key',
                val: "document_key",
                width: 100,
                sortable: false
            },
            'DOCUMENT_VALUE': {
                text: i18n.t('locations.fields.identificationValue'),
                value: 'document_value',
                val: "document_value",
                width: 100,
                sortable: false
            },
            'AREA': {
                text: i18n.t('storage.fields.area'),
                value: 'area',
                val: "area",
                width: 100,
                sortable: false
            },
            'LONGITUDE': {
                text: i18n.t('storage.fields.longitude'),
                value: 'longitude',
                sortable: false
            },
            'LATITUDE': {
                text: i18n.t('storage.fields.latitude'),
                value: 'latitude',
                sortable: false
            },
            'MEASUREMENT_UNIT': {
                text: i18n.t('storage.fields.measurement_unit'),
                value: 'measurementUnit',
                width: 150,
                sortable: false
            },
            'TELEPHONE': {
                text: i18n.t("locations.fields.telephone"),
                value: "telephone"
            },
            'EMAIL': {
                text: i18n.t("locations.fields.email"),
                value: "email"
            },
            'ADDRESS1': {
                text: i18n.t('locations.fields.address1'),
                value: 'address1',
                sortable: false
            },
            'ADDRESS2': {
                text: i18n.t('locations.fields.address2'),
                value: 'address2',
                sortable: false
            },
            'CITY': {
                text: i18n.t('locations.fields.city'),
                value: 'city',
                sortable: false
            },
            'STATE': {
                text: i18n.t('locations.fields.state'),
                value: 'state',
                sortable: false
            },
            'COUNTRY': {
                text: i18n.t('locations.fields.country'),
                value: 'country',
                sortable: false
            },
            'ZIP_CODE': {
                text: i18n.t('locations.fields.zipCode'),
                value: 'zipCode',
                sortable: false
            },
            'DESCRIPTION': {
                text: i18n.t('locations.fields.description'),
                value: 'description',
                sortable: false
            },
            'CLASSIFICATION_TYPES': {
                text: i18n.t('locations.fields.classificationType'),
                value: 'classificationTypes',
                sortable: false
            },
            'COMMERCIAL_SITE': {
                text: i18n.t('locations.fields.commercialSite'),
                value: 'comercialSite',
                sortable: false
            },
            'COLOR': {
                text: i18n.t('locations.fields.color'),
                value: 'color',
                sortable: false
            }
        };

        // Build dynamic columns in the exact order they appear in the columns array
        const dynamicColumns = columns.map(column => {
            if (allColumnConfigs[column.key]) {
                return { ...allColumnConfigs[column.key] };
            }
            return null;
        }).filter(Boolean); // Remove any nulls

        // Action column (always at the end)
        const actionColumn = [{
            text: i18n.t('common.fields.action'),
            value: 'action',
            width: 160,
            sortable: false,
            align: 'center'
        }];

        // Combine all columns
        return [...baseColumns, ...dynamicColumns, ...actionColumn];
    },

    GET_EXTRA_COLUMNS: state => ([
        {key: 'AREA', name: i18n.t('storage.fields.area')},
        {key: 'MEASUREMENT_UNIT', name: i18n.t('storage.fields.measurement_unit')},
        {key: 'DOCUMENT_KEY', name: i18n.t('locations.fields.identificationType')},
        {key: 'DOCUMENT_VALUE', name: i18n.t('locations.fields.identificationValue')},
        {key: 'EMAIL', name: i18n.t('locations.fields.email')},
        {key: 'TELEPHONE', name: i18n.t('locations.fields.telephone')},
        {key: 'ADDRESS1', name: i18n.t('locations.fields.address1')},
        {key: 'ADDRESS2', name: i18n.t('locations.fields.address2')},
        {key: 'CITY', name: i18n.t('locations.fields.city')},
        {key: 'STATE', name: i18n.t('locations.fields.state')},
        {key: 'COUNTRY', name: i18n.t('locations.fields.country')},
        {key: 'ZIP_CODE', name: i18n.t('locations.fields.zipCode')},
        {key: 'COLOR', name: i18n.t('locations.fields.color')},
        {key: 'COMMERCIAL_SITE', name: i18n.t('locations.fields.commercialSite')},
        {key: 'DESCRIPTION', name: i18n.t('locations.fields.description')},
        {key: 'LONGITUDE', name: i18n.t('storage.fields.longitude')},
        {key: 'LATITUDE', name: i18n.t('storage.fields.latitude')},
        {key: 'CLASSIFICATION_TYPES', name: i18n.t('locations.fields.classification')}
    ])
}
