<template>
  <aside>
    <v-navigation-drawer :clipped="true" app color="secondary">
      <template v-slot:append>
        <code class="t-bw-secondary--text">powered by <strong>AgTrace</strong></code>
      </template>

      <v-list nav dense id="sidenav">
        <v-list-item-group active-class="t-bw-secondary--text">
          <!--          Dashboard-->
          <v-list-item active-class="t-bw-secondary--text" href="dashboard" :to="{name: 'dashboard'}"
                       v-if="check(rules.Dashboard.permissionRequired)">
            <v-list-item-icon class="navigation-icon">
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="navigation-title-font">{{ $t("dashboard.label") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--*****************-->
          <!--          Modules-->
          <v-list-group color="t-bw-secondary" value="true" v-if="check(rules.Modules.permissionRequired)">
            <template v-slot:activator>
              <v-list-item-icon class="navigation-icon">
                <v-icon>mdi-drawing-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("menu.modules") }}
              </v-list-item-title>
            </template>

            <v-list-item color="t-bw-secondary" active-class="t-bw-secondary--text" link href="records" :to="{name: 'records'}"
                         v-if="check(rules.Modules.subMenu.Record.permissionRequired)">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-calendar-multiple-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("records.label") }}
              </v-list-item-title>

            </v-list-item>

            <v-list-item active-class="t-bw-secondary--text" link href="documents"
                         v-if="check(rules.Modules.subMenu.Documents.permissionRequired)"
                         :to="{name: 'documents'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("documents.label") }}
              </v-list-item-title>

            </v-list-item>

            <v-list-item active-class="t-bw-secondary--text" link href="inspections"
                         v-if="check(rules.Modules.subMenu.Inspections.permissionRequired)"

                         :to="{name: 'inspections'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-order-bool-ascending-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("inspections.label") }}
              </v-list-item-title>

            </v-list-item>

            <v-list-group
              v-if="check(rules.Modules.subMenu.Tokens.permissionRequired)"
              sub-group
              prepend-icon=""
              color="t-bw-secondary"
            >
              <template v-slot:activator>
                <v-list-item-icon class="navigation-icon">
                  <v-icon active-class="t-bw-secondary--text">mdi-poker-chip</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="navigation-title-font">{{ $t("tokens.label") }}
                </v-list-item-title>
              </template>

              <v-list-item active-class="t-bw-secondary--text" link href="token-stock-module"
                           :to="{name: 'token-stock'}"
                           v-if="check(rules.Modules.subMenu.Tokens.subMenu.Stock.permissionRequired)">
                <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                  <v-icon>mdi-archive</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="navigation-title-font">{{ $t("tokens.menu.tokenStock") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item active-class="t-bw-secondary--text" link href="wallet-module"
                           :to="{name: 'token-wallet'}"
                           v-if="check(rules.Modules.subMenu.Tokens.subMenu.Wallet.permissionRequired)">
                <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                  <v-icon>mdi-credit-card</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="navigation-title-font">{{ $t("tokens.menu.wallet") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item active-class="t-bw-secondary--text" link href="contracts-module"
                           :to="{name: 'token-contracts'}"
                           v-if="check(rules.Modules.subMenu.Tokens.subMenu.Contracts.permissionRequired)">
                <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                  <v-icon>mdi-database</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="navigation-title-font">{{ $t("tokens.menu.contracts") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item active-class="t-bw-secondary--text" link href="faq"
                           :to="{name: 'token-faq'}"
                           v-if="check(rules.Modules.subMenu.Tokens.subMenu.Wallet.permissionRequired)">
                <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                  <v-icon>mdi-frequently-asked-questions</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="navigation-title-font">{{ $t("tokens.menu.faq") }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

          </v-list-group>
          <!--*****************-->
          <!--          Reports-->
          <v-list-group color="t-bw-secondary" v-if="check(rules.Reports.permissionRequired)">
            <template v-slot:activator>
              <v-list-item-icon class="navigation-icon">
                <v-icon>mdi-cloud-print-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("reports.label") }}</v-list-item-title>
            </template>
            <v-list-item active-class="t-bw-secondary--text" link href="traceability-view"
                         v-if="check(rules.Reports.subMenu.Traceability.permissionRequired)"
                         :to="{name: 'traceability-view'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-transit-detour</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("reports.menu.traceability-report") }}
              </v-list-item-title>
            </v-list-item>
            <!--<v-list-item link href="traceability-report"
                         v-if="check(rules.Reports.subMenu.Traceability.permissionRequired)"
                         :to="{name: 'traceability-report'}">
                <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                    <v-icon>mdi-transit-detour</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="navigation-title-font">{{ $t('reports.titles.traceability') }}
                </v-list-item-title>
            </v-list-item>-->
            <!--                        <v-list-item link href="biorganic-report"-->
            <!--                                     v-if="check(rules.Reports.subMenu.TraceabilityV2.permissionRequired)"-->
            <!--                                     :to="{name: 'traceabilityV2-report'}">-->
            <!--                            <v-list-item-icon class="navigation-icon navigation-icon-group-item">-->
            <!--                                <v-icon>mdi-transit-detour</v-icon>-->
            <!--                            </v-list-item-icon>-->
            <!--                            <v-list-item-title class="navigation-title-font">{{ $t('reports.titles.traceability') }} v2-->
            <!--                            </v-list-item-title>-->
            <!--                        </v-list-item>-->
            <v-list-item active-class="t-bw-secondary--text" link href="stock-report"
                         v-if="check(rules.Reports.subMenu.Stock.permissionRequired)"
                         :to="{name: 'stock-report'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-inbox-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("reports.menu.stock-report") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" link href="general-report"
                         v-if="check(rules.Reports.subMenu.General.permissionRequired)"
                         :to="{name: 'general-report'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-chart-donut-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("reports.menu.general-report") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" link href="biorganic-report"
                         v-if="(profile.company_owner === 'SITIO_DA_CHUVA_DEV' || profile.company_owner === 'SITIO_DA_CHUVA' || profile.company_owner==='AG_TRACE') && check(rules.Reports.subMenu.Biorganic.permissionRequired)"
                         :to="{name: 'biorganic-report'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-account-heart</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("reports.menu.biorganic-report") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!--*****************-->
          <!--          Integrations-->
          <v-list-item active-class="t-bw-secondary--text" href="integrations"
                       :to="{path: `/integrations/${profile.company_owner.toLowerCase() || 'all'}`}"
                       v-if="check(rules.Integrations.permissionRequired)">
            <v-list-item-icon class="navigation-icon">
              <v-icon>mdi-webhook</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="navigation-title-font">{{ $t("integrations.label") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--*****************-->
          <!--          Entries-->
          <v-list-group color="t-bw-secondary" v-if="check(rules.Entries.permissionRequired)">
            <template v-slot:activator>
              <v-list-item-icon class="navigation-icon">
                <v-icon> mdi-book-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("menu.entries") }}
              </v-list-item-title>
            </template>


            <v-list-item active-class="t-bw-secondary--text" href="locations"
                         v-if="check(rules.Entries.subMenu.Location.permissionRequired)"
                         :to="{name: 'locations'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-barn</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("locations.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="storage"
                         v-if="check(rules.Entries.subMenu.StorageDevice.permissionRequired)"
                         :to="{name: 'storage'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-map-marker-radius</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("storage.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="products"
                         v-if="check(rules.Entries.subMenu.Culture.permissionRequired)"
                         :to="{name: 'products'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-package-variant-closed</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("product.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="seasons"
                         v-if="check(rules.Entries.subMenu.Season.permissionRequired)"
                         :to="{name: 'seasons'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-barley</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("season.label") }}</v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="inputs"
                         v-if="check(rules.Entries.subMenu.Input.permissionRequired)"
                         :to="{name: 'inputs'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-sack</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("input.label") }}</v-list-item-title>

            </v-list-item>

            <v-list-item active-class="t-bw-secondary--text" href="operators"
                         v-if="check(rules.Entries.subMenu.Operator.permissionRequired)"
                         :to="{name: 'operators'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-account-hard-hat</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("operator.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="producers"
                         v-if="check(rules.Entries.subMenu.Producer.permissionRequired)"
                         :to="{name: 'producers'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-account-settings</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("producer.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="equipments"
                         v-if="check(rules.Entries.subMenu.Equipment.permissionRequired)"
                         :to="{name: 'equipments'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-tractor</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("equipment.label") }}
              </v-list-item-title>

            </v-list-item>

          </v-list-group>
          <!--*****************-->
          <!--          Config-->
          <v-list-group color="t-bw-secondary" v-if="check(rules.Configuration.permissionRequired)">
            <template v-slot:activator>
              <v-list-item-icon class="navigation-icon">
                <v-icon>mdi-cloud-print-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("menu.configuration") }}
              </v-list-item-title>
            </template>
            <v-list-item color="t-bw-secondary" active-class="t-bw-secondary--text" href="companies"
                         v-if="check(rules.Configuration.subMenu.Company.permissionRequired)"
                         :to="{name: 'companies'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-briefcase</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("company.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="storage-types"
                         v-if="check(rules.Configuration.subMenu.StorageDeviceType.permissionRequired)"
                         :to="{name: 'storage-types'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-database-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("storageType.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="details"
                         v-if="check(rules.Configuration.subMenu.Structure.permissionRequired)"
                         :to="{name: 'details'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-text-box-plus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("structure.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="proccess"
                         v-if="check(rules.Configuration.subMenu.Process.permissionRequired)"
                         :to="{name: 'proccess'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-all-inclusive</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("process.label") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" link href="prerecords" :to="{name: 'prerecords'}"
                         v-if="check(rules.Configuration.subMenu.PreRecord.permissionRequired)">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-calendar-multiple-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("pre-records.label") }}
              </v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="survey"
                         v-if="check(rules.Configuration.subMenu.SurveyAdm.permissionRequired)"
                         :to="{name: 'survey'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-order-bool-ascending-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("survey.label") }}</v-list-item-title>

            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="authority"
                         v-if="check(rules.Configuration.subMenu.PersonInCharge.permissionRequired)"
                         :to="{name: 'authority'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-account-key</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("authority.label") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="authority" v-if="check(rules.Configuration.subMenu.Role.permissionRequired)"
                         :to="{name: 'roles'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon> mdi-shield-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("roles.label") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item active-class="t-bw-secondary--text" href="translations"
                          v-if="check(rules.Configuration.subMenu.Translations.permissionRequired)"
                         :to="{name: 'translations'}">
              <v-list-item-icon class="navigation-icon navigation-icon-group-item">
                <v-icon>mdi-translate-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="navigation-title-font">{{ $t("translations.label") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!--*****************-->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <filters-menu />
  </aside>
</template>
<script>
  import PermissionService from "@/services/PermisionService"
  import FiltersMenu from "../../components/FiltersMenu"

  export default {
    components: { FiltersMenu },
    name: "app-layout-sidenav",
    data: () => ({
      user: require("@/assets/img/user.png"),
      logo: require("@/assets/img/Logo AgTrace Original-Branco.svg"),
      username: "UserLoad",
      drawer: true,
      profile: null,
      rules: null,
      reload: false,
      integrations: {
        ABRAPA: null,
      },
      loadingCompanies: false
    }),

    created() {
      this.profile = JSON.parse(localStorage.getItem("profile"))

      this.integrations.ABRAPA = this.$store.getters["integration/IS_PRESENT"]("ABRAPA")

      this.username = this.profile.name
      this.rules = {
        Dashboard: {
          link: "/dashboard",
          icon: "mdi-view-dashboard",
          permissionRequired: [
            { domain: "Dashboard", permisions: ["Read"] },
          ],
        },
        Integrations: {
          link: "/integrations",
          icon: "mdi-webhook",
          permissionRequired: [
            { domain: "AbrapaProductAPI", permisions: ["Write"] },
            { domain: "AbrapaOrderAPI", permisions: ["Write"] },
          ],
        },
        Modules: {
          icon: "mdi-drawing-box",
          permissionRequired: [
            { domain: "Record", permisions: ["Read"] },
            { domain: "Document", permisions: ["Read"] },
            { domain: "Survey", permisions: ["Read"] },
            { domain: "TokenWallet", permisions: ["Read"] },
          ],
          subMenu: {
            Record: {
              link: "/appointments",
              module: "Record",
              icon: "mdi-calendar-multiple-check",
              permissionRequired: [
                { domain: "Record", permisions: ["Read"] },
              ],
            },
            Documents: {
              link: "/documents",
              module: "Document",
              icon: "mdi-file",
              permissionRequired: [
                { domain: "Document", permisions: ["Read"] },
              ],
            },
            Inspections: {
              link: "/inspections",
              module: "Inspection",
              icon: "mdi-file",
              permissionRequired: [
                { domain: "Survey", permisions: ["Read"] },
              ],
            },
            Tokens: {
              link: "/tokens",
              module: "Tokens",
              icon: "mdi-file",
              permissionRequired: [
                { domain: "TokenStock", permisions: ["Read"] },
                { domain: "TokenWallet", permisions: ["Read"] },
                { domain: "TokenContract", permisions: ["Read"] },
              ],
              subMenu: {
                Stock: {
                  link: "/tokens/stock",
                  show: false,
                  permissionRequired: [
                    { domain: "TokenStock", permisions: ["Read"] },
                  ],
                  module: "TokenStock",
                  icon: "mdi-transit-detour",
                },
                Wallet: {
                  link: "/tokens/wallet",
                  show: false,
                  permissionRequired: [
                    { domain: "TokenWallet", permisions: ["Read"] },
                  ],
                  module: "Wallet",
                  icon: "mdi-transit-detour",
                },
                Contracts: {
                  link: "/tokens/contracts",
                  show: false,
                  permissionRequired: [
                    { domain: "TokenContract", permisions: ["Read"] },
                  ],
                  module: "Contracts",
                  icon: "mdi-transit-detour",
                },
              },
            },
          },
        },
        Reports: {
          show: false,
          leaves: true,
          icon: "mdi-cloud-print-outline",
          permissionRequired: [
            { domain: "StockReport", permisions: ["Read"] },
            { domain: "Traceability", permisions: ["Read"] },
            { domain: "GeneralReport", permisions: ["Read"] },
            { domain: "BiorganicReport", permisions: ["Read"] },
            { domain: "TraceabilityV2Report", permisions: ["Read"] },
          ],
          subMenu: {
            Stock: {
              link: "/reports/stock-report",
              show: false,
              permissionRequired: [
                { domain: "StockReport", permisions: ["Read"] },
              ],
              module: "Stock",
              icon: "mdi-transit-detour",
            },
            Traceability: {
              link: "/reports/traceability",
              show: false,
              permissionRequired: [
                { domain: "Traceability", permisions: ["Read"] },
              ],
              module: "Traceability",
              icon: "mdi-transit-detour",
            },
            General: {
              link: "/reports/general-report",
              show: false,
              permissionRequired: [
                { domain: "GeneralReport", permisions: ["Read"] },
              ],
              module: "Stock",
              icon: "chart-donut-variant",
            },
            Biorganic: {
              link: "/reports/biorganic-report",
              show: false,
              permissionRequired: [
                { domain: "BiorganicReport", permisions: ["Read"] },
              ],
              module: "BiorganicReport",
              icon: "bio",
            },
            TraceabilityV2: {
              link: "/reports/traceabilityV2-report",
              show: false,
              permissionRequired: [
                { domain: "TraceabilityV2Report", permisions: ["Read"] },
              ],
              module: "TraceabilityV2Report",
              icon: "bio",
            },
          },
        },
        Entries: {
          icon: "mdi-book-plus",
          leaves: true,
          show: false,
          permissionRequired: [
            { domain: "Location", permisions: ["Read"] },
            { domain: "StorageDevice", permisions: ["Read"] },
            { domain: "Culture", permisions: ["Read"] },
            { domain: "Season", permisions: ["Read"] },
            { domain: "Input", permisions: ["Read"] },
            { domain: "Operator", permisions: ["Read"] },
            { domain: "Equipment", permisions: ["Read"] },
          ],
          subMenu: {
            Location: {
              link: "/nomenclators/locations",
              show: false,
              module: "Location",
              permissionRequired: [
                { domain: "Location", permisions: ["Read"] },
              ],
              icon: "mdi-barn",
            },
            StorageDevice: {
              link: "/nomenclators/storage",
              show: false,
              permissionRequired: [
                { domain: "StorageDevice", permisions: ["Read"] },
              ],
              module: "StorageDevice",
              icon: "mdi-map-marker-radius",
            },
            Culture: {
              link: "/nomenclators/products",
              show: false,
              permissionRequired: [
                { domain: "Culture", permisions: ["Read"] },
              ],
              module: "Culture",
              icon: "mdi-package-variant-closed",
            },
            Season: {
              link: "/nomenclators/seasons",
              show: false,
              permissionRequired: [
                { domain: "Season", permisions: ["Read"] },
              ],
              module: "Season",
              icon: "mdi-barley",
            },
            Input: {
              link: "/nomenclators/inputs",
              show: false,
              permissionRequired: [
                { domain: "Input", permisions: ["Read"] },
              ],
              module: "Input",
              icon: "mdi-sack",
            },
            Operator: {
              link: "/nomenclators/operators",
              show: false,
              permissionRequired: [
                { domain: "Operator", permisions: ["Read"] },
              ],
              module: "Operator",
              icon: "mdi-account-hard-hat",
            },
            Producer: {
              link: "/nomenclators/producers",
              show: false,
              permissionRequired: [
                { domain: "Producer", permisions: ["Read"] },
              ],
              module: "Producer",
              icon: "mdi-account-settings",
            },
            Equipment: {
              link: "/nomenclators/equipments",
              show: false,
              permissionRequired: [
                { domain: "Equipment", permisions: ["Read"] },
              ],
              module: "Equipment",
              icon: "mdi-tractor",
            },
          },
        },
        Configuration: {
          icon: "mdi-book-plus",
          leaves: true,
          show: false,
          permissionRequired: [
            { domain: "Company", permisions: ["Read"] },
            { domain: "StorageDeviceType", permisions: ["Read"] },
            { domain: "Structure", permisions: ["Read"] },
            { domain: "Process", permisions: ["Read"] },
            { domain: "PreRecord", permisions: ["Read"] },
            { domain: "surveyAdm", permisions: ["Read"] },
            { domain: "PersonInCharge", permisions: ["Read"] },
            { domain: "Role", permisions: ["Read"] },
            { domain: "Translations", permisions: ["Read"] },
          ],
          subMenu: {
            Company: {
              link: "/companies",
              show: false,
              module: "Company",
              icon: "mdi-briefcase",
              permissionRequired: [
                { domain: "Company", permisions: ["Read"] },
              ],
            },
            Translations: {
              link: "/translations",
              show: false,
              module: "Translations",
              icon: "mdi-translate-variant",
              permissionRequired: [
                { domain: "Translations", permisions: ["Read"] },
              ],
            },
            StorageDeviceType: {
              link: "/nomenclators/storage-types",
              show: false,
              module: "StorageDeviceType",
              icon: "mdi-database-plus",
              permissionRequired: [
                { domain: "StorageDeviceType", permisions: ["Read"] },
              ],
            },
            PreRecord: {
              leaves: true,
              show: false,
              permissionRequired: [
                { domain: "PreRecord", permisions: ["Read"] },
              ],
            },
            Process: {
              link: "/nomenclators/proccess",
              show: false,
              permissionRequired: [
                { domain: "Process", permisions: ["Read"] },
              ],
              module: "Process",
              icon: "mdi-all-inclusive",
            },
            PersonInCharge: {
              link: "/nomenclators/authority",
              show: false,
              module: "PersonInCharge",
              permissionRequired: [
                { domain: "PersonInCharge", permisions: ["Read"] },
              ],
              icon: "mdi-account-key",
            },
            Role: {
              link: "/nomenclators/profiles",
              show: false,
              permissionRequired: [
                { domain: "Role", permisions: ["Read"] },
              ],
              module: "Role",
              icon: "mdi-account-key",
            },
            Structure: {
              link: "/nomenclators/details",
              show: false,
              permissionRequired: [
                { domain: "Structure", permisions: ["Read"] },
              ],
              module: "Structure",
              icon: "mdi-text-box-plus-outline",
            },
            SurveyAdm: {
              link: "/nomenclators/survey",
              module: "survey",
              icon: "mdi-calendar-multiple-check",
              permissionRequired: [
                { domain: "surveyAdm", permisions: ["Read"] },
              ],
            },
          },
        },
      }
    },

    async mounted() {
      this.$root.$on("update:company", (profile) => {
        this.$set(this, "profile", profile)
      })
    },

    methods: {
      check(permissions) {
        return PermissionService.check(permissions)
      },
    },

    destroyed() {
      this.$root.$off("update:company")
    },
  }
</script>
<style lang="scss" scoped>
  .v-navigation-drawer {
    height: 100vh;
    top: 64px;
    max-height: 100% !important;
    transform: translateX(0%);
    width: 256px;
  }

  .v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
    margin-right: 10px;
    margin-left: 10px;
  }

  .v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > div > .v-list-item {
    padding-left: 68px;
  }

  .v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
    padding-left: 68px;
  }

  :deep(.v-list-item) {
    color: var(--v-t-bw-secondary-base) !important;
  }

  :deep(.v-list-group__header__append-icon) i {
    color: var(--v-t-bw-secondary-base) !important;
  }

  :deep(a.v-list-item--active) {
    color: var(--v-t-bw-secondary-base) !important;
  }

  a.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--v-t-bw-secondary-base) !important;
  }

  div.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--v-t-bw-secondary-base) !important;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) i {
    color: var(--v-t-bw-secondary-base) !important;
  }

  .custom-menu {
    margin: 0 !important;
    left: 8px !important;
  }

  :deep(div.v-navigation-drawer__append) {
    text-align: center !important;
    margin-bottom: 80px;
    color: var(--v-t-bw-secondary-darken2) !important;
    font-weight: normal;
  }
</style>
