<template>
  <div>
    <p :class="[classes, 'read-more-text']" class="black--text" :style="{ 'max-width': '100%', fontSize: fontSize + 'px' }">
      {{ $t(formattedString) }}
      <span v-show="text.length > maxChars">
        <v-chip x-small id="readmore" v-show="!isReadMore && layout === 'text'" @click.stop="triggerReadMore($event, true)">
          {{ $t(moreStr) }}
        </v-chip>
        <v-chip x-small id="readmore" v-show="isReadMore && layout === 'text'" @click.stop="triggerReadMore($event, false)">
          {{ $t(lessStr) }}
        </v-chip>
        <v-chip x-small id="readmore" v-show="layout === 'dialog'" @click.stop="showDialog = true">
          {{ $t(moreStr) }}
        </v-chip>
      </span>
    </p>
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card>
        <v-card-title>{{ i18n.t('locations.fields.description') }}</v-card-title>
        <v-card-text class="dialog-content">
          {{ $t(text) }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" text @click="showDialog = false">
            {{ $t(lessStr) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import i18n from '@/plugins/i18n';

  export default {
    name: 'ReadMore',
    props: {
      fontSize: {
        type: Number,
        default: 22
      },
      moreStr: {
        type: String,
        default: 'inspections.labels.readMore'
      },
      lessStr: {
        type: String,
        default: 'inspections.labels.readLess'
      },
      text: {
        type: String,
        required: false,
        default: ''
      },
      link: {
        type: String,
        default: '#'
      },
      maxChars: {
        type: Number,
        default: 100
      },
      classes: {
        type: String
      },
      layout: {
        type: String,
        default: 'text'
      },
      title: {
        type: String,
        default: 'inspections.labels.description'
      }
    },
    setup(props) {
      const isReadMore = ref(false);
      const showDialog = ref(false);

      const formattedString = computed(() => {
        let val_container = props.text;

        if (!isReadMore.value && props.text.length > props.maxChars) {
          val_container = val_container.substring(0, props.maxChars) + '...';
        }

        return val_container;
      });

      const triggerReadMore = (e, b) => {
        if (props.link === '#') {
          e.preventDefault();
        }
        if (props.lessStr !== null || props.lessStr !== '') {
          isReadMore.value = b;
        }
      };

      return {
        isReadMore,
        showDialog,
        formattedString,
        triggerReadMore,
        i18n
      };
    }
  };
</script>

<style>
  .dialog-content {
    max-height: 300px;
    overflow-y: auto;
  }

  .read-more-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    line-height: 1.4;
  }
</style>
