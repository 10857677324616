import {mapActions, mapGetters} from "vuex";
import sortList from "@/services/OrderListService";
import _ from "lodash";

export const CommonLocationFilterMixin = {
  props: {
    twoColumns: {
      type: Boolean,
      required: false,
      default: false
    },
    showOrigin: {
      type: Boolean,
      required: false,
      default: false
    },
    showDestination: {
      type: Boolean,
      required: false
    },
    locationRef: {
      type: String,
      required: false
    },
    locationsSelected: {
      type: Array,
      required: false,
      default: () => ([])
    },
    storagesSelected: {
      type: Array,
      required: false,
      default: () => ([])
    },
    storageRef: {
      type: String,
      required: false
    },
    locationLabel: {
      type: String,
      required: false
    },
    storageLabel: {
      type: String,
      required: false
    },
    autocompleteProps: {
      type: Object,
      required: false,
      default: () => ({
        backgroundColor: "#EAEAEA80",
        color: "secondary",
        flat: true,
        itemColor: "secondary",
        small: true,
        smallChips: true,
        solo: true,
        clearable: true,
        multiple: true,
      })
    }
  },
  data: () => ({
    loadings: {
      origin: false,
      destination: false,
    },
    checkboxState: {
      sLocations: false,
      sStorageDevices: false,
    },
    locations: [],
    storageDevices: [],
    locationData: [],
    storageData: [],
    pagination: {
      locations: {
        currentPage: 1,
        itemsPerPage: 10,
      },
      storages: {
        currentPage: 1,
        itemsPerPage: 10,
      }
    },
    locationsSearch: '',
    storagesSearch: '',
    valid: true,
    clearEvent: null,
    setEvent: null,
  }),
  async created() {
    await this.fetchLocations()

    this.$set(this, 'locationData', this.sLocations);
    this.$set(this, 'locations', this.locationsSelected);

    await this.fetchStorages(null, this.locations)

    this.$set(this, 'storageData', this.sStorageDevices);
    this.$set(this, 'storageDevices', this.storagesSelected);
  },
  watch: {
    locations(newValue, oldValue) {
      if (oldValue.length > newValue.length) {
        this.cleanupStorages();
      }
    },
    valid(newValue, oldValue) {
      this.$emit('on:valid-form', newValue);
    }
  },
  computed: {
    ...mapGetters({
      type: "ui/GET_FILTER_TYPE",
      sLocations: "locations/sLocations",
      sStorageDevices: "storage/sStorageDevices",
      arraySelectionRules: "general/arraySelectionRules"
    }),
    filtersObjects: {
      get() {
        return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
      },
    },
    source: {
      get() {
        return this.$store.getters['ui/GET_FILTER_SOURCE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTER_SOURCE', val)
      },
    },
    displayedLocations() {
      const start = (this.pagination.locations.currentPage - 1) * this.pagination.locations.itemsPerPage;
      const end = start + this.pagination.locations.itemsPerPage;
      return this.filteredLocations.slice(start, end);
    },
    displayedStorages() {
      const start = (this.pagination.storages.currentPage - 1) * this.pagination.storages.itemsPerPage;
      const end = start + this.pagination.storages.itemsPerPage;
      return this.filteredStorages.slice(start, end);
    },
    filteredLocations() {
      if (!this.locationsSearch) {
        return this.locationData;
      }
      return this.locationData.filter(location =>
        location.name.toLowerCase().includes(this.locationsSearch.toLowerCase())
      );
    },
    filteredStorages() {
      if (!this.storagesSearch) {
        return this.storageData;
      }
      return this.storageData.filter(storage =>
        storage.control_label.toLowerCase().includes(this.storagesSearch.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      fetchSLocations: "locations/fetchSLocations",
      fetchSStorageDevices: "storage/fetchSStorageDevices",
    }),
    updateSearchInput(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    updatePagination(property, { page, itemsPerPage }) {
      this.pagination[property].currentPage = page;
      if (itemsPerPage) {
        this.pagination[property].itemsPerPage = itemsPerPage;
      }
    },
    handleLocationsSearchChange(searchTerm) {
      this.locationsSearch = searchTerm;
      this.updatePagination('locations', { page: 1, itemsPerPage: this.pagination.locations.itemsPerPage });
    },

    handleStoragesSearchChange(searchTerm) {
      this.storagesSearch = searchTerm;
      this.updatePagination('storages', { page: 1, itemsPerPage: this.pagination.storages.itemsPerPage });
    },
    async toggleSelection(autocompleteName, target) {
      console.log(autocompleteName)
      console.log(target)
      let allItems = [];
      let selectedItems = [];

      switch (autocompleteName) {
        case 'locations':
          allItems = this.locationData;
          selectedItems = this.locations;
          break;
        case 'storages':
          allItems = this.storageData;
          selectedItems = this.storageDevices;
          break;
        default:
          return;
      }

      if (selectedItems.length < allItems.length) {
        const newSelection = allItems.map(item => item.control_label || item.name || item.id);
        if (autocompleteName === 'locations') {
          this.$set(this, 'locations', this.locationData.filter(item => newSelection.includes(item.name)));
          this.$emit(`update:locations:${target}`, _.map(this.locations, l => l.locationId));
          await this.fetchStorages(null, this.locations)
        } else if (autocompleteName === 'storages') {
          this.$set(this, 'storageDevices', this.storageData.filter(item => newSelection.includes(item.control_label)));
          this.$emit(`update:storages:${target}`, _.map(this.storageDevices, s => s.storageDeviceId));
        }
      } else {
        if (autocompleteName === 'locations') {
          this.locations = [];
          this.$emit(`update:locations:${target}`, [])
          this.$emit(`update:storages:${target}`, [])
        } else if (autocompleteName === 'storages') {
          this.storageDevices = [];
          this.$emit(`update:storages:${target}`, [])
        }
      }
    },
    computeSelectionIcon(autocompleteName) {
      let allItems = [];
      let selectedItems = [];

      switch (autocompleteName) {
        case 'locations':
          allItems = this.locationData;
          selectedItems = this.locations;
          break;
        case 'storages':
          allItems = this.storageData;
          selectedItems = this.storageDevices;
          break;
        default:
          return 'mdi-checkbox-blank-outline';
      }

      if (selectedItems.length === 0) {
        return 'mdi-checkbox-blank-outline';
      } else if (selectedItems.length === allItems.length) {
        return 'mdi-checkbox-marked';
      } else {
        return 'mdi-minus-box';
      }
    },
    orderData(list, order) {
      return sortList.orderListByUppercase(list, order)
    },
    getByProperty(array, property) {
      return _.map(array, property)
    },
    async fetchLocations(e) {
      let byIds = false

      if (!e) {
        byIds = true
      }

      this.$set(this.loadings, "origin", true)

      const filters = {
        stringFilter: e || "",
        ids: byIds ? this.locations : null,
        isActive: true,
      }

      await this.fetchSLocations([filters, this.$toast]).finally(() => {
        this.$set(this, 'locationData', this.sLocations);
        this.$set(this.loadings, "origin", false)
      })
    },
    async fetchStorages(e, pl, target) {
      if (pl.length <= 1000) {
        this.$set(this.loadings, "destination", true);

        if (this.showDestination)
          this.$emit(`update:storages:${target}`, []);

        // Convertimos pl en una lista de IDs si son objetos
        if (pl && pl.length > 0 && typeof pl[0] === "object") {
          pl = pl.map(location => location.locationId);
        }

        await this.fetchSStorageDevices([
          {
            primaryLocationIds: pl,
            stringFilter: e || "",
            isActive: true,
          },
          this.$toast,
        ]).finally(() => {
          this.pagination.storages.currentPage = 1;
          this.$set(this, 'storageData', this.sStorageDevices);
          this.$set(this.loadings, "destination", false);
          this.$emit(`update:locations:${target}`, this.locations);

          if (this.showDestination)
            this.$emit(`update:storages:${target}`, this.storageDevices);
        });
      }
    },
    cleanupStorages() {
      const selectedPrimaryIds = this.locations.map(loc => 
        typeof loc === 'object' ? loc.locationId : loc
      );
      
      // Keep only storage devices that belong to the remaining selected locations
      this.storageDevices = this.storageDevices.filter(device => {
        const storageDevice = this.storageData.find(s => 
          (typeof device === 'object' ? device.storageDeviceId : device) === s.storageDeviceId
        );
        return storageDevice && selectedPrimaryIds.includes(storageDevice.primaryLocationId);
      });

      // Emit the updated storage devices
      if (this.showDestination) {
        const target = this.type === 'origin' ? 'origin' : 'destination';
        this.$emit(`update:storages:${target}`, this.storageDevices);
      }
    },
    clearLocations(tag, target) {
      console.log(tag)
      switch (tag) {
        case "origin":
          this.filtersObjects.sdi_origin_ids = []
          this.checkboxState.sLocations = false
          this.$set(this, 'locations', [])

          this.checkboxState.sStorageDevices = false
          this.$set(this, 'storageData', [])
          this.$set(this, 'storageDevices', [])

          if (this.showDestination)
            this.$emit(`update:storages:${target}`, this.locations);

          return
        case "destination":
          this.filtersObjects.sdi_destination_ids = []
          this.checkboxState.sStorageDevices = false
          this.$set(this, 'storageDevices', [])

          if (this.showDestination)
            this.$emit(`update:storages:${target}`, []);
          return
      }
    },
  },
}