<template>
    <fragment>
        <template>
            <div class="text-left">
                <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="150"
                        offset-x
                        max-height="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mt-2 ml-3"
                               dark
                               v-bind="attrs"
                               v-on="on"
                               fab
                               x-small
                               elevation="0"
                               tile
                               :color="cantFilters>0 ?'secondary':'white'"
                        >
                            <v-icon :color="cantFilters>0 ?'white':'black'">mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-card style="max-height: 400px;">
                      <v-card-title class="py-2 font-weight-regular secondary t-bw-secondary--text">
                        {{ capitalizeFirstLetter($t('common.buttons.filter'))}}
                      </v-card-title>
                      <v-card-text style="max-height: 200px;  overflow-y: auto;">
                        <v-list
                          dense
                        >
                          <v-list-item dense @click="filterAllMap(!areAllChecked)">
                            <v-list-item-action>
                              <v-icon :color="areAllChecked? 'primary': '' ">{{ areAllChecked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="font-weight-bold">
                              {{ areAllChecked ? $t('common.titles.deselectAll') : $t('common.titles.selectAll') }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item dense  v-for="(stage, index) in localStages" :key="index">
                            <v-list-item-action @click="filterMap()">
                              <v-checkbox v-model="stage.checked"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{stage.storage_type_name}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click='menu=false'>
                          {{ $t('common.buttons.close') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                </v-menu>
            </div>
        </template>
    </fragment>
</template>
<script>
    import _ from 'lodash';

    export default {
        name: "MapFilter",
        inject: ["mapbox", "map", "actions"],
        props: [
            'stages'
        ],
        data() {
            return {
                menu: false,
                localStages: []
            };
        },
      mounted() {
          this.localStages = _.map(this.stages, stage => ({
            ...stage,
            checked: true
          }))
        this.$emit("filterMap", this.localStages);
      },
      computed: {
        areAllChecked() {
          return this.localStages ? (_.filter(this.localStages, stage => {
            if (stage.checked === true) {
              return stage
            }
          }).length > 0) : false
        }
      },

        methods: {
          capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
          },
            cantFilters() {
                return this.localStages ? _.filter(this.localStages, stage => {
                    if (stage.checked === true) {
                        return stage
                    }
                }).length : 0;
            },
            filterAllMap(value) {
                _.forEach(this.localStages, (stage) => {
                    stage.checked = value;
                });
                this.$emit("filterMap", this.localStages);
            },
            filterMap() {
                this.$emit("filterMap", this.localStages);
            }
        }
    }
</script>