export const REGISTER_WEB3_INSTANCE = 'REGISTER_WEB3_INSTANCE'
export const REVERT_WEB3_INSTANCE = 'REVERT_WEB3_INSTANCE'
export const WALLET_DATA = 'WALLET_DATA'
export const WALLETS_DATA = 'WALLETS_DATA'
export const IS_SYNC = 'IS_SYNC'
export const SYNC_INFO = 'SYNC_INFO'
export const TOKEN_LIST = 'TOKEN_LIST'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const DEFAULT_PRODUCTS = 'DEFAULT_PRODUCTS'
export const CONTRACTS_LIST = 'CONTRACTS_LIST'
export const CONTRACTS_JSON_LIST= 'CONTRACTS_JSON_LIST'
export const FACTORY_OWNER = 'FACTORY_OWNER'
export const DEFAULT_TOKEN_LIST = 'DEFAULT_TOKEN_LIST'
export const DEFAULT_WALLET_DATA = 'DEFAULT_WALLET_DATA'
export const DEFAULT_CONTRACTS_LIST = 'DEFAULT_CONTRACTS_LIST'
export const ALL_PRODUCTS = 'ALL_PRODUCTS'
export const FACTORY_DATA = 'FACTORY_DATA'
export const TOKEN_DATA = 'TOKEN_DATA'
export const TOKEN_DETAILS = 'TOKEN_DETAILS'
export const WALLET_TRACKING_DETAILS = 'WALLET_TRACKING_DETAILS'
export const WALLET_TRACKING_TOTAL = 'WALLET_TRACKING_TOTAL'
export const DEFAULT_WALLET_TRACKING = 'DEFAULT_WALLET_TRACKING'
export const SET_LOTS = 'SET_LOTS'
export const DEFAULT_LOTS = 'DEFAULT_LOTS'
export const DEFAULT_TOKEN_DATA = 'DEFAULT_TOKEN_DATA'
export const SET_LOADING_SETTLE = 'SET_LOADING_SETTLE'
export const SET_LOADING_SEND = 'SET_LOADING_SEND'
export const SET_LOADING_START = 'SET_LOADING_START'
export const SET_LOADING_STOP = 'SET_LOADING_STOP'
export const SET_LOADING_EMIT = 'SET_LOADING_EMIT'
