<template>
  <v-col cols="12" v-if="showOrigin || showDestination" :class="{ 'ma-0 pa-0' : twoColumns }" class="pa-0">
    <v-form ref="lOriginForm" v-model="valid" :class="{ 'd-flex justify-space-between': twoColumns }">
      <v-autocomplete
        :ref="locationRef"
        multiple
        color="primary"
        item-color="primary"
        :items="displayedLocations"
        :no-data-text="$t('common.fields.noDataAvailable')"
        v-bind="autocompleteProps"
        v-model="locations"
        item-text="name"
        item-value="locationId"
        @keydown.enter="updateSearchInput($event)"
        @update:search-input="handleLocationsSearchChange($event)"
        @change="fetchStorages(null, locations, 'origin')"
        @click:clear="clearLocations('origin', 'origin')"
        :loading="loadings.origin"
        :rules="arraySelectionRules({ min: 0, max: 1000, required: false })"
        :class="{ 'mr-1' : twoColumns }"
      >
        <template v-slot:label>
          {{ locationLabel }}
        </template>
        <template v-slot:prepend-item>
          <v-alert border="left" tile icon="mdi-information-slab-circle-outline" class="sticky-alert ma-0" text type="info" v-if="locations.length > 0">
            {{ locations.length }} {{ $t('authority.titles.selected') }}
          </v-alert>
          <v-list-item @click="() => toggleSelection('locations', 'origin')">
            <v-list-item-action>
              <v-icon color="primary">{{ computeSelectionIcon('locations') }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              {{ locations.length === locationData.length ? $t('common.titles.deselectAll') : $t('common.titles.selectAll') }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 180px">
            <v-checkbox
              :input-value="attrs.inputValue"
              color="primary"
              class="pt-2 pb-2 mt-0"
              :hide-details="true"
            >
              <template v-slot:label>
                <read-more
                  :classes="'mb-0'"
                  :text="item.name"
                  :max-chars="40"
                  :font-size="14"
                />
              </template>
            </v-checkbox>
          </div>
        </template>
        <template v-slot:append-item>
          <AFooterPaginatorField
            :items-length="filteredLocations.length"
            :items-per-page="pagination.locations.itemsPerPage"
            :itemPerPageText="false"
            :current-page="pagination.locations.currentPage"
            :valid="true"
            @update:current-page="(e) => updatePagination('locations', { page: e.page, itemsPerPage: e.itemsPerPage })"
            @update:items-per-page="(e) => updatePagination('locations', { page: e.page, itemsPerPage: e.itemsPerPage })"
          />
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(5) }}</span>
          </v-chip>
          <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
          >(+{{ locations.length - 1 }}
                          {{ $t('common.titles.others') }})</span
          >
        </template>
      </v-autocomplete>

      <v-autocomplete
        v-if="showDestination"
        :ref="storageRef"
        color="primary"
        item-color="primary"
        multiple
        :items="displayedStorages"
        :no-data-text="$t('common.fields.noDataAvailable')"
        v-bind="autocompleteProps"
        v-model="storageDevices"
        item-text="control_label"
        item-value="storageDeviceId"
        @keydown.enter="updateSearchInput($event)"
        @update:search-input="handleStoragesSearchChange($event)"
        @change="$emit('update:storages:origin', storageDevices)"
        @click:clear="clearLocations('destination', 'origin')"
        :disabled="!(locations.length > 0)"
        :loading="loadings.destination"
        :rules="arraySelectionRules({ min: 0, max: 1000, required: false })"
        :class="{ 'ml-1' : twoColumns }"
      >
        <template v-slot:label>
          {{ storageLabel }}
        </template>
        <template v-slot:prepend-item>
          <v-alert border="left" tile icon="mdi-information-slab-circle-outline" class="sticky-alert ma-0" text type="info" v-if="storageDevices.length > 0">
            {{ storageDevices.length }} {{ $t('authority.titles.selected') }}
          </v-alert>
          <v-list-item @click="() => toggleSelection('storages', 'origin')">
            <v-list-item-action>
              <v-icon color="primary">{{ computeSelectionIcon('storages') }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              {{ storageDevices.length === storageData.length ? $t('common.titles.deselectAll') : $t('common.titles.selectAll') }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 180px">
            <v-checkbox
              :input-value="attrs.inputValue"
              color="primary"
              class="pt-2 pb-2 mt-0"
              :hide-details="true"
            >
              <template v-slot:label>
                <read-more
                  :classes="'mb-0'"
                  :text="item.control_label"
                  :max-chars="40"
                  :font-size="14"
                />
              </template>
            </v-checkbox>
          </div>
        </template>
        <template v-slot:append-item>
          <AFooterPaginatorField
            :items-length="filteredStorages.length"
            :items-per-page="pagination.storages.itemsPerPage"
            :itemPerPageText="false"
            :current-page="pagination.storages.currentPage"
            :valid="true"
            @update:current-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
            @update:items-per-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
          />
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.control_label | truncate(5) }}</span>
          </v-chip>
          <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
          >(+{{ storageDevices.length - 1 }}
                          {{ $t('common.titles.others') }})</span
          >
        </template>
      </v-autocomplete>
    </v-form>
  </v-col>
</template>

<script>
  import ReadMore from "@/components/ReadMoreComponent.vue"
  import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";
  import {CommonLocationFilterMixin} from "@/components/filters/mixins/common.mixin";

  export default {
    components: { AFooterPaginatorField, ReadMore },
    mixins: [CommonLocationFilterMixin],

    async mounted() {
      this.$root.$on(`filter:fetch-locations`, async () => {
        console.log('event:', `filter:fetch-locations`)
        await this.fetchLocations();
      });

      await this.fetchLocations();
      this.$set(this, 'locationData', this.sortSelectedItems(this.sLocations, this.locations));
      this.$set(this, 'storageData', this.sortSelectedItems(this.sStorageDevices, this.storageDevices));

      if (this.$route.name === 'locations') {
        const filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'));
        if (filtersLocations) {
          this.$set(this, "locations", filtersLocations.filtersObjects.locations.ids);
        }
      }

      if (this.$route.name === 'storage') {
        const filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'));
        if (filtersStorages) {
          this.$set(this, "locations", filtersStorages.filtersObjects.storages.primaryLocationIds);
          setTimeout(() => {
            this.$set(this, "storageDevices", filtersStorages.filtersObjects.storages.ids);
          }, 300);
        }
      }

      this.clearEvent = this.source ? `clear:locations:filters-${this.source}` : 'clear:locations:filters';
      this.$root.$on(this.clearEvent, async (payload) => {
        console.log('event:', this.clearEvent);
        this.$set(this, "locations", payload.locations);
        this.$set(this, "storageDevices", payload.storageDevice);
      });

      this.setEvent = this.source ? `set:locations:filters-${this.source}` : 'set:locations:filters';
      this.$root.$on(this.setEvent , async (payload) => {
        console.log('event:', this.setEvent);
        this.$set(this, "locations", payload.locations);
        await this.fetchStorages(null, payload.locations, 'origin');

        if (payload.storageDevice)
          this.$set(this, "storageDevices", payload.storageDevice);

        if (this.sLocations.length > 0)
          this.$set(this, 'locationData', this.sortSelectedItems(this.sLocations, this.locations));

        if (this.sStorageDevices.length > 0)
          this.$set(this, 'storageData', this.sortSelectedItems(this.sStorageDevices, this.storageDevices));
      });
    },

    methods: {
      sortSelectedItems(items, selectedIds) {
        const selectedItems = items.filter(item => selectedIds.includes(item.locationId));
        const nonSelectedItems = items.filter(item => !selectedIds.includes(item.locationId));
        return [...selectedItems, ...nonSelectedItems];
      }
    },

    destroyed() {
      // Force reset pagination
      this.pagination.locations.currentPage=1;
      this.pagination.storages.currentPage=1;

      this.$root.$off(`filter:fetch-locations`)
      this.$root.$off(this.clearEvent)
      this.$root.$off(this.setEvent)

      console.log('destroyed locations filters')
    }
  }
</script>

<style scoped>
.v-list {
  padding-top: 0 !important;
}

.sticky-alert {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white !important;
}
</style>
