<template>
  <v-list-item :key="index" class="notification-item">
    <v-list-item-avatar v-if="pIcon" class="notification-avatar">
      <v-icon :color="pColor">
        {{ pIcon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-icon class="fixed-width">
      <v-chip small color="primary" class="notification-chip white--text">
        {{ notification.module }}
      </v-chip>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="d-block">
        <v-chip small class="notification-chip my-1 grey lighten-3 grey--text text--darken-2">
          {{ notification.data.query.type.documentType }} | {{ getDatasetName(notification) }}
          <v-tooltip bottom content-class="tooltip-styles">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" class="ml-2" small>mdi-information-outline</v-icon>
            </template>
            <span>{{ notification.data.query.type.document }}</span>
          </v-tooltip>
        </v-chip>
        <p class="notification-detail my-2">
          {{ $t('storage.titles.location') }} <br> 
          <strong>{{ notification.data.storageDevice.control_label }}</strong>
        </p>
        <p class="notification-date">{{ getDateFormat(notification.dateTime) }}</p>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="withAction">
      <v-btn v-if="!notification.watched" 
             fab x-small 
             class="action-button" 
             elevation="0" 
             @click="$emit('mark:read')" 
             :loading='loading'>
        <template v-slot:loader>
          <v-progress-circular indeterminate size="12" width="2" />
        </template>
        <v-icon small>
          mdi-check
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
  name: 'BrainAgNotification',

  props: {
    index: {
      type: Number,
      require: true
    },
    notification: {
      type: Object,
      require: true
    },
    loading: {
      type: Boolean,
      require: false,
      default: false
    },
    pColor: {
      type: String,
      require: false
    },
    pIcon: {
      type: String,
      require: false
    },
    withAction: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  data: () => ({}),

  computed: {
    ...mapGetters({
      documentType: "storage/documentTypes",
      date: "ui/GET_DATE_FILTERS"
    })
  },

  methods: {
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format(this.date.dateFormat)
    },
    getDatasetName(notification) {
      switch (notification.data.query.type.documentType) {
        case 'CPF':
        case 'CNPJ':
          return _.find(this.documentType.datasets.document, { value: notification.data.query.dataset }).name
        case 'CAR':
          return _.find(this.documentType.datasets.car, { value: notification.data.query.dataset }).name
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/notification-styles.scss";
</style>