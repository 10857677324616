import { render, staticRenderFns } from "./LocationOriginFilters.vue?vue&type=template&id=96bb680c&scoped=true"
import script from "./LocationOriginFilters.vue?vue&type=script&lang=js"
export * from "./LocationOriginFilters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96bb680c",
  null
  
)

export default component.exports