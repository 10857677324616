import i18n from '@/plugins/i18n'

export default {
    totalsByLocations: [],
    totalsByProducts: [],
    generalTotals: [],
    loading: false,
    loadingSellers: false,
    loadingContracts: false,
    // charts
    datasets: [],
    chart: null,
    itemInSheet: {},

    // sheet
    sheetContract: false,
    sheetSeller: false,

    // sellers
    sellers: [],
    sellersNames: [],

    // contracts
    contracts: [],
    contractsNames: [],
    totalContracts: 0,

    // volumes & quebra ranges
    quebra: null,
    volumesN: null,
    volumesB: null,

    // General Reports
    records: [],
    totalRecordsFromServer: null,

    inspections: [],
    totalInspectionsFromServer: null,

    documents: [],
    totalDocumentsFromServer: null,

    // Biorganic Reports
    biorganic: [],
    totalBiorganicFromServer: null,

    // Traceability Reports
    qrList: [],
    totalQRFromServer: null,

    // Traceability Reports
    traceability: [],
    totalRTFromServer: null,

    // Traceability Reports v2
    traceabilityV2: [],
    recordsByIds: [],
    totalTraceabilityV2FromServer: null,

    request: false,
    source: null,

    reCalculateInfo: {
        lastUpdate: null,
        changesPendants: 0
    },

    // Headers
    defaultHeadersTraceability: [
        {text: "", value: "statusLine", sortable: false,},
        {text: "records.fields.status", value: "status", val: "status", type: 'text'},
        {text: "records.fields.code", value: "uniqueIdRepresentation", val: "uniqueIdRepresentation", sortable: true,},
        {text: 'records.fields.stage', value: 'sdt_to_show_translate', type: 'text', width: 130, sortable: false},
        {text: 'records.fields.origin', value: 'sdi_origin_name', type: 'text', sortable: false},
        {text: 'records.fields.destination', value: 'sdi_destination_name', type: 'text', sortable: false},
        {text: 'records.fields.date', value: 'record_date_time', type: 'date', width: 150, sortable: false},
        {text: 'records.fields.process', value: 'process_name', type: 'text', sortable: false},
        {text: 'records.fields.person', value: 'person_in_charge_name', type: 'text', width: 170, sortable: false},
        {text: 'common.fields.action', value: 'action', width: 150, sortable: false, type: 'actions', align: 'center'}
    ],
    headersTraceability: []
}
