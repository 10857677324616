import LanguajeService from '../../../../services/LanguajeService';
import sortList from '../../../../services/OrderListService';

import _ from 'lodash';
export default {
    storagesTypesByCompany: state => sortList.orderListByUppercase(_.map(state.storagesTypesByCompany, (item) => {
        return {
            ...item,
            storage_type_name: LanguajeService.getKey3(item.storage_device_type_key),
            checked: true
        };
    }), 'storage_type_name'),
    storageDeviceTypesByCompanyAccess: state => sortList.orderListByUppercase(_.map(state.storageDeviceTypesByCompanyAccess, (item) => {
        return {
            ...item,
            storage_type_name: LanguajeService.getKey3(item.storage_device_type_key),
            checked: true
        };
    }), 'storage_type_name'),
    getStorageTypeById: state => (id) => _.find(state.storagesTypesByCompany, {storage_device_type_id: id}),
    storageDeviceTypesActiveByCompany: state => sortList.orderListByUppercase(_.map(state.storageDeviceTypesActiveByCompany, (item) => {
        return {
            ...item,
            storage_type_name: item.storage_device_type_key ? LanguajeService.getKey3(item.storage_device_type_key) : item.storage_device_type_key
        };
    }),'storage_type_name'),

    allStoragesTypes: state => _.map(state.allStoragesTypes, (item) => {
        return {
            ...item,
            storage_type_name: LanguajeService.getKey3(item.language_key)
        };
    }),
    loading: state => state.loading
}
