import { default as rules } from '../middlewares/check-rules.js';

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'init',
    beforeEnter: rules.redirectBasedOnIntegration,
    meta: {domain: 'Dashboard'},
  },
  {
    path: '/integrations/:company_owner',
    name: 'integrations',
    component: () => import(`@integrations/common/IntegrationWrapperView.vue`),
    beforeEnter: rules.navigate,
    meta: { domain: 'All' },
  },
  {
    path: '/configuration/translations',
    name: 'translations',
    component: () => import('@/module/configuration/translations/IndexView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Translations'}
  },
  {
    path: '/product_traceability/:label_code',
    name: 'final',
    component: () => import('@/module/final/Map.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'},
  },
  {
    path: '/label_traceability/:label_code',
    name: 'label_trace',
    component: () => import('@/module/labels/components/QrLabel.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'},
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/module/auth/Login.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/agtracetracking/:id',
    name: 'agtracetracking',
    component: () => import('@/module/tracking/TrackingQR.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@dashboard/views/DashboardView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Dashboard'}
  },
  {
    path: '/prerecords',
    name: 'prerecords',
    component: () => import('@/module/prerecords/RecordsTabsPreComponent.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'PreRecord'}
  },
  {
    path: '/prerecordsAdd',
    name: 'prerecordsAdd',
    component: () => import('@/module/prerecords/AddOrEditPreRecords.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'PreRecord'}
  },
  {
    path: '/records',
    name: 'records',
    component: () => import('@/module/records/RecordsTabsComponent.vue'),
    props: true,
    beforeEnter: rules.navigate,
    meta: {domain: 'Record'}
  },
  {
    path: '/tokens/wallet',
    name: 'token-wallet',
    component: () => import('@/module/tokens/wallet/IndexView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'TokenWallet'}
  },
  {
    path: '/tokens/stock',
    name: 'token-stock',
    component: () => import('@/module/tokens/stock/IndexView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'TokenStock'}
  },
  {
    path: '/tokens/contracts',
    name: 'token-contracts',
    component: () => import('@/module/tokens/contract/IndexView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'TokenContract'}
  },
  {
    path: '/tokens/faq',
    name: 'token-faq',
    component: () => import('@/module/tokens/faq/IndexView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'TokenWallet'}
  },
  {
    path: '/recordsAdd',
    name: 'recordsAdd',
    component: () => import('@/module/records/AddOrEditRecords.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Record'}
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/module/documents/Documents.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Document'}
  },
  {
    path: '/inspections',
    name: 'inspections',
    component: () => import('@/module/inspections/Inspections.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Survey'}
  },
  {
    path: '/inspections/answer',
    name: 'inspectionsAnswer',
    component: () => import('@/module/inspections/Answer.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Survey'}
  },
  {
    path: '/inspections/details',
    name: 'inspectionsDetails',
    component: () => import('@/module/inspections/Details.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Survey'}
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/module/configuration/company/Company.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Company'},
  },
  {
    path: '/companies/configuration',
    name: 'company-configuration',
    component: () => import('@/module/configuration/company/Configuration.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Company'}
  },
  // Nomenclature
  {
    path: '/nomenclators/inputs',
    name: 'inputs',
    component: () => import('@/module/configuration/input/Input.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Input'}
  },
  {
    path: '/nomenclators/products',
    name: 'products',
    component: () => import('@/module/configuration/product/Product.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Culture'}
  },
  {
    path: '/nomenclators/survey',
    name: 'survey',
    component: () => import('@/module/configuration/survey/Survey.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'surveyAdm'},
  },
  {
    path: '/nomenclators/survey/surveyAdd',
    name: 'surveyAdd',
    meta: {domain: 'surveyAdm'},
    beforeEnter: rules.navigate,
    component: () => import('@/module/configuration/survey/AddOrEditSurvey.vue'),
  },
  {
    path: '/nomenclators/survey/questionAdd',
    name: 'questionAdd',
    meta: {domain: 'surveyAdm'},
    beforeEnter: rules.navigate,
    component: () => import('@/module/configuration/survey/AddOrEditQuestion.vue'),
  },

  {
    path: '/nomenclators/equipments',
    name: 'equipments',
    component: () => import('@/module/configuration/equipment/Equipment.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Equipment'}
  },
  {
    path: '/nomenclators/operators',
    name: 'operators',
    component: () => import('@/module/configuration/operator/Operator.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Operator'}
  },
  {
    path: '/nomenclators/producers',
    name: 'producers',
    component: () => import('@/module/configuration/producer/Producer.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Producer'}
  },
  {
    path: '/nomenclators/seasons',
    name: 'seasons',
    component: () => import('@/module/configuration/season/Season.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Season'}
  },
  {
    path: '/nomenclators/units',
    name: 'units',
    component: () => import('@/module/configuration/units/Units.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/nomenclators/group-units',
    name: 'group-units',
    component: () => import('@/module/configuration/group-units/GroupUnits.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/nomenclators/storage-types',
    name: 'storage-types',
    component: () => import('@/module/configuration/storageTypes/StorageTypes.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'StorageDeviceType'}
  },
  {
    path: '/nomenclators/locations',
    name: 'locations',
    component: () => import('@/module/configuration/location/Location.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Location'}
  },
  {
    path: '/nomenclators/storage',
    name: 'storage',
    component: () => import('@/module/configuration/storage/Storage.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'StorageDevice'}
  },
  {
    path: '/nomenclators/storage/management',
    name: 'storage-management',
    component: () => import('@/module/configuration/storage/components/tabs/TabsComponent.vue'),
    beforeEnter: rules.navigate,
    props: true,
    meta: {domain: 'StorageDevice'},
    children: [{
      path: 'folder/:name',
      name: 'document-type-detail',
      component: () => import('@/module/configuration/storage/components/tabs/items/DocumentView/DocumentComponent.vue'),
      beforeEnter: rules.navigate,
      meta: {domain: 'StorageDevice'},
      props: true,
    }]
  },
  {
    path: '/nomenclators/location/management',
    name: 'location-management',
    component: () => import('@/module/configuration/location/components/tabs/TabsComponent.vue'),
    beforeEnter: rules.navigate,
    props: true,
    meta: {domain: 'Location'}
  },
  {
    path: '/nomenclators/document-description',
    name: 'document-description',
    component: () => import('@/module/configuration/documentDescription/DocumentDescription.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/nomenclators/document-types',
    name: 'document-types',
    component: () => import('@/module/configuration/documentTypes/DocumentTypes.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/nomenclators/document-authority',
    name: 'document-authority',
    component: () => import('@/module/configuration/documentAuthority/DocumentAuthority.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/nomenclators/authority',
    name: 'authority',
    component: () => import('@/module/configuration/authority/Authority.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'PersonInCharge'}
  },
  {
    path: '/nomenclators/roles',
    name: 'roles',
    component: () => import('@/module/configuration/roles/Roles.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Role', module: "roles"}
  },
  {
    path: '/nomenclators/proccess',
    name: 'proccess',
    component: () => import('@/module/configuration/process/Process.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Process'}
  },
  {
    path: '/processAdd',
    name: 'processAdd',
    component: () => import('@/module/configuration/process/components/tabs/TabsComponent.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Process'}
  },
  {
    path: '/nomenclators/details',
    name: 'details',
    component: () => import('@/module/configuration/structures/Structure.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Structure'}
  },
  {
    path: '/system/notifications',
    name: 'notifications',
    component: () => import('@/module/configuration/notifications/IndexView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/configRecordLabel',
    name: 'configRecordLabel',
    component: () => import('@/module/labels/recordLabel/Labels.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Record'}
  },
  {
    path: '/printRecordLabel/:id',
    name: 'printRecordLabel',
    component: () => import('@/module/labels/recordLabel/PrintLabel.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Record'}
  },
  {
    path: '/configReportLabel',
    name: 'configReportLabel',
    props: true,
    component: () => import('@/module/labels/reportLabel/Labels.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  {
    path: '/printReportLabel',
    name: 'printReportLabel',
    props: true,
    component: () => import('@/module/labels/reportLabel/PrintLabel.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  // Admin
  {
    path: '/agtAdmin/storage-types',
    name: 'agtAdminSDT',
    component: () => import('@/module/agt-admin/storageTypes/StorageTypes.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'All'}
  },
  //Reports
  {
    path: '/reports/traceability',
    name: 'traceability-view',
    component: () => import('@/module/reports/traceability/IndexView.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'Traceability'}
  },
  {
    path: '/reports/traceability/create',
    name: 'traceability-report',
    component: () => import('@/module/reports/traceability/ReTraceability.vue'),
    props: true,
    beforeEnter: rules.navigate,
    meta: {domain: 'Traceability'}
  },
  {
    path: '/reports/stock-report',
    name: 'stock-report',
    component: () => import('@/module/reports/ReStock.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'StockReport'}
  },
  {
    path: '/reports/general-report',
    name: 'general-report',
    component: () => import('@/module/reports/ReGeneral.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'GeneralReport'}
  },
  {
    path: '/reports/biorganic-report',
    name: 'biorganic-report',
    component: () => import('@/module/reports/ReBiorganic.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'BiorganicReport'}
  },
  {
    path: '/reports/traceabilityV2-report',
    name: 'traceabilityV2-report',
    component: () => import('@/module/reports/ReTraceabilityV2.vue'),
    beforeEnter: rules.navigate,
    meta: {domain: 'TraceabilityV2Report'}
  }
];

export default routes;
