import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";
import i18n from '@/plugins/i18n';


export default {
    detailsCode: state => _.map(state.detailsCode, (item) => {
        return {
            ...item,
            details_name: LanguajeService.getKey3(item.language_key),

        };
    }),
    details: state => sortList.orderListByUppercase(_.map(state.details, (item) => {
        return {
            ...item,
            details_name: LanguajeService.getKey3(item.language_key),
        };
    }), 'details_name'),
    lots: state => state.lots,
    products: state => sortList.orderListByUppercase(state.products, 'product_name'),
    detailsLocalEnum() {
        return sortList.orderListByUppercase([
            {text: i18n.t('structure.types.number'), value: 'NUMBER'},
            {text: i18n.t('structure.types.text'), value: 'TEXT'},
            {text: i18n.t('structure.types.qr'), value: 'QR'},
            {text: i18n.t('structure.types.list'), value: 'LIST'},
            {text: i18n.t('structure.types.product'), value: 'PRODUCT'},
            {text: i18n.t('structure.types.operator'), value: 'OPERATOR'},
            {text: i18n.t('structure.types.equipment'), value: 'EQUIPMENT'},
            {text: i18n.t('structure.types.season'), value: 'SEASON'},
            {text: i18n.t('structure.types.mu'), value: 'MEASUREMENTUNIT'},
            {text: i18n.t('structure.types.input'), value: 'INPUT'},
            {text: i18n.t('structure.types.lote'), value: 'LOTE'},
            {text: i18n.t('structure.types.datetime'), value: 'DATATIME'},
            {text: i18n.t('structure.types.date'), value: 'DATA'},
            {text: i18n.t('structure.types.time'), value: 'TIME'},
            {text: i18n.t('structure.types.sList'), value: 'LISTSIMPLE'},
            {text: i18n.t('structure.types.sProduct'), value: 'PRODUCTSIMPLE'},
            {text: i18n.t('structure.types.sOperator'), value: 'OPERATORSIMPLE'},
            {text: i18n.t('structure.types.sEquipment'), value: 'EQUIPMENTSIMPLE'},
            {text: i18n.t('structure.types.sSeason'), value: 'SEASONSIMPLE'},
            {text: i18n.t('structure.types.smu'), value: 'MEASUREMENTUNITSIMPLE'},
            {text: i18n.t('structure.types.sInput'), value: 'INPUTSIMPLE'},
        ], 'text')
    },
    loading: state => state.loading,
}
