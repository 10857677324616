<template>
  <div class="d-flex justify-center flex-column">
    <v-select
      v-model="selected"
      :items="items"
      :loading="loading"
      append-icon=""
      :return-object="returnObject"
      :multiple="multiple"
      :hide-no-data="hideNoData"
      :chips="chips"
      :item-text="itemText"
      :item-value="itemValue"
      :no-data-text="$t('common.fields.noDataAvailable')"
      @change="$emit('data:change', selected)"
      @click:clear="clearInput()"
      :menu-props="{ closeOnContentClick: false }"
      v-bind="selectProps"
    >
      <template v-slot:label>
        {{ $t("home.fields.search") }}
        <strong v-if="required" class="red--text">*</strong>
      </template>

      <!-- Append area with search field and criteria select -->
      <template v-slot:prepend-item>
        <v-row class="px-2 py-2">
          <v-col cols="4">
            <v-select
              outlined
              v-model="selectedFilter"
              :items="filterChoices"
              :label="$t('common.filters.criteria')"
              hide-details
              :return-object="false"
              item-value="value"
              item-text="name"
              dense
            ></v-select>
          </v-col>
          <v-col cols="8">
            <v-text-field
              outlined
              v-model="searchQuery"
              :label="$t('home.fields.search')"
              @keyup.enter="emitSearch"
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider />
      </template>

      <template v-slot:selection="{ item, index }">
        <slot name="selection" :item="item" :index="index">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption">
            (+{{ selected.length - 1 }} {{ $t("common.titles.others") }})
          </span>
        </slot>
      </template>
      <template v-slot:item="{ item, attrs }">
        <div style="max-width: 360px">
          <v-checkbox
            v-if="multiple"
            :input-value="attrs.inputValue"
            color="primary"
            class="pt-2 pb-2 mt-0"
            :hide-details="true"
          >
            <template v-slot:label>
              <read-more
                :classes="'mb-0'"
                :text="item.name"
                :max-chars="80"
                :font-size="14"
              />
            </template>
          </v-checkbox>
          <read-more
            v-else
            :classes="'mb-0'"
            :text="item.name"
            :max-chars="80"
            :font-size="14"
          />
        </div>
      </template>

      <template v-slot:append-item>
        <v-divider v-if="totalPages > 1"/>
        <div class="pagination mx-1 my-2" v-if="totalPages > 1">
          <v-spacer />
          <v-btn text small :disabled="isFirstPage" @click="previousPage">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <span style="font-size: 12px" class="mx-2 ma-0 pa-0">{{ firstElementIndex + 1 }} - {{ lastElementIndex }} de {{ totalFiltered }}</span>
          <v-btn text small :disabled="isLastPage" @click="nextPage">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </template>
    </v-select>

    <div class="d-flex align-center justify-start">
      <v-chip
        v-if="filtersObjects.lots.length > 0"
        :key="'lots-summary'"
        class="d-flex align-center mr-2"
        not-apply-styles
      >
        {{ $t('inventory.titles.total') }}: {{ filtersObjects.lots.length }}

        <v-icon
          small
          class="ml-2"
          @click="deleteAll()"
        >
          mdi-close
        </v-icon>
      </v-chip>

      <v-chip-group id="chip-group__lots" v-if="filtersObjects.lots && filtersObjects.lots.length > 0" show-arrows style="width:480px">
        <v-chip
          v-for="(item, index) in filtersObjects.lots"
          :key="item"
          :close="true"
          @click:close="deleteSelected(index)"
        >
          {{ item }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
  import LotsMixin from "@/mixins/lots.mixin";
  import ReadMore from "@/components/ReadMoreComponent.vue"
  import { mapGetters } from "vuex"

  export default {
    name: 'LotFilterComponent',
    components: { ReadMore },
    mixins: [LotsMixin],
    props: {
      showSelected: {
        type: Boolean,
        default: true
      },
      returnObject: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: true
      },
      hideNoData: {
        type: Boolean,
        default: true
      },
      chips: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      selectProps: {
        type: Object,
        default: () => ({
          outlined: false,
          dense: false,
          solo: true,
          backgroundColor: '#EAEAEA80',
          flat: true,
          hideDetails: true
        })
      },
      chipProps: {
        type: Object,
        default: () => ({})
      },
      itemText: {
        type: String,
        default: "name"
      },
      itemValue: {
        type: String,
        default: "id"
      }
    },
    data() {
      return {
        selectedFilter: null,
        filterChoices: [
          { name: this.$t('common.filters.exact'), value: 'exact' },
          { name: this.$t('common.filters.start'), value: 'start' },
          { name: this.$t('common.filters.end'), value: 'end' },
          { name: this.$t('common.filters.contain'), value: 'contain' }
        ],
      };
    },

    mounted() {
      this.$root.$on("clear:selected:lots", () => {
        this.selected = []
        this.searchQuery = ""
        this.selectedFilter = null
      })
    },

    computed: {
      ...mapGetters({
        selectRule: 'general/selectRule',
      }),
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      isFirstPage() {
        return this.pageableDTO.page === 0;
      },
      isLastPage() {
        return this.pageableDTO.page >= this.totalPages - 1;
      },
      firstElementIndex() {
        return this.pageableDTO.page * this.pageableDTO.size;
      },
      lastElementIndex() {
        return Math.min((this.pageableDTO.page + 1) * this.pageableDTO.size, this.totalFiltered);
      }
    },
    methods: {
      emitSearch() {
        if (this.searchQuery.trim()) {
          this.pageableDTO.page = 0;
          this.fetchItems();
        } else {
          console.warn("Search input is empty. No search event emitted.");
        }
      },
      removeFilter() {
        this.selectedFilter = null;
        this.searchQuery = "";
      },
      deleteSelected(index) {
        if (index > -1) {
          this.selected.splice(index, 1);
        }
      },
      deleteAll() {
        this.selected = [];
        this.$emit('data:change', this.selected)
      },
      previousPage() {
        if (!this.isFirstPage) {
          this.pageableDTO.page--;
          this.fetchItems();
        }
      },
      nextPage() {
        if (!this.isLastPage) {
          this.pageableDTO.page++;
          this.fetchItems();
        }
      }
    },

    destroyed() {
      this.$root.$off('clear:selected:lots');
    }
  };
</script>

<style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

 :deep(#chip-group__lots div.v-slide-group__content) {
    display: flex;
    justify-content: flex-start;
   align-content: center;
   align-items: center;
  }
</style>
