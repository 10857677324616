import _ from "lodash";
import i18n from '@/plugins/i18n';
import LanguajeService from "../../../services/LanguajeService";
import moment from "moment";

export default {
    loading: state => state.loading,
    score: state => state.score,
    loadingEvidences: state => state.loadingEvidences,
    uploadProgress: state => state.uploadProgress,
    error: state => state.error,
    cantToExport: state => state.cantToExport,
    blob: state => state.blob,
    surveyStateEnum() {
        return [
            {id: 1, text: i18n.t('survey.status.EDITING'), type: 'EDITING'},
            {id: 2, text: i18n.t('survey.status.READY_TO_FILL'), type: 'READY_TO_FILL'},
            {id: 3, text: i18n.t('survey.status.FILLING'), type: 'FILLING'},
            {id: 4, text: i18n.t('survey.status.READY_TO_REVIEW'), type: 'READY_TO_REVIEW'},
            {id: 5, text: i18n.t('survey.status.REVIEWING'), type: 'REVIEWING'},
            {id: 6, text: i18n.t('survey.status.PUBLISHED'), type: 'PUBLISHED'}
        ]
    },

    question: state => state.question,

    inspectionsList: (state, getters) => {
        return _.map(state.inspectionList, (survey) => {
            const status = _.find(getters.surveyStateEnum, {type: survey.surveyStateType});
            return {
                ...survey,
                questionSectionList: _.map(survey.questionSectionList, (questionSection) => {
                    return {
                        ...questionSection,
                        questions: _.map(questionSection.questions, (question) => {
                            return {
                                ...question,
                                answer_name: LanguajeService.getKey3(question.answer),
                                possibleAnswer: _.map(question.possibleAnswer, (answer) => {
                                    return {
                                        ...answer,
                                        answer_name: LanguajeService.getKey3(answer.answer)
                                    }
                                })
                            }
                        })
                    }
                }),
                location_name: survey.locations && survey.locations.length > 0 ? survey.locations : [],
                surveyStateTypeName: status ? status.text : survey.surveyStateType
            }
        });
    },

    // analizar bien esta estructura porque estaba dando error en versiones anteriores a la hora de obtener el item por eso no lo uso en inspections
    inspection: (state) => {
        state.inspection.questionSectionList = _.map(state.inspection.questionSectionList, (questionSection) => {
            return {
                ...questionSection,
                questions: _.map(questionSection.questions, (question) => {
                    let questionResult ={};
                    const STRUCTURE = ['DATA', 'TIME', 'DATATIME'];
                    let father = null;
                    let questionAnswerConvert = [];
                    let show = false;
                    if (question.extraQuestionFatherRef) {
                        question.extraQuestionFatherRef.possibleAnswer = _.map(question.extraQuestionFatherRef.possibleAnswer, (answerFather) => {
                            return {
                                ...answerFather,
                                answer_name: LanguajeService.getKey3(answerFather.answer)
                            }
                        });
                        _.forEach(state.inspection.questionSectionList, (section) => {
                            if (!father) {
                                father = _.find(section.questions, {"id": question.extraQuestionFatherRef.id});
                            }

                        });
                        if (father){
                            _.forEach(father.answers, (answer) => {
                                let dateFormatted = (LanguajeService.getKey3(answer.answer) + '.000Z').slice(0, 19);
                                let data = null;
                                if(STRUCTURE.includes(father.questionStructureType)){
                                    data = new Date(dateFormatted);
                                    show = true;
                                } else {
                                    data =  LanguajeService.getKey3(answer.answer);
                                }
                                questionAnswerConvert.push(data);
                            })
                        }
                    }
                    let answers_names = [];
                    return {
                        ...question,
                        answers: _.map(question.answers, (answer) => {
                            let dateFormatted = (LanguajeService.getKey3(answer.answer) + '.000Z').slice(0, 19);
                            let data = STRUCTURE.includes(question.questionStructureType) ? new Date(dateFormatted) :
                                LanguajeService.getKey3(answer.answer);
                            answers_names.push(data);

                            return {
                                ...answer,
                                answer_name: data
                            }
                        }),
                        answers_names: answers_names,
                        possibleAnswer: _.map(question.possibleAnswer, (answer) => {
                            return {
                                ...answer,
                                answer_name: LanguajeService.getKey3(answer.answer)
                            }
                        }),
                        show: father? !STRUCTURE.includes(father.questionStructureType)?(questionAnswerConvert && questionAnswerConvert.includes(question.condicionalAnswer)):show: false
                    }
                })
            }
        });
        return state.inspection;
    },
    surveyToExports: state => _.map(state.inspectionToExports, (survey) => {
        return {
            ...survey,
            questionSectionList: _.map(survey.questionSectionList, (questionSection) => {
                return {
                    ...questionSection,
                    questions: _.map(questionSection.questions, (question) => {
                        let answer_names = '';
                        if (question.answers) {
                            if ([
                                'NUMBER',
                                'TEXT',
                                'QR',
                                'LOTE',
                                'LACRE',
                                'TIME',
                                'DATA',
                                'DATATIME',
                                'PRODUCTSIMPLE',
                                'EQUIPMENTSIMPLE',
                                'OPERATORSIMPLE',
                                'INPUTSIMPLE',
                                'MEASUREMENTUNITSIMPLE',
                                'SEASONSIMPLE',
                                'LISTSIMPLE'
                            ].includes(question.questionStructureType)) {
                                if (question.answers[0]) {
                                    let dateFormatted = (LanguajeService.getKey3(question.answers[0].answer) + '.000Z').slice(0, 19);
                                    answer_names = question.questionStructureType === 'DATA' ? moment(dateFormatted).format('DD / MM / YYYY') :
                                        question.questionStructureType === 'TIME' ? moment(dateFormatted).format('HH:mm') :
                                            question.questionStructureType === 'DATATIME' ? moment(dateFormatted).format('DD / MM / YYYY HH:mm') :
                                                LanguajeService.getKey3(question.answers[0].answer)
                                }
                            }
                            if ([
                                'PRODUCT',
                                'EQUIPMENT',
                                'OPERATOR',
                                'INPUT',
                                'MEASUREMENTUNIT',
                                'SEASON',
                                'LIST'
                            ].includes(question.questionStructureType)) {
                                let text = [];
                                _.forEach(question.answers, (answer) => {
                                    text.push(LanguajeService.getKey3(answer.answer))
                                });
                                answer_names = text.join(', ')
                            }
                        }
                        return {
                            ...question,
                            answer_name: answer_names
                        }
                    })
                }
            }),
            location_name: survey.locations && survey.locations.length > 0 ? _.map(survey.locations, (location) => {
                return location.primaryLocation.name + ' | ' + location.control_label;
            }) : [],
            personInCharge_name: survey.personInCharge ? survey.personInCharge.name : "",
        }
    }),
    recordsByInspectionsTotalCount: state => state.recordsByInspectionsTotalCount
}
