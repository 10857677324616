import Web3  from 'web3';

const web3 = new Web3(window.ethereum)
const FACTORY_ADDRESS = process.env.VUE_APP_FACTORY
const BASE_URI_IPFS =
  "ipfs://QmUNLLsPACCz1vLxQVkXqqLX5R1X345qqfHbsf67hvA3Nn/{id}.json"
const GAS_LIMIT = 40000000000

const toUTF8Array = str => {
  var utf8 = []
  for (var i = 0; i < str.length; i++) {
    var charcode = str.charCodeAt(i)
    if (charcode < 0x80) utf8.push(charcode)
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f))
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      )
    }
    // surrogate pair
    else {
      i++
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff))
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      )
    }
  }
  return utf8
}

// aguarda transação ser minerada
const getTransactionReceiptMined = (txHash, maxAttempts = 30, interval = 1000) => {
  let attempts = 0;
  
  const transactionReceiptAsync = (resolve, reject) => {
    attempts++;
    
    if (attempts > maxAttempts) {
      return reject(new Error(`Transaction receipt not found after ${maxAttempts} attempts`));
    }
    
    web3.eth.getTransactionReceipt(txHash, function (error, receipt) {
      if (error) {
        reject(error)
      } else if (receipt == null) {
        setTimeout(() => {
          transactionReceiptAsync(resolve, reject)
        }, interval)
      } else {
        resolve(receipt)
      }
    })
  }

  console.log('>>>>> txHash: ', txHash);

  if (Array.isArray(txHash)) {
    return Promise.all(
      txHash.map(oneTxHash => getTransactionReceiptMined(oneTxHash, maxAttempts, interval))
    )
  } else if (typeof txHash === "string") {
    return new Promise(transactionReceiptAsync)
  } else {
    throw new Error("Invalid Type: " + txHash)
  }
}

// retorna uma instancia do contrato
const loadContract = (_abiInterface, _contractAddress) => {
  return new web3.eth.Contract(_abiInterface, _contractAddress)
}

// retorna nonce do usuario
const getNonce = async _userAddress => {
  return web3.utils.toHex(await web3.eth.getTransactionCount(_userAddress))
}

// retorna gasprice
const getGasPrice = async () => {
  web3.utils.toHex(await web3.eth.getGasPrice())
}

// retorna exa
const getGasLimit = async limit => {
  return web3.utils.toHex(limit)
}

// identificar se a carteira conectada é o Owner da Factory
const checkFactoryOwner = async (_factoryABI, _userAddress) => {
  const contract = loadContract(_factoryABI, FACTORY_ADDRESS)
  const ownerAddress = await contract.methods.owner().call()

  return _userAddress.toLowerCase() === ownerAddress.toLowerCase()
}

// gera a TX dos metodos que criam as transações
const generateAndSendTxObject = async (
  _contractAbi,
  _contractAddress,
  _adminAddress,
  _method,
  _gasLimit,
  ..._paramsOfMethod
) => {
  try {
    // Check if there's a pending transaction for this method
    const pendingTxKey = `tx_${_method}_pending`;
    if (localStorage.getItem(pendingTxKey)) {
      console.log(`A transaction for method ${_method} is already in progress`);
      return { code: -32000, message: "Transaction already in progress" };
    }
    
    // Mark this method as having a pending transaction
    localStorage.setItem(pendingTxKey, 'true');
    
    // Use custom nonce management for Hardhat
    const nonce = await getNonce(_adminAddress);
    console.log(`Using nonce: ${nonce} for address: ${_adminAddress}`);
    
    // Create contract instance
    const contract = loadContract(_contractAbi, _contractAddress);
    
    // Prepare transaction object
    const txObject = {
      to: _contractAddress,
      from: _adminAddress,
      nonce: nonce,
      gasLimit: await getGasLimit(_gasLimit),
      // For Hardhat, use a fixed gas price to avoid issues
      gasPrice: web3.utils.toHex(web3.utils.toWei('50', 'gwei')),
      data: contract.methods[_method](..._paramsOfMethod).encodeABI(),
    };
    
    console.log(`Sending transaction for method: ${_method}`, txObject);
    
    // Send transaction
    const hash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [txObject],
    });
    
    console.log(`Transaction hash: ${hash}`);
    
    // Clear the pending flag after 10 seconds
    setTimeout(() => {
      localStorage.removeItem(pendingTxKey);
    }, 10000);
    
    return hash;
  } catch (error) {
    // Clear the pending flag immediately on error
    localStorage.removeItem(`tx_${_method}_pending`);
    
    console.error(`Error in generateAndSendTxObject for method ${_method}:`, error);
    
    // Detect specific error types
    if (error.code === 4001) {
      console.log('User rejected the transaction');
      return { code: 4001, message: "User rejected transaction" };
    } else if (error.code === -32603) {
      console.log('Internal JSON-RPC error. Possible duplicate transaction.');
      return { code: -32603, message: "Internal RPC error - possible duplicate transaction" };
    } else if (error.message && error.message.includes('Transaction with the same hash')) {
      console.log('Duplicate transaction detected');
      return { code: -32000, message: "Duplicate transaction detected" };
    }
    
    return error;
  }
}

//////////////////////////////////////////
/// comunicação com metodos da factory ///
//////////////////////////////////////////

const createNewToken = async (
  _factoryABI,
  _tokenName,
  _tokenSymbol,
  _userAddress,
  _adminAddress
) => {
  const hash = await generateAndSendTxObject(
    _factoryABI,
    FACTORY_ADDRESS,
    _adminAddress,
    "createNewToken",
    GAS_LIMIT,
    _tokenName,
    _tokenSymbol,
    _userAddress,
    BASE_URI_IPFS
  )

  return getTransactionReceiptMined(hash)
}

// retorna a lista de tokens se a carteira conectada for do owner da factory
// retorna os tokens do usuario caso nao seja o owner da factory
const getTokens = async (_factoryABI, userAddress) => {
  if (await checkFactoryOwner(_factoryABI, userAddress)) {
    return getAllTokens(_factoryABI)
  }
  return getAllTokensFromUser(_factoryABI, userAddress)
}

// listar todos os tokens da factory (Only factory owner)
const getAllTokens = async _factoryABI => {
  const contract = loadContract(_factoryABI, FACTORY_ADDRESS)
  return await contract.methods.getTokens().call()
}

// listar todos os tokens por usuario
const getAllTokensFromUser = async (_factoryABI, userAddress) => {
  const contract = loadContract(_factoryABI, FACTORY_ADDRESS)
  return await contract.methods.getAllTokensFromUser(userAddress).call()
}

const transferOwnership = async (_factoryABI, _userAddress) => {
  const contract = loadContract(_factoryABI, FACTORY_ADDRESS)
  return await contract.methods.transferOwnership(_userAddress).call()
}

///////////////////////////////////////////////
/// comunicação com metodos da do contrato ////
///////////////////////////////////////////////

const getTokenData = async (
  _ERC1155ModelABI,
  _contractAddress,
  _referenceID
) => {
  const contract = loadContract(_ERC1155ModelABI, _contractAddress)
  return await contract.methods.getTokenData(_referenceID).call()
}

const balanceOf = async (
  _ERC1155ModelABI,
  _contractAddress,
  userAddress,
  _reference
) => {
  const contract = loadContract(_ERC1155ModelABI, _contractAddress)
  return await contract.methods.balanceOf(userAddress, _reference).call()
}

//Alterar para funcao do grandRole

const emitTokens = async (
  _ERC1155ModelABI,
  _contractAddress,
  _userAddress,
  _references,
  _amounts,
  _description
) => {
  try {
    console.log("Emitting tokens:", {
      contractAddress: _contractAddress,
      userAddress: _userAddress,
      references: _references,
      amounts: _amounts
    });
    
    const description = toUTF8Array('EMIT');
    
    // Validate input parameters
    if (!Array.isArray(_references) || !Array.isArray(_amounts)) {
      throw new Error("References and amounts must be arrays");
    }
    
    if (_references.length !== _amounts.length) {
      throw new Error("References and amounts arrays must have the same length");
    }
    
    if (_references.length === 0) {
      throw new Error("At least one token must be specified");
    }
    
    // Ensure all references are integers and not empty
    const validReferences = _references.every(ref => Number.isInteger(ref) && ref > 0);
    if (!validReferences) {
      throw new Error("All references must be positive integers");
    }
    
    // Ensure all amounts are integers and positive
    const validAmounts = _amounts.every(amt => Number.isInteger(amt) && amt > 0);
    if (!validAmounts) {
      throw new Error("All amounts must be positive integers");
    }
    
    // Send transaction with a delay to allow UI to update
    await new Promise(resolve => setTimeout(resolve, 500));
    
    const hash = await generateAndSendTxObject(
      _ERC1155ModelABI,
      _contractAddress,
      _userAddress,
      "emitsBatch",
      GAS_LIMIT,
      _references,
      _amounts,
      description
    );
    
    // Check if result is an error object
    if (typeof hash === 'object' && hash.code) {
      throw hash;
    }
    
    return await getTransactionReceiptMined(hash);
  } catch (error) {
    console.error("Error in emitTokens:", error);
    throw error;
  }
}

const settleToken = async (
  _ERC1155ModelABI,
  _contractAddress,
  _userAddress,
  _amount,
  _reference
) => {
  try {
    const hash = await generateAndSendTxObject(
      _ERC1155ModelABI,
      _contractAddress,
      _userAddress,
      "settle",
      GAS_LIMIT,
      _amount,
      _reference
    )
    
    // Check if result is an error
    if (hash.code) {
      throw hash;
    }
    
    return getTransactionReceiptMined(hash)
  } catch (error) {
    console.error("Error in settleToken:", error);
    throw error;
  }
}

const burnToken = async (
  _ERC1155ModelABI,
  _contractAddress,
  _userAddress,
  _references,
  _amounts
) => {
  const hash = await generateAndSendTxObject(
    _ERC1155ModelABI,
    _contractAddress,
    _userAddress,
    "burnBatch",
    GAS_LIMIT,
    _amounts,
    _references
  )
  return getTransactionReceiptMined(hash)
}

const transfer = async (
  _ERC1155ModelABI,
  _contractAddress,
  _userAddress,
  _to,
  _amount,
  _reference,
  _description
) => {
  try {
    const description = toUTF8Array('TRANSFER')
    const hash = await generateAndSendTxObject(
      _ERC1155ModelABI,
      _contractAddress,
      _userAddress,
      "transfer",
      GAS_LIMIT,
      _to,
      _amount,
      _reference,
      description
    )
    
    // Check if result is an error
    if (hash.code) {
      throw hash;
    }
    
    return getTransactionReceiptMined(hash)
  } catch (error) {
    console.error("Error in transfer:", error);
    throw error;
  }
}

const getTotalMinted = async (_ERC1155ModelABI, _contractAddress) => {
  const contract = loadContract(_ERC1155ModelABI, _contractAddress)
  return await contract.methods.getTotalMintedTokens().call()
}

const getTotalSupply = async (_ERC1155ModelABI, _contractAddress) => {
  const contract = loadContract(_ERC1155ModelABI, _contractAddress)
  return await contract.methods.getTotalSupply().call()
}

const getWalletBalance = async (
  _ERC1155ModelABI,
  _contractAddress,
  _userAddress
) => {
  const contract = loadContract(_ERC1155ModelABI, _contractAddress)
  return await contract.methods.getWalletBalance(_userAddress).call()
}

const getStatus = async (_ERC1155ModelABI, _contractAddress) => {
  const contract = loadContract(_ERC1155ModelABI, _contractAddress)
  return await contract.methods.paused().call()
}

// pausar contrato ERC1155Model
const pause = async (_ERC1155ModelABI, _contractAddress, _userAddress) => {
  const hash = await generateAndSendTxObject(
    _ERC1155ModelABI,
    _contractAddress,
    _userAddress,
    "pause",
    GAS_LIMIT
  )
  return getTransactionReceiptMined(hash)
}

// liberar contrato
const unpause = async (_ERC1155ModelABI, _contractAddress, _userAddress) => {
  const hash = await generateAndSendTxObject(
    _ERC1155ModelABI,
    _contractAddress,
    _userAddress,
    "unpause",
    GAS_LIMIT
  )
  return getTransactionReceiptMined(hash)
}

const grantRole = async (
  _ERC1155ModelABI,
  _contractAddress,
  _adminAddress,
  _permission,
  _userAddress
) => {
  const hash = await generateAndSendTxObject(
    _ERC1155ModelABI,
    _contractAddress,
    _adminAddress,
    "grantRole",
    GAS_LIMIT,
    _permission,
    _userAddress
  )
  return getTransactionReceiptMined(hash)
}

const revokeRole = async (
  _ERC1155ModelABI,
  _contractAddress,
  _adminAddress,
  _permission,
  _userAddress
) => {
  const hash = await generateAndSendTxObject(
    _ERC1155ModelABI,
    _contractAddress,
    _adminAddress,
    "revokeRole",
    GAS_LIMIT,
    _permission,
    _userAddress
  )
  return getTransactionReceiptMined(hash)
}

const hasRole = async (
  _ERC1155ModelABI,
  _contractAddress,
  _adminAddress,
  _permission,
  _userAddress
) => {
  const contract = loadContract(_ERC1155ModelABI, _contractAddress)
  return await contract.methods.hasRole(_permission, _userAddress).call()
}

const uuidv4 = async () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export {
  createNewToken,
  getTokens,
  checkFactoryOwner,
  getTokenData,
  balanceOf,
  emitTokens,
  burnToken,
  settleToken,
  transfer,
  grantRole,
  revokeRole,
  hasRole,
  transferOwnership,
  pause,
  unpause,
  getStatus,
  getTotalMinted,
  getTotalSupply,
  getWalletBalance,
  uuidv4
}
