<template>
  <v-list-item :key="index" class="notification-item">
    <v-list-item-avatar v-if="pIcon" class="notification-avatar">
      <v-icon :color="pColor">
        {{ pIcon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-icon class="fixed-width">
      <v-chip small color="primary" class="notification-chip white--text">
        {{ getTranslationModule(notification.module) }}
      </v-chip>
    </v-list-item-icon>
    <v-list-item-content class="d-flex justify-start">
      <p class="notification-content my-1">
        {{ $t('common.notification.exportSuccess') }}
      </p>
      <v-list-item-title class="d-block">
        <p class="notification-date">{{ getDateFormat(notification.dateTime) }}</p>
      </v-list-item-title>
    </v-list-item-content>
    <div v-if="withAction" class="notification-actions">
      <v-btn fab x-small 
             class="action-button" 
             elevation="0" 
             @click="downloadFileFromS3(notification)" 
             :loading='loadings.download || loading'>
        <template v-slot:loader>
          <v-progress-circular indeterminate size="10" width="2" />
        </template>
        <v-icon small>
          mdi-download-multiple
        </v-icon>
      </v-btn>
      <v-btn v-if="!notification.watched" 
             fab x-small 
             class="action-button" 
             elevation="0" 
             @click="$emit('mark:read')" 
             :loading='loadings.read || loading'>
        <template v-slot:loader>
          <v-progress-circular indeterminate size="10" width="2" />
        </template>
        <v-icon small>
          mdi-check
        </v-icon>
      </v-btn>
    </div>
  </v-list-item>
</template>

<script>
import moment from "moment/moment";
import {mapGetters} from "vuex";
import axios from "axios";
import i18n from '@/plugins/i18n';

export default {
  name: 'S3FileNotification',

  props: {
    index: {
      type: Number,
      require: true
    },
    notification: {
      type: Object,
      require: true
    },
    loading: {
      type: Boolean,
      require: false,
      default: false
    },
    loadingSee: {
      type: Boolean,
      require: false,
      default: false
    },
    pColor: {
      type: String,
      require: false
    },
    pIcon: {
      type: String,
      require: false
    },
    withAction: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  data: () => ({
    loadings: {
      read: false,
      download: false
    }
  }),

  computed: {
    ...mapGetters({
      documentType: "storage/documentTypes",
      date: "ui/GET_DATE_FILTERS"
    })
  },

  methods: {
    async downloadFileFromS3(notification) {
      try {
        this.loadings.download = true;
        
        let filename = 'download';
        const s3Url = notification.data?.s3Url;

        // Check if URL exists and is valid
        if (!s3Url) {
          console.error('S3 URL is missing or invalid');
          return;
        }

        switch (notification.data.type) {
          case 'PDF_EXPORT':
            filename = `${this.getTranslationModule(notification.module).toLowerCase()}.pdf`;
            break
          case 'EXCEL_EXPORT':
            filename = `${this.getTranslationModule(notification.module).toLowerCase()}.xlsx`;
            break
        }

        // Ensure URL is properly formatted (add https:// if missing)
        let url = s3Url;
        if (!/^https?:\/\//i.test(url)) {
          url = 'https://' + url.replace(/^\/\//, '');
        }

        const response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob',
          timeout: 30000, // 30 seconds timeout
        });
        
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();

        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);
      } catch (error) {
        console.error('Failed to download file: ', error);
      } finally {
        this.loadings.download = false;
      }
    },
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format(this.date.dateFormat)
    },
    getTranslationModule(module) {
      switch (module) {
        case 'RECORDS':
          return this.$t('records.label').toUpperCase();
        case 'SURVEY':
          return this.$t('survey.label').toUpperCase();
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/notification-styles.scss";
</style>