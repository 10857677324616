import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import VueQrcode from '@xkeshi/vue-qrcode';
import JsonExcel from 'vue-json-excel';
import SVGInjectorVue from 'svginjector-vue';
import { Plugin } from 'vue-fragment';
import DatetimePicker from '@/components/vuetify-datetime-picker';
import HypertIcon from '@/components/icons/HypertIcon.vue';
import store from '@/store';

import { getTextBlackOrWhite, getTextColorAdjusted } from '@customization/utils';

Vue.use(DatetimePicker);

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

Vue.component(VueQrcode.name, VueQrcode);
Vue.component('downloadExcel', JsonExcel);
Vue.use(SVGInjectorVue);
Vue.use(Plugin);

const getThemeColors = () => {
  const customization = store.state.customization;
  const theme = customization ? customization.theme : null;

  const themeColors = theme && theme.colors ? theme.colors : {
    primary: '#03AC8C',
    secondary: '#232323',
    accent: '#82B1FF',
    error: '#e83b36',
    info: '#2196F3',
    success: '#289F89',
    warning: '#E8BD30',
    neuter: '#232323',
    white: '#FFFFFF',
    gray: '#6c757d',
    metal: '#ebebeb',
    validating: '#ffc107',
    validated: '#28a745',
    pending: '#2f2f2f',
    disabled: '#ebebeb',
    surface: '#F2F2F2',
  };

  const textColors: { [key: string]: string } = {};
  const adjustedTextColors: { [key: string]: string } = {};

  Object.keys(themeColors).forEach((key) => {
    const color = themeColors[key];
    textColors[`t-bw-${key}`] = getTextBlackOrWhite(color);
    adjustedTextColors[`t-adj-${key}`] = getTextColorAdjusted(color);
  });

  return {
    ...themeColors,
    ...textColors,
    ...adjustedTextColors,
  };
};

const vuetifyTheme = getThemeColors();

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      hypert: {
        component: HypertIcon,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: vuetifyTheme,
    },
    dark: false,
  },
});

Vue.use(VuetifyToast, {
  x: 'right',
  y: 'top',
  classes: ['notification', 'body-2', 'light'],
  queueable: true,
  closeIcon: "mdi-close",
  showClose: true,
});

store.watch(
  (state) => state.customization.theme.colors,
  (themeColors) => {
    if (themeColors) {
      const updatedColors = {
        ...themeColors,
        ...Object.keys(themeColors).reduce((acc, key) => {
          const color = themeColors[key];
          acc[`t-bw-${key}`] = getTextBlackOrWhite(color);
          acc[`t-adj-${key}`] = getTextColorAdjusted(color);
          return acc;
        }, {} as { [key: string]: string }),
      };

      vuetify.framework.theme.themes.light = updatedColors;
    }
  },
  { deep: true }
);

export default vuetify;
