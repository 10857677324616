import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import ReportsService from "../../../services/ReportsService";
import AppointmentsService from "../../../services/AppointmentsService";
import InspectionsService from "../../../services/InspectionsService";
import DocumentsService from "../../../services/DocumentsService";

export default {
    async getDataStock({dispatch, commit, state}, [toast, filters]) {
        state.totalsByLocations = [];
        state.totalsByProducts = [];
        state.generalTotals = [];
        commit('SET_LOADING', true);
        try {
            const {data} = await ReportsService.getDataStock(filters);
            if(!data.totalsByProducts.length > 0){
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
            commit(types.SET_DATA_STOCK, data);
            commit('SET_LOADING', false);
        } catch (error) {
            if (error.response.message !== 'cancelRequest') {
                if (error.response.status === 409)
                    toast.info(i18n.t('reports.notification.taskRunning'), {
                        icon: 'mdi-information-slab-circle',
                        queueable: true
                    });
                else
                    toast.info(i18n.t('reports.notification.recordsCero'), {
                        icon: 'mdi-information-slab-circle',
                        queueable: true
                    });
            }
            commit('SET_LOADING', false);
        }
    },
    async fetchDataFilteredForVoiter({dispatch, commit, state}, [toast, filters]) {
        state.contracts = [];
        commit('SET_LOADING', true);
        try {
            const {data} = await ReportsService.getProtheusDataFiltered(filters);
            commit(types.SET_DATA_CONTRACTS, data);
            commit('SET_LOADING', false);
            if(!data.totalElements > 0){
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING', false);
        }
    },

    async getContracts({dispatch, commit, state}, [toast, filters]) {
        state.contracts = [];
        commit('SET_LOADING_CONTRACTS', true);
        try {
            const {data} = await ReportsService.getDataContracts(filters);
            commit(types.SET_DATA_CONTRACTS, data);
            if(!data.totalElements > 0){
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
            commit('SET_LOADING_CONTRACTS', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING_CONTRACTS', false);
        }
    },

    async getSellers({dispatch, commit, state}, [toast, filters]) {
        state.sellers = [];
        commit('SET_LOADING_SELLERS', true);
        try {
            const {data} = await ReportsService.getDataSellers(filters);
            commit(types.SET_DATA_SELLERS, data);
            if (!(data && data.length > 0)) {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
            commit('SET_LOADING_SELLERS', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });

            commit('SET_LOADING_SELLERS', false);
        }
    },

    async getContract({dispatch, commit, state}, [contract,toast]) {
        state.itemInSheet = {};
        commit('SET_LOADING', true);
        try {
            const {data} = await ReportsService.getContract(contract);
            commit(types.SET_ITEM_IN_SHEET, data);
            commit('SET_LOADING', false);
            if(!data.contract){
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING', false);
        }
    },

    async fetchContractsNames({dispatch, commit, state}, [profile, toast]) {
        try {
            const {data} = await ReportsService.getContractsNumbers(profile.company_id);
            commit(types.SET_CONTRACTS_NAMES, data);
            commit('SET_LOADING', false);

            // MANEJAR MENSAJES
            // if(!(data && data.length > 0)){
            //     toast.error(i18n.t('reports.notification.recordsCero'), {
            //         queueable: true,
            //         color: 'gray'
            //     });
            // }
        } catch (error) {

            // MANEJAR MENSAJES
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING', false);
        }
    },

    async fetchSellersNames({dispatch, commit, state}, [profile, toast]) {
        try {
            const {data} = await ReportsService.getSellersNames(profile.company_id);
            commit(types.SET_SELLERS_NAMES, data);
            commit('SET_LOADING', false);

            // MANEJAR MENSAJES
            // if(!(data && data.length > 0)){
            //     toast.error(i18n.t('reports.notification.recordsCero'), {
            //         queueable: true,
            //         color: 'gray'
            //     });
            // }
        } catch (error) {

            // MANEJAR MENSAJES
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING', false);
        }
    },

    async fetchVolumesN({dispatch, commit, state}, [profile, toast]) {
        try {
            const {data} = await ReportsService.getVolumesN(profile.company_id);
            commit(types.SET_VOLUMES_NEGOTIATE, data);
            commit('SET_LOADING', false);

            // MANEJAR MENSAJES
            if(!data){
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        } catch (error) {

            // MANEJAR MENSAJES
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING', false);
        }
    },

    async fetchVolumesB({dispatch, commit, state}, [profile, toast]) {
        try {
            const {data} = await ReportsService.getVolumesB(profile.company_id);
            commit(types.SET_VOLUMES_BENEFIT, data);
            commit('SET_LOADING', false);

            // MANEJAR MENSAJES
            if(!data){
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        } catch (error) {

            // MANEJAR MENSAJES
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING', false);
        }
    },

    async fetchQuebra({dispatch, commit, state}, [profile, toast]) {
        try {
            const {data} = await ReportsService.getQuebra(profile.company_id);
            commit(types.SET_QUEBRA, data);
            commit('SET_LOADING', false);

            // MANEJAR MENSAJES
            if(!data){
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        } catch (error) {

            // MANEJAR MENSAJES
            if (error.message !== 'cancelRequest')
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            commit('SET_LOADING', false);
        }
    },

    // charts
    addDatasets ({state, commit}, payload) {
        commit('SET_DATASETS', payload)
    },
    addChart ({state, commit}, payload) {
        commit('SET_CHART', payload)
    },
    setItemInSheet ({state, commit}, payload) {
        commit('SET_ITEM_IN_SHEET', payload)
    },

    // sheet
    setSheetContract ({state, commit}, payload) {
        commit('SET_SHEET_CONTRACT', payload)
    },
    setSheetSeller ({state, commit}, payload) {
        commit('SET_SHEET_SELLER', payload)
    },

    async fetchRecordsForGeneralReport({state, commit}, [filters, source, toast]) {
        state.records = []
        try {
            const { data } = await AppointmentsService.appointmentsByCompanyPaginatedGeneralReport(filters, source);
            commit('SET_RECORDS', data)
        } catch (error) {
            if (error.code !== 'ERR_CANCELED') {
                toast.error(i18n.t('records.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },
    async fetchInspectionsForGeneralReport({state, commit}, [filters, toast]) {
        state.inspections = []
        try {
            const { data } = await InspectionsService.survey(filters);
            commit('SET_INSPECTIONS', data)
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('survey.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },
    async fetchDocumentsForGeneralReport({state, commit}, [filters, toast]) {
        state.documents = []
        try {
            const { data } = await DocumentsService.documentsFiltered(filters);
            commit('SET_DOCUMENTS', data)
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('documents.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },
    async fetchBiorganicDataForGeneralReport({state, commit}, [filters, source, toast]) {
        state.biorganic = []
        try {
            const { data } = await ReportsService.getBiorganicFiltered(filters, source);
            commit('SET_BIORGANIC', data)
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        }
    },

    async fetchTraceabilityReportData({state, commit}, [toast, filters]) {
        state.traceability = []
        commit('SET_LOADING', true);

        try {
            const { data } = await ReportsService.getTraceabilityFiltered(filters);
            commit('SET_TRACEABILITY', data)
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async fetchTraceabilityQRData({state, commit}, [toast, filters]) {
        try {
            const { data } = await ReportsService.getQrList(filters);
            commit('SET_QR_LIST', data)
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        }
    },

    async fetchDataForTraceabilityV2Report({state, commit}, [filters, toast, source]) {
        state.traceabilityV2 = []
        try {
            const { data } = await ReportsService.getTraceabilityV2Report(filters, source);
            commit('SET_TRACEABILITYV2', data)
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        }
    },

    async fetchExistingTraceabilityReportData({state, commit}, [toast, filters]) {
        commit('SET_LOADING', true);

        return new Promise( async (resolve, reject) => {
            try {
                const res = await ReportsService.getExistingTraceabilityReport(filters);

                localStorage.setItem('documentIds', JSON.stringify(res.data.documentIds))
                resolve(res)
            } catch (error) {
                localStorage.removeItem('documentIds')
                if (error.message !== 'cancelRequest') {
                    toast.info(i18n.t('reports.notification.recordsCero'), {
                        icon: 'mdi-information-slab-circle',
                        queueable: true
                    });
                }
                reject(error)
            } finally {
                commit('SET_LOADING', false);
            }
        })
    },

    fetchRecordsByIds({dispatch, commit, state}, [paginationAndFilter, source, toast]) {
        commit('SET_RECORDS_BY_IDS_ORIGINAL_STATE')

        return new Promise((resolve, reject) => {
            AppointmentsService.appointmentsByIds(paginationAndFilter, source)
              .then((res) => {
                  resolve(res.data)
                  commit(types.SET_RECORDS_BY_IDS, res.data);
              })
            .catch((error) => {
                reject(error)
                if (error.message !== 'cancelRequest') {
                    toast.error(i18n.t('records.notification.listError'), {
                        icon: "mdi-alert-circle",
                        queueable: true
                    });
                }
            })
        })
    },

    async getBiorganicReportCSV({state, commit}, [filters, toast]) {
        try {
            const { data } = await ReportsService.getBiorganicReport(filters);

            const FileSaver = require('file-saver');
            const blob = new Blob([data], {
                type: 'application/xlsx'
            });

            FileSaver.saveAs(blob, 'biorganic_report.xlsx');
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        }
    },

    async getGeneralReportPDF({commit}, [filters, toast]) {
        try {
            const { data } = await ReportsService.getGeneralReport(filters);
            const FileSaver = require('file-saver');
            const blob = new Blob([data], {
                type: 'application/pdf'
            });
            FileSaver.saveAs(blob, `${i18n.t('reports.menu.general-report')}.pdf`);
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('reports.notification.exportError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },

    async generateReportsByLotAndProduct({commit}, data) {
        try {
            await ReportsService.generateReportsByLotAndProduct(data);

            this._vm.$toast.success(i18n.t('reports.notification.generateReportsSuccess'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                this._vm.$toast.error(i18n.t('reports.notification.generateReportsError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },

    async setReCalculatedStockData({ commit }, [toast]) {
        try {
            await ReportsService.setManualStockUpdate();
            const { data } = await ReportsService.getDateNumberOfPendants();
            commit(types.SET_RECALCULATE_STOCK_INFO, data);
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        }
    },

    async setDateAndReCalculate({ commit }, [toast, payload]) {
        try {
            await ReportsService.setDateToStartStockReset(payload)

            toast.success(i18n.t('common.notification.configSave'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.response.message !== 'cancelRequest') {
                if (error.response.status === 409)
                    toast.error(i18n.t('reports.notification.taskRunning'), {
                        icon: "mdi-alert-circle",
                        queueable: true,
                    });
                else
                    toast.error(i18n.t('common.notification.configSaveError'), {
                        icon: "mdi-alert-circle",
                        queueable: true
                    });
            }
        }
    },

    async getDateNumberOfPendants({ commit }, [toast]) {
        try {
            const { data } = await ReportsService.getDateNumberOfPendants();
            commit(types.SET_RECALCULATE_STOCK_INFO, data);
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.info(i18n.t('reports.notification.recordsCero'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            }
        }
    },

    async checkCanProcess({}) {
        return new Promise((resolve, reject) => {
            ReportsService.checkCanProcess().then(res => {
                resolve(res.data);
            }).catch(err => reject(err))
        })
    },

    async activeOrDesactiveQr({ dispatch }, [toast, payload]) {
        try {
            await ReportsService.activeOrDeactive(payload);

            toast.success(i18n.t('reports.notification.activeOrDeactiveSuccess'), {
                icon: 'mdi-check-circle',
                queueable: true
            });

            dispatch('fetchTraceabilityQRData', [toast, {
                pageableDTO: {
                    page:0,
                    size:10,
                    sortBy: "id",
                    direction: "desc"
                }
            }]);
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('reports.notification.activeOrDeactiveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },

    async setQrPrintLimit({ dispatch }, [toast, payload]) {
        try {
            await ReportsService.setPrintLimit(payload);

            toast.success(i18n.t('reports.notification.setPrintLimitSuccess'), {
                icon: 'mdi-check-circle',
                queueable: true
            });

            dispatch('fetchTraceabilityQRData', [toast, {
                pageableDTO: {
                    page:0,
                    size:10,
                    sortBy: "id",
                    direction: "desc"
                }
            }]);
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('reports.notification.setPrintLimitError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },

    async fetchStockDate({ dispatch }, [toast]) {
        return new Promise((resolve, reject) => {
            ReportsService.getStockDate().then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error)

                if (error.message !== 'cancelRequest') {
                    toast.error(i18n.t('reports.notification.fetchErrorDate'), {
                        icon: "mdi-alert-circle",
                        queueable: true
                    });
                }
            })
        })
    },

    async deleteReportTraceability({ commit, state }, [id, toast]) {
        try {
            await ReportsService.deleteTraceabilityReport(id)
            toast.success(i18n.t('reports.notification.deleted'), {
                queueable: true,
                icon: 'mdi-check-circle',
            })
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('reports.notification.deletedError'), {
                    icon: "mdi-alert-circle",
                    queueable: true,
                })
        }
    },
}
