import * as types from './mutations-type.js';
import Vue from 'vue';

export default {
    [types.FILTER_TYPE](state, data) {
        state.type = data;
    },
    [types.FILTER_SOURCE](state, data) {
        state.source = data;
    },
    [types.DRAWER](state, data) {
        state.drawer = data;
    },
    [types.SIDENAV](state, data) {
        state.sidenav = data;
    },
    [types.LOADING](state, data) {
        state.loading = data;
    },
    [types.FILTERS](state, data) {
        state.filters = data;
    },
    [types.CHART_DATA](state, data) {
        state.chartData = data;
    },
    [types.DATA](state, data) {
        state.data = data;
    },
    [types.STRUCTURE_FILTERS](state, data) {
        state.structureFilter = data;
    },
    [types.STRUCTURES_BY_IDS](state, data) {
        state.structuresByIds = data;
    },
    [types.OTHERS_FILTERS](state, data) {
        state.othersFilter = data;
    },
    [types.OPTIONS_TABLE](state, data) {
        state.optionsTable = data;
    },
    [types.FILTERS_CHIPS](state, data) {
        state.filtersChips = data;
    },
    [types.COUNT_FILTERS](state, data) {
        state.cantFilters = data;
    },
    [types.LOCATIONS_FILTERS](state, data) {
        state.locations = data;
    },
    [types.STAGE_ID](state, data) {
        state.filterRecord = data;
    },
    [types.OBJECTS_TO_FILTERS](state, data) {
        state.filtersObjects = data;
    },
    [types.PRODUCER_TO_FILTERS](state, data) {
        state.producerFilters = data;
    },
    [types.CHART_RANGES](state, data) {
        state.chartRanges = data;
    },
    [types.LOCATION_RANGES](state, data) {
        state.locationRanges.primary = data;
    },
    [types.STORAGE_RANGES](state, data) {
        state.locationRanges.secondary = data;
    },
    [types.CONTRACTS_RANGES](state, data) {
        state.contractsRanges = data;
    },
    [types.CONTRACTS_DATA](state, data) {
        state.contractsData = data;
    },
    [types.DATE_FILTERS](state, data) {
        state.date = data;
    },
    [types.NOTIFICATION](state, data) {
        state.notification = data;
    },
    [types.NOMENCLATOR_DATA](state, data) {
        state.nomencaltor = data;
    },
    [types.NOMENCLATOR_DATA_ORIGINAL_STATE](state) {
        state.nomencaltor = null
    },
    [types.QUESTIONS](state, data) {
        state.questions = data
    },
    [types.SELECTED_QUESTIONS](state, data) {
        state.selectedQuestions = data
    },
    [types.ANSWERS](state, data) {
        state.answers = data
    },
    [types.SELECTED_ANSWERS](state, data) {
        state.selectedAnswers = data
    },
    [types.SET_FILTERS_SORT](state, data) {
        state.filtersSort = data
    },

    [types.PRODUCER_FILTERS_ORIGINAL_STATE](state) {
        state.producerFilters = {
            stringFilter: null,
            locations: [],
            storageDevices: [],
            name: null,
            cpf: null,
            rg: null,
            inscriptionCode: null,
            address: null,
            state: null,
            city: null,
            country: null,
            zipCode: null,
            phone: null,
            email: null,
            isActive: null
        }
    },

    [types.FILTERS_ORIGINAL_STATE](state, type) {
        state.filters =  {
            stringFilter: null,
            template_names: [],
            company_id: null,
            process_ids: [],
            classificationTypes: [],
            isActiveOnMobile: null,
            products_ids: type === 'traceability' ? state.filters.products_ids : [],
            seasons_ids: [],
            person_in_charge_ids: [],
            sdi_origin_ids: [],
            sdi_destination_ids: [],
            start_record_date_time: null,
            end_record_date_time: [],
            sent_date_time: null,
            structure: [],
            structureIds: [],
            pageableDTO: null,
            record_ids: type === 'traceability' ? state.filters.record_ids : [],
            status: [],
            reportType: null,
            storage_device_types_ids: [],
            min_punctuation: 0,
            max_punctuation: 100,
            withExternalVisibility: null,
            document_authority_ids: [],
            document_type_ids: [],
            document_description_ids: [],
            storage_devices_ids: [],
            document_date: [],
            expiration_date: [],
            lots:  type === 'traceability' ? state.filters.lots : [],
            isActive: null,
            productive: null,
            isExpired: null,
            isPublic: null,
            locationStrict: true,
            sellers_ids: [],
            contracts_names: [],
            volumeB_range: [],
            volumeN_range: [],
            quebra_range: [],
            contracts_dates: [],
            answers: [],
            minArea: null,
            maxArea: null,
            primaryLocationIds: [],
            ids: []
        };
        state.filtersObjects = {
            stringFilter: null,
            locations: {
                area: [0, 0],
                isActive: null,
                productive: null,
                status: [],
                ids: [],
                classificationTypes: []
            },
            storages: {
                primaryLocationIds: [],
                area: [0, 0],
                ids: [],
                classificationTypes: []
            },
            lots: [],
            process_ids: [],
            products_ids: [],
            classificationTypes: [],
            seasons_ids: [],
            person_in_charge_ids: [],
            storage_device_types_ids: [],
            storage_devices_ids: [],
            sdi_origin_ids: [],
            sdi_destination_ids: [],
            start_record_date_time: null,
            sent_date_time: null,
            reportType: null,
            document: {
                document_date: [],
                expiration_date: [],
                isActive: null,
                isExpired: null,
                isPublic: null
            },
            qr: {
                isActive: null
            },
            record: {
                status: []
            },
            range: [0, 100],
            isActiveOnMobile: null,
            sellers_ids: [],
            contracts_names: [],
            volumeB_range: [],
            volumeN_range: [],
            quebra_range: [],
            contracts_dates: [],
            locationStrict: true
        };
        state.chartRanges = {
            quebra: [0, 100],
            volume: [0, 100]
        };
        state.chartData = {
            quebra: [],
            volume: []
        };
        state.contractsRanges = {
            quebra: [0, 100],
            volumeN: [0, 100],
            volumeB: [0, 100],
        };
        state.contractsData = {
            quebras: [],
            volumesN: [],
            volumesB: [],
        };
        state.locations =  {
            origin_locations_filters: [],
            destination_locations_filters: [],
        };
        state.date = {
            record_date_time: '',
            sent_date_time: '',
            filtersDate: [],
            filtersRecordDate: [],
            filtersRegisterDate: [],
            filtersRecordRegisterDate: [],
            filtersInspectionsDate: [],
            filtersDocumentDate: {
                document_date: [],
                expiration_date: []
            },
            filtersContractsDate: [],
            document_date: '',
            expiration_date: '',
            contracts_dates: '',
            dateFormat: 'DD/MM/YYYY HH:mm',
        };
        state.structureFilter = [];
        state.structuresByIds = [];
        state.questions = [];
        state.selectedQuestions = [];
        state.answers = [];
        state.selectedAnswers = [];
        state.data = {};
        state.filtersChips = [];
        state.cantFilters = null
    },

    [types.FILTERS_OBJECTS_ORIGINAL_STATE](state) {
        state.filtersObjects = {
            stringFilter: null,
            locations: {
                area: [0, 0],
                isActive: null,
                productive: null,
                status: [],
                ids: []
            },
            storages: {
                primaryLocationIds: [],
                area: [0, 0],
                ids: []
            },
            lots: [],
            process_ids: [],
            products_ids: [],
            seasons_ids: [],
            person_in_charge_ids: [],
            storage_device_types_ids: [],
            storage_devices_ids: [],
            sdi_origin_ids: [],
            sdi_destination_ids: [],
            start_record_date_time: null,
            sent_date_time: null,
            reportType: null,
            document: {
                document_date: [],
                expiration_date: [],
                isActive: null,
                isExpirated: null,
                isPublic: null
            },
            qr: {
                isActive: null
            },
            record: {
                status: []
            },
            range: [0, 100],
            isActiveOnMobile: null,
            sellers_ids: [],
            contracts_names: [],
            volumeB_range: [],
            volumeN_range: [],
            quebra_range: [],
            contracts_dates: []
        };
    },

    [types.SET_LOCAL_STORAGE_FOR_CONTRACTS](state) {
        let filtersContracts = {
            type: 'contracts',
            filtersObjects: {
                contracts_names: state.filtersObjects.contracts_names,
                sellers_ids: state.filtersObjects.sellers_ids,
                contracts_dates: state.filtersObjects.contracts_dates,
            },
            contracts_dates: state.date['contracts_dates'],
            contractsRanges: {
                volumeN: state.contractsRanges.volumeN,
                volumeB: state.contractsRanges.volumeB,
                quebra: state.contractsRanges.quebra,
            },
            filtersContractsDate: state.date.filtersContractsDate
        };
        localStorage.setItem('filtersContracts', JSON.stringify(filtersContracts));
    },
    [types.SET_LOCAL_STORAGE_FOR_SELLERS](state) {
        let filtersSellers = {
            type: 'sellers',
            filtersObjects: {
                contracts_names: state.filtersObjects.contracts_names,
                sellers_ids: state.filtersObjects.sellers_ids,
                sellers_dates: state.filtersObjects.contracts_dates,
            },
            sellers_dates: state.date['contracts_dates'],
            contractsRanges: {
                volumeN: state.contractsRanges.volumeN,
                volumeB: state.contractsRanges.volumeB,
                quebra: state.contractsRanges.quebra,
            },
            filtersSellersDate: state.date.filtersContractsDate
        };
        localStorage.setItem('filtersSellers', JSON.stringify(filtersSellers));
    },
    [types.SET_LOCAL_STORAGE_FOR_DOCUMENTS](state) {
        let filtersDocuments = {
            type: 'documents',
            document_authority_ids: state.filters.document_authority_ids,
            document_type_ids: state.filters.document_type_ids,
            document_description_ids: state.filters.document_description_ids,
            withExternalVisibility: state.filters.withExternalVisibility,
            storage_devices_ids: state.filtersObjects.sdi_destination_ids,
            destination_locations_filters: state.locations.destination_locations_filters,
            filtersObjects: {
                document: {
                    document_date: state.filtersObjects.document.document_date,
                    expiration_date: state.filtersObjects.document.expiration_date,
                    isActive: state.filtersObjects.document.isActive,
                    isExpirated: state.filtersObjects.document.isExpirated,
                    isPublic: state.filtersObjects.document.isPublic
                },
                seasons_ids: state.filtersObjects.seasons_ids,
                sdi_destination_ids: state.filtersObjects.sdi_destination_ids,
            },
            filtersDocumentDate: {
                document_date: state.date.filtersDocumentDate['document_date'],
                expiration_date: state.date.filtersDocumentDate['expiration_date']
            }
        };
        localStorage.setItem('filtersDocuments', JSON.stringify(filtersDocuments));
    },
    [types.SET_LOCAL_STORAGE_FOR_INSPECTIONS](state) {
        let filtersInspections = {
            type: 'inspections',
            template_names: state.filters.template_names,
            origin_locations_filters: state.locations.origin_locations_filters,
            destination_locations_filters: state.locations.destination_locations_filters,
            filtersObjects: {
                storage_device_types_ids: state.filtersObjects.storage_device_types_ids,
                person_in_charge_ids: state.filtersObjects.person_in_charge_ids,
                sdi_origin_ids: state.filtersObjects.sdi_origin_ids,
                sdi_destination_ids: state.filtersObjects.sdi_destination_ids,
                start_record_date_time: state.date['record_date_time'],
                range: state.filtersObjects.range ? state.filtersObjects.range : [0,100]
            },
            questions: {
                all: state.questions,
                selected: state.selectedQuestions
            },
            answers: {
                all: state.answers ,
                selected: state.selectedAnswers
            },
            filtersInspectionsDate: state.date.filtersRecordDate,
            record_date_time: state.date['record_date_time'],
            filtersDate: state.date.filtersDate
        };
        localStorage.setItem('filtersInspections', JSON.stringify(filtersInspections));
    },
    [types.SET_LOCAL_STORAGE_FOR_RECORDS](state, values) {
        _.forEach(state.structuresByIds, (structure) => {
            Vue.set(structure, 'structures_selected', values[structure.structure_id]);
        });

        let filtersRecords = {
            type: 'records',
            origin_locations_filters: state.locations.origin_locations_filters,
            destination_locations_filters: state.locations.destination_locations_filters,
            filtersObjects: {
                stringFilter: state.filtersObjects.stringFilter,
                record: {
                    status: state.filtersObjects.record.status
                },
                process_ids: state.filtersObjects.process_ids,
                products_ids: state.filtersObjects.products_ids,
                lots: state.filtersObjects.lots,
                seasons_ids: state.filtersObjects.seasons_ids,
                person_in_charge_ids: state.filtersObjects.person_in_charge_ids,
                sdi_origin_ids: state.filtersObjects.sdi_origin_ids,
                sdi_destination_ids: state.filtersObjects.sdi_destination_ids,
                start_record_date_time: state.date['record_date_time'],
                sent_date_time: state.date['sent_date_time'],
                locationStrict: state.filtersObjects.locationStrict,
            },
            structures: {
                structureFilter: state.structureFilter,
                structuresByIds: state.structuresByIds
            },
            filtersRecordDate: state.date.filtersRecordDate,
            filtersRecordRegisterDate: state.date.filtersRecordRegisterDate,
            record_date_time: state.date['record_date_time'],
            sent_date_time: state.date['sent_date_time'],
            filtersDate: state.date.filtersDate,
            filtersRegisterDate: state.date.filtersRegisterDate,
        };
        localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords));
    },
    [types.SET_LOCAL_STORAGE_FOR_PRODUCER](state) {
        let filtersProducer = {
            type: 'producer',
            ...state.producerFilters
        };
        console.log(filtersProducer)
        localStorage.setItem('filtersProducer', JSON.stringify(filtersProducer));
    },
    [types.CLEAR_LOCAL_FILTERS_FOR_PRODUCER](state) {
        const data = {
            stringFilter: null,
            locations: [],
            storageDevices: [],
            name: null,
            cpf: null,
            rg: null,
            address: null,
            state: null,
            city: null,
            country: null,
            zipCode: null,
            phone: null,
            email: null,
            isActive: null
        };
        console.log(data)
        state.producerFilters = data;
        localStorage.setItem('filtersProducer', JSON.stringify(data));
    },
    [types.SET_LOCAL_STORAGE_FOR_LOCATIONS](state) {
        let filtersLocations = {
            type: 'locations',
            filtersObjects: {
                stringFilter: state.filtersObjects.stringFilter,
                locations: {
                    status: state.filtersObjects.locations.status,
                    isActive: state.filtersObjects.locations.isActive,
                    ids: state.filtersObjects.locations.ids,
                    area: state.filtersObjects.locations.area,
                    classificationTypes: state.filtersObjects.locations.classificationTypes
                },
                storage_device_types_ids: state.filtersObjects.storage_device_types_ids
            }
        };
        localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations));
    },
    [types.SET_LOCAL_STORAGE_FOR_STORAGES](state) {
        let filtersStorages = {
            type: 'storages',
            filtersObjects: {
                stringFilter: state.filtersObjects.stringFilter,
                locations: {
                    status: state.filtersObjects.locations.status,
                    isActive: state.filtersObjects.locations.isActive,
                    productive: state.filtersObjects.locations.productive,
                    ids: state.filtersObjects.locations.ids,
                    classificationTypes: state.filtersObjects.locations.classificationTypes
                },
                storages: {
                    ids: state.filtersObjects.storages.ids,
                    area: state.filtersObjects.storages.area,
                    primaryLocationIds: state.filtersObjects.storages.primaryLocationIds,
                    classificationTypes: state.filtersObjects.storages.classificationTypes,
                },
                storage_device_types_ids: state.filtersObjects.storage_device_types_ids
            }
        };
        localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages));
    },
    [types.SET_LOCAL_STORAGE_FOR_PRE_RECORDS](state) {
        let filtersPreRecords = {
            type: 'pre-records',
            origin_locations_filters: state.locations.origin_locations_filters,
            destination_locations_filters: state.locations.destination_locations_filters,
            filtersObjects: {
                storage_device_types_ids: state.filtersObjects.storage_device_types_ids,
                process_ids: state.filtersObjects.process_ids,
                sdi_origin_ids: state.filtersObjects.sdi_origin_ids,
                sdi_destination_ids: state.filtersObjects.sdi_destination_ids,
                isActiveOnMobile: state.filtersObjects.isActiveOnMobile
            },
        };
        localStorage.setItem('filtersPreRecords', JSON.stringify(filtersPreRecords));
    },
    [types.CLEAR_LOCAL_FILTERS](state) {
        let filtersDocuments = {
            type: state.type,
            authority_ids: [],
            document_type_ids: [],
            description_ids: [],
            storage_devices_ids: [],
            destination_locations_filters: [],
            withExternalVisibility: null,
            filtersObjects: {
                document: {
                    document_date: [],
                    expiration_date: [],
                    isActive: null,
                    isExpirated: null,
                    isPublic: null
                },
                sdi_destination_ids: [],
            },
            filtersDocumentDate: {
                document_date: [],
                expiration_date: []
            }
        };
        localStorage.setItem('filtersDocuments', JSON.stringify(filtersDocuments));
        let filtersRecords = {
                type: state.type,
                origin_locations_filters: [],
                destination_locations_filters: [],
                filtersObjects: {
                    record: {
                        status: []
                    },
                    process_ids: [],
                    products_ids: [],
                    lots: [],
                    seasons_ids: [],
                    person_in_charge_ids: [],
                    sdi_origin_ids: [],
                    sdi_destination_ids: [],
                    start_record_date_time: null,
                    sent_date_time: null,
                    stringFilter: null,
                },
                structures: {
                    structureFilters: [],
                    structureByIds: []
                },
                filtersRecordDate: [],
                filtersRecordRegisterDate: [],
                record_date_time: '',
                sent_date_time: '',
                filtersDate: [],
                filtersRegisterDate: [],
            };
        localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords));
        let filtersLocations = {
                type: state.type,
                filtersObjects: {
                    locations: {
                        status: [],
                        isActive: null,
                        ids: [],
                        area: [null, null]
                    },
                    stringFilter: null,
                    storage_device_types_ids: []
                }
            };
        localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations));
        let filtersStorages = {
                type: state.type,
                filtersObjects: {
                    locations: {
                        status: [],
                        isActive: null,
                        productive: null,
                        ids: [],
                        area: [null, null]
                    },
                    storages: {
                        ids: [],
                        area: [null, null],
                        primaryLocationIds: []
                    },
                    stringFilter: null,
                    storage_device_types_ids: []
                },
            };
        localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages));
    },
    [types.CLEAR_LOCAL_FILTERS_FOR_DOCUMENT](state) {
        let filtersDocuments = {
            type: state.type,
            authority_ids: [],
            document_type_ids: [],
            description_ids: [],
            storage_devices_ids: [],
            destination_locations_filters: [],
            withExternalVisibility: null,
            filtersObjects: {
                document: {
                    document_date: [],
                    expiration_date: [],
                    isActive: null,
                    isExpirated: null,
                    isPublic: null
                },
                sdi_destination_ids: [],
            },
            filtersDocumentDate: {
                document_date: [],
                expiration_date: []
            }
        };
        localStorage.setItem('filtersDocuments', JSON.stringify(filtersDocuments));
    },
    [types.CLEAR_LOCAL_STORAGE](state, payload) {
        if (state.type === 'pre-records') {
            let filtersPreRecords = {
                type: state.type,
                origin_locations_filters: [],
                destination_locations_filters: [],
                filtersObjects: {
                    storage_device_types_ids: null,
                    process_ids: null,
                    sdi_origin_ids: null,
                    sdi_destination_ids: null,
                    isActiveOnMobile: null
                },
            };
            localStorage.setItem('filtersPreRecords', JSON.stringify(filtersPreRecords));
        } else if (state.type === 'records') {
            let filtersRecords = {
                type: state.type,
                origin_locations_filters: [],
                destination_locations_filters: [],
                filtersObjects: {
                    record: {
                        status: []
                    },
                    process_ids: [],
                    products_ids: [],
                    lots: [],
                    seasons_ids: [],
                    person_in_charge_ids: [],
                    sdi_origin_ids: [],
                    sdi_destination_ids: [],
                    start_record_date_time: null,
                    sent_date_time: null,
                    stringFilter: null,
                },
                structures: {
                    structureFilters: [],
                    structureByIds: []
                },
                filtersRecordDate: [],
                filtersRecordRegisterDate: [],
                record_date_time: '',
                sent_date_time: '',
                filtersDate: [],
                filtersRegisterDate: [],
            };
            localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords));
        } else if (state.type === 'locations') {
            let filtersLocations = {
                type: state.type,
                filtersObjects: {
                    locations: {
                        status: [],
                        isActive: null,
                        ids: [],
                        area: [null, null]
                    },
                    stringFilter: null,
                    storage_device_types_ids: []
                }
            };
            localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations));
        } else if (state.type === 'storages') {
            let filtersStorages = {
                type: state.type,
                filtersObjects: {
                    locations: {
                        status: [],
                        isActive: null,
                        productive: null,
                        ids: [],
                        area: [null, null]
                    },
                    storages: {
                        ids: [],
                        area: [null, null],
                        primaryLocationIds: []
                    },
                    stringFilter: null,
                    storage_device_types_ids: []
                },
            };
            localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages));
        } else if (state.type === 'producer') {
            this.commit('ui/PRODUCER_FILTERS_ORIGINAL_STATE');
            localStorage.setItem('filtersProducer', JSON.stringify(state.producerFilters));
        } else if (state.type === 'inspections') {
            state.questions = []
            state.answers = []
            let filtersInspections = {
                type: state.type,
                template_names: [],
                origin_locations_filters: [],
                destination_locations_filters: [],
                filtersObjects: {
                    range: state.filtersObjects.range,
                    person_in_charge_ids: [],
                    sdi_origin_ids: [],
                    sdi_destination_ids: [],
                    start_record_date_time: null
                },
                filtersInspectionsDate: [],
                record_date_time: '',
                filtersDate: [],
                questions: {
                    all: [],
                    selected: []
                },
                answers: {
                    all: [],
                    selected: []
                },
            };
            localStorage.setItem('filtersInspections', JSON.stringify(filtersInspections));
        } else if (state.type === 'documents') {
            let filtersDocuments = {
                type: state.type,
                authority_ids: [],
                document_type_ids: [],
                description_ids: [],
                storage_devices_ids: [],
                destination_locations_filters: [],
                withExternalVisibility: null,
                filtersObjects: {
                    document: {
                        document_date: [],
                        expiration_date: [],
                        isActive: null,
                        isExpirated: null,
                        isPublic: null
                    },
                    sdi_destination_ids: [],
                },
                filtersDocumentDate: {
                    document_date: [],
                    expiration_date: []
                }
            };
            localStorage.setItem('filtersDocuments', JSON.stringify(filtersDocuments));
        }  else if (state.type === 'contracts') {
            let filtersContracts = {
                type: state.type,
                filtersObjects: {
                    contracts_names: [],
                    sellers_ids: [],
                    contracts_dates: '',
                },
                contracts_dates: '',
                contractsRanges: {
                    volumeN: payload.vN,
                    volumeB: payload.vB,
                    quebra: payload.bP,
                },
                contractsData: [],
                filtersContractsDate: []
            };
            localStorage.setItem('filtersContracts', JSON.stringify(filtersContracts));
        } else if (state.type === 'sellers') {
            let filtersSellers = {
                type: state.type,
                filtersObjects: {
                    contracts_names: [],
                    sellers_ids: [],
                    sellers_dates: '',
                },
                sellers_dates: '',
                contractsRanges: {
                    volumeN: payload.vN,
                    volumeB: payload.vB,
                    quebra: payload.bP,
                },
                contractsData: [],
                filtersSellersDate: []
            };
            localStorage.setItem('filtersSellers', JSON.stringify(filtersSellers));
        }
    },
}
