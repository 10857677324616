import i18n from "@/plugins/i18n";
import * as types from "./mutations-type.js";
import EquipmentService from "../../../../services/EquipmentService";

export default {
  loadingON({ commit, state }) {
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false);
  },
  setEquipmentList({ commit }, items) {
    commit(types.SET_EQUIPMENT_LIST, items);
  },
  async fetchListEquipment({ dispatch, commit }, [filters, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await EquipmentService.equipments(filters);
      commit(types.SET_EQUIPMENT_LIST, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("equipment.notification.listError"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });
    }
  },
  async exportCSV({ dispatch, commit, state }, [language, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await EquipmentService.export(language);
      const FileSaver = require("file-saver");
      const blob = new Blob([data], {
        type: "application/xlsx",
      });
      FileSaver.saveAs(blob, `${i18n.t('equipment.label').toLowerCase()}.xlsx`);

      toast.success(i18n.t("equipment.notification.export"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });

      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("equipment.notification.exportError"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });

      dispatch("loadingOFF");
    }
  },
  async exportEvidences({ commit, state }, [equipment, toast]) {
    try {
      const { data } = await EquipmentService.getEvidences(equipment);
      commit(types.EXPORT, data);
      if (!data.length > 0) {
        toast.info(i18n.t("equipment.notification.evidencesCero"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });
      }
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("equipment.notification.exportError"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });
    }
  },
  async saveEquipment(
    { dispatch, commit, state },
    [item, documents, editOrSave, toast]
  ) {
    dispatch("loadingON");
    try {
      await EquipmentService.save(item, documents, editOrSave);
      toast.success(i18n.t("equipment.notification.save"), {
        icon: 'mdi-check-circle',
        queueable: true
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.info(i18n.t("equipment.notification.inUse"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("equipment.notification.saveError"), {
            queueable: true,
            icon: "mdi-alert-circle",
          });
      }
    }
  },
  async deleteEquipment({ commit, state }, [id, toast]) {
    try {
      await EquipmentService.delete(id);
      toast.success(i18n.t("equipment.notification.deleted"), {
        icon: 'mdi-check-circle',
        queueable: true
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.info(i18n.t("equipment.notification.inUse"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("equipment.notification.deletedError"), {
            queueable: true,
            icon: "mdi-alert-circle",
          });
      }
    }
  },
};
