import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import ProcessService from "../../../../services/ProcessService";

export default {
    loadingON({commit, state}) {
        state.proccess = [];
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async fetchListProccess({dispatch, commit}, [profile, toast]) {
        dispatch('loadingON');
        try {
            const company = profile.company_id;
            const {data} = await ProcessService.proccess(company);
            commit(types.SET_PROCCESS_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('process.notification.listError'), {
                    queueable: true
                });
        } finally {
            dispatch('loadingOFF');
        }
    },
    async fetchNomenclatorData({dispatch, commit}, [payload, toast]) {
        dispatch('loadingON');
        try {
            const { data } = await ProcessService.searchNomenclatorData(payload);

            switch(payload.nomenclatorType) {
                case "product":
                    commit('product/SET_PRODUCT_LIST', { content: data, totalFromServer: data.length }, { root: true })
                    break;
                case "season":
                    commit('season/SET_SEASON_LIST', { content: data, totalFromServer: data.length }, { root: true })
                    break;
                case "equipment":
                    commit('equipment/SET_EQUIPMENT_LIST', { content: data, totalFromServer: data.length }, { root: true })
                    break;
                case "input":
                    commit('input/SET_INPUT_LIST', { content: data, totalFromServer: data.length }, { root: true })
                    break;
                case "operator":
                    commit('operator/SET_OPERATOR_LIST', { content: data, totalFromServer: data.length }, { root: true })
                    break;
            }
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('process.notification.nomenclatorError'), {
                    queueable: true
                });
        } finally {
            dispatch('loadingOFF');
        }
    },
    searchProcessStructure({dispatch, commit}, [search, structureId, source = null]) {
        return new Promise((resolve, reject) => {
            ProcessService.processStructureSearch(search, structureId, source).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            });
        })
    },
    async getProcessById({commit}, [process_id, toast]) {
        try {
            const {data} = await ProcessService.getById(process_id);
            commit(types.SET_PROCESS, data)
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('process.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchRulesByProcessId({commit}, [process_id, toast]) {
        try {
            const { data } = await ProcessService.getRules(process_id);
            return data;
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('process.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchProcessByVersionList({commit}, [ids, toast]) {
        try {
            const { data } = await ProcessService.getProcessByVersionList(ids);
            return data;
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('process.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveProccess({dispatch, commit, state}, [item, editOrSave, toast]) {
        dispatch('loadingON');
        try {
            const { data } = await ProcessService.save(item, editOrSave);

            await dispatch('getProcessById', [data.id, toast]);

            toast.success(i18n.t('process.notification.save'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            commit(types.SET_ORIGINAL_STATE_PROCESS)

            if (error.message.includes('406')) {
                toast.error(i18n.t('process.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('process.notification.saveError'), {
                        queueable: true
                    });
            }
        }
    },
    async saveRules({dispatch, commit, state}, [items, toast, opts]) {
        dispatch('loadingON');
        try {
            await ProcessService.saveRules(items);

            if (opts.showNotification) {
                toast.success(i18n.t('process.notification.saveRules'), {
                    queueable: true,
                    icon: 'mdi-check-circle',
                });
            }
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('process.notification.errorRules'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('process.notification.saveError'), {
                        queueable: true
                    });
            }
        }
    },
    async deleteProccess({commit, state}, [id, toast]) {
        try {
            await ProcessService.delete(id);
            toast.success(i18n.t('process.notification.deleted'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('process.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('process.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    }
}
