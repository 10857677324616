<template>
  <v-list-item :key="index" class="notification-item">
    <v-list-item-avatar v-if="pIcon" class="notification-avatar">
      <v-icon :color="pColor">
        {{ pIcon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-icon class="fixed-width">
      <v-chip small color="primary" class="notification-chip white--text">
        {{ $t('reports.titles.traceability').toUpperCase() }}
      </v-chip>
    </v-list-item-icon>
    <v-list-item-content class="d-flex justify-start">
      <p class="notification-content font-weight-medium my-1">
        {{ $t('reports.titles.generatedReport') }}
      </p>
      <p class="notification-detail my-1">
        {{ $t('reports.fields.lot') }}: <strong>{{ notification.data.lot }}</strong><br/>
        {{ $t('reports.fields.product') }}: <strong>{{ notification.data.product }}</strong>
      </p>
      <v-list-item-title class="d-block">
        <p class="notification-date">{{ getDateFormat(notification.dateTime) }}</p>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="withAction">
      <v-btn v-if="!notification.watched" 
             fab x-small 
             class="action-button" 
             elevation="0" 
             @click="$emit('mark:read')" 
             :loading='loading'>
        <template v-slot:loader>
          <v-progress-circular indeterminate size="12" width="2" />
        </template>
        <v-icon small>
          mdi-check
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
  name: 'TraceabilityNotification',

  props: {
    index: {
      type: Number,
      require: true
    },
    notification: {
      type: Object,
      require: true
    },
    loading: {
      type: Boolean,
      require: false,
      default: false
    },
    loadingSee: {
      type: Boolean,
      require: false,
      default: false
    },
    pColor: {
      type: String,
      require: false
    },
    pIcon: {
      type: String,
      require: false
    },
    withAction: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  data: () => ({}),

  computed: {
    ...mapGetters({
      documentType: "storage/documentTypes",
      date: "ui/GET_DATE_FILTERS"
    })
  },

  methods: {
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format(this.date.dateFormat)
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/notification-styles.scss";
</style>