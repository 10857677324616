import i18n from "@/plugins/i18n";
import * as types from "./mutations-type.js";
import OperatorService from "../../../../services/OperatorService";

export default {
  loadingON({ commit, state }) {
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false);
  },
  setOperatorList({ commit }, items) {
    commit(types.SET_OPERATOR_LIST, items);
  },
  async fetchListOperator({ dispatch, commit }, [filters, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await OperatorService.operators(filters);
      commit(types.SET_OPERATOR_LIST, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("operator.notification.listError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    }
  },
  async exportCSV({ dispatch, commit, state }, [language, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await OperatorService.export(language);
      const FileSaver = require("file-saver");
      const blob = new Blob([data], {
        type: "application/xlsx",
      });
      FileSaver.saveAs(blob, `${i18n.t('operator.label').toLowerCase()}.xlsx`);

      toast.success(i18n.t("operator.notification.export"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });

      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("operator.notification.exportError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });

      dispatch("loadingOFF");
    }
  },
  async saveOperator(
    { dispatch, commit, state },
    [item, documents, editOrSave, toast]
  ) {
    dispatch("loadingON");
    try {
      await OperatorService.save(item, documents, editOrSave);
      toast.success(i18n.t("operator.notification.save"), {
        queueable: true,
        icon: 'mdi-check-circle',
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.info(i18n.t("operator.notification.inUse"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("operator.notification.saveError"), {
            queueable: true,
            icon: "mdi-alert-circle",
          });
      }
    }
  },
  async deleteOperator({ commit, state }, [id, toast]) {
    try {
      await OperatorService.delete(id);
      toast.success(i18n.t("operator.notification.deleted"), {
        queueable: true,
        icon: 'mdi-check-circle',
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.info(i18n.t("operator.notification.inUse"), {
          queueable: true,
          icon: "mdi-alert-circle",
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("operator.notification.deletedError"), {
            queueable: true,
            icon: "mdi-alert-circle",
          });
      }
    }
  },
};
