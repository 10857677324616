import store from '@/store';
import { get } from 'lodash';

/**
 * Core modules that will always be available regardless of integration
 * @type {string[]}
 */
const CORE_MODULES = [
    'common',
    'menu',
    'notifications',
    'translations'
];

/**
 * Modules that will always be excluded
 * @type {string[]}
 */
const ALWAYS_EXCLUDED = [
    'auth',
    'home',
    'login',
    'login-abrapa',
    'login-ig',
    'abrapa'
];

/**
 * Integration-specific module configuration
 * @type {Object.<string, {include: string[], exclude: string[]}>}
 */
const INTEGRATION_CONFIG = {
    'ABRAPA': {
        include: [
            'abrapa',
        ],
        exclude: [
        ]
    },
    'IG': {
        include: [
        ],
        exclude: [
            'abrapa'
        ]
    }
};

/**
 * Common modules available for all integrations
 * @type {string[]}
 */
const COMMON_MODULES = [
    'company',
    'authority',
    'equipment',
    'inspections',
    'inventory',
    'operator',
    'process',
    'storage',
    'storageType',
    'customization',
    'dashboard',
    'groupStructure',
    'groupUnit',
    'input',
    'labels',
    'locations',
    'map',
    'measurementUnit',
    'popupContent',
    'pre-records',
    'product',
    'qr',
    'records',
    'reports',
    'roles',
    'structure',
    'survey',
    'tokens',
    'widgets',
    'documentAuthority',
    'documentDescription',
    'documentType',
    'documents',
    'producer',
    'rastreo',
    'season'
];

/**
 * Gets the currently active integration
 * @returns {string|null} The active integration name or null if none is active
 */
const getActiveIntegration = () => {
    for (const [integration] of Object.entries(INTEGRATION_CONFIG)) {
        if (store.getters['integration/IS_PRESENT'](integration)) {
            return integration;
        }
    }
    return null;
};

/**
 * Gets the list of available modules based on active integration
 * @returns {string[]} Array of available module names
 */
export const getAvailableModules = () => {
    const activeIntegration = getActiveIntegration();
    if (!activeIntegration) {
        return [
            ...CORE_MODULES, 
            ...COMMON_MODULES
        ];
    }

    const integrationConfig = INTEGRATION_CONFIG[activeIntegration];
    return [
        ...CORE_MODULES,
        ...COMMON_MODULES,
        ...integrationConfig.include
    ];
};

/**
 * Gets the list of excluded modules based on active integration
 * @returns {string[]} Array of excluded module names
 */
export const getExcludedModules = () => {
    const activeIntegration = getActiveIntegration();
    
    // If no integration is active, return just the always excluded modules
    if (!activeIntegration) {
        return ALWAYS_EXCLUDED;
    }

    const integrationConfig = INTEGRATION_CONFIG[activeIntegration];
    
    const filteredExcluded = ALWAYS_EXCLUDED.filter(
        module => !integrationConfig.include.includes(module)
    );

    return [
        ...filteredExcluded,
        ...(integrationConfig.exclude || [])
    ];
};

/**
 * Configuration object for translations
 * @type {Object}
 */
export const TRANSLATIONS_CONFIG = {
    get excludedModules() {
        return getExcludedModules();
    },
    get availableModules() {
        return getAvailableModules();
    },
    shouldExcludeModule(moduleName) {
        const availableModules = getAvailableModules();
        const excludedModules = getExcludedModules();
        
        return !availableModules.includes(moduleName) || 
               excludedModules.includes(moduleName);
    }
};
