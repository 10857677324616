// src/plugins/i18n.ts
import _ from 'lodash';
import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

// Contextos definidos para cada directorio de localización
const baseLocales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
const abrapaLocales = require.context('@/module/integrations/abrapa/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

// Array de contextos
const contexts: __WebpackModuleApi.RequireContext[] = [baseLocales, abrapaLocales];

function loadLocaleMessages(contexts: __WebpackModuleApi.RequireContext[] = []): LocaleMessages {
    console.log('=== LOADING INITIAL TRANSLATIONS ===');
    const messages: LocaleMessages = {};

    contexts.forEach((context, index) => {
        const contextPath = context.id || `Context ${index + 1}`;
        console.log(`📂 Loading from: ${contextPath}`);
        
        context.keys().forEach((key) => {
            const matched = key.match(/([A-Za-z0-9-_]+)/i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                const translations = context(key);
                // console.log(`📦 Found translations for ${locale} in ${key}:`, translations);
                messages[locale] = {
                    ...messages[locale],
                    ...translations
                };
            }
        });
    });

    console.log('📚 All local translations loaded:', messages);
    return messages;
}

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en-us',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-us',
    silentTranslationWarn: true,
    messages: loadLocaleMessages(contexts)
});

// Guardar las traducciones locales como fallback
const fallbackMessages = loadLocaleMessages(contexts);

function getFallbackMessage(locale: string, key?: string): any {
    const message = key ? _.get(fallbackMessages[locale], key) : (fallbackMessages[locale] || {});
    return message;
}

function getAllFallbackMessages(): LocaleMessages {
    return fallbackMessages;
}

export { getFallbackMessage, getAllFallbackMessages };
export default i18n;