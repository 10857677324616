<template>
    <fragment>
        <v-dialog v-model="dialog" max-width="350" persistent>
            <v-card :loading="loading">
                <v-card-title class="headline secondary t-bw-secondary--text">
                    {{ $t('common.titles.changePassTitle') }}
                </v-card-title>
                <v-card-text class="pa-6">
                    <v-container>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field outlined dense onkeypress="return (event.charCode != 34)"
                                v-on:keyup.86="replaceCurrentPassword" v-on:keyup.ctrl.86="replaceCurrentPassword"
                                v-model="changeData.currentPassword" :rules="requireRules"
                                :label="$t('common.fields.pass')" required color="primary"
                                type="password"></v-text-field>

                            <v-text-field outlined dense onkeypress="return (event.charCode != 34)" v-on:keyup.86="replaceNewPassword"
                                v-on:keyup.ctrl.86="replaceNewPassword" v-model="changeData.newPassword"
                                :rules="requireAndMinRules(5)" :label="$t('common.fields.newPass')" required
                                color="primary" type="password" @blur="validateRep()"></v-text-field>

                            <v-text-field outlined dense onkeypress="return (event.charCode != 34)"
                                v-on:keyup.86="replaceRepNewPassword" v-on:keyup.ctrl.86="replaceRepNewPassword"
                                v-model="repNewPassword" :rules="passRepRules" :label="$t('common.fields.repNewPass')"
                                required color="primary" type="password"></v-text-field>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider/>
                <v-card-actions class="pa-6">
                    <v-spacer></v-spacer>
                    <v-btn small text color="neuter" @click="reset">
                        {{ $t("common.buttons.cancel") }}
                    </v-btn>
                    <v-btn small elevation="0" color="primary" class="t-bw-error--text" @click="changePass">
                        {{ $t("common.buttons.change") }}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </fragment>
</template>
<script>
import i18n from '@/plugins/i18n';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ChangePassword",
    props: [],
    data() {
        return {
            dialog: false,
            valid: false,
            changeData: {
                currentPassword: null,
                newPassword: null
            },
            repNewPassword: null,
            passRepRules: [
                (v) => !!v || i18n.t('common.rules.required'),
                (v) => (v || '').length >= 6 || i18n.t('common.rules.lengthMin', { limit: 6 }),
                (v) => (v || '') === this.changeData.newPassword || i18n.t('common.rules.passIdentical'),
            ],

        };
    },
    computed: {
        ...mapGetters({
            changedSuccess: 'general/changed',
            loading: 'general/loading',
            requireRules: 'general/requireRules',
            requireAndMinRules: 'general/requireAndMinRules'
        }),
        items: () => [
            { text: i18n.t('map.styles.satellite'), value: 'satellite-v9' },
            { text: i18n.t('map.styles.streets'), value: 'streets-v11' }
        ],
    },

    created() {
        this.dialog = this.dialogvalue;
    },

    methods: {
        ...mapActions({
            changedPassAction: 'general/changePass'
        }),
        openDialog() {
            this.dialog = true;
        },
        changePass() {
            this.validate();
            if (this.valid) {
                this.changedPassAction([this.changeData, this.$toast]);
                this.dialog = false;
            }
        },
        validateRep() {
            if (this.repNewPassword && this.repNewPassword !== '') {
                this.validate();
            }
        },
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
            this.dialog = false;
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        replace(event) {
            event.currentTarget.value = event.currentTarget.value.toString().replaceAll('"', '')
        },
        replaceCurrentPassword(event) {
            this.changeData.currentPassword = event.currentTarget.value.toString().replaceAll('"', '')
        },
        replaceNewPassword(event) {
            this.changeData.newPassword = event.currentTarget.value.toString().replaceAll('"', '')
        },
        replaceRepNewPassword(event) {
            this.repNewPassword = event.currentTarget.value.toString().replaceAll('"', '')
        },
    }
};
</script>