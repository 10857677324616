import { default as http } from './HttpService'

class LogsService {
  constructor() {
  }

  recordById(id) {
    return http.recordLog.get('/api/v1/logs/byRecordId', {
      params: {
        id: id
      }
    }).catch(error => {
      console.warn("Failed to fetch logs:", error.message);
      // Return a standardized empty response instead of throwing an error
      return {
        data: {
          blockchainSave: false,
          blockchainHash: null,
          url: null
        }
      };
    });
  }
}

export default new LogsService()
