<template>
    <v-dialog v-model="display" :width="dialogWidth">
        <template v-slot:activator="{ on }">
            <v-form ref="form" v-model="valid">
                <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                              color="primary"
                              v-bind="textFieldProps"
                              :disabled="disabled"
                              :loading="loading"
                              v-model="generalDate"
                              v-on="on"
                              readonly
                              clearable
                              :hide-details="hideDetails"
                              @click:clear="clearHandler"
                              @click:append="display = !display"
                              :rules="required ? selectRule.concat(requireRules) : []"
                >
                  <template v-slot:label>
                    {{ label }} <strong v-show="required" class="red--text">*</strong>
                  </template>
                  <template v-slot:progress>
                    <slot name="progress">
                      <v-progress-linear color="primary" indeterminate absolute height="2"/>
                    </slot>
                  </template>
                </v-text-field>
            </v-form>
        </template>
        <v-card>
            <v-card-text class="px-0 py-0" v-if="(!showTime && showDate)">
                <v-date-picker style="-webkit-box-shadow: none"
                               color="primary"
                               item-color="primary"
                               v-model="date"
                               ref='date'
                               v-bind="datePickerProps"
                               :locale="$i18n.locale"
                               :max="maxDate"
                               :min="minDate"
                               full-width
                />
            </v-card-text>
            <v-card-text class="px-0 py-0" v-if="(showTime && !showDate)">
                <v-time-picker
                        color="primary"
                        item-color="primary"
                        style="-webkit-box-shadow: none"
                        ref="timer"
                        class="v-time-picker-custom"
                        v-model="time"
                        v-bind="timePickerProps"
                        :locale="$i18n.locale"
                        format="24hr"
                        full-width
                />
            </v-card-text>
            <v-card-text class="px-0 py-0" v-if="(showTime && showDate)">
                <v-tabs color="primary" v-model="activeTab">
                    <v-tab style="margin: 5px 5px !important;" key="calendar">
                        <slot name="dateIcon">
                            <v-icon>mdi-calendar</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab style="margin: 5px 5px !important;" key="timer">
                        <slot name="timeIcon">
                            <v-icon>mdi-clock</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab-item key="calendar">
                        <v-date-picker style="-webkit-box-shadow: none"
                                       color="primary"
                                       item-color="primary"
                                       v-model="date"
                                       v-bind="datePickerProps"
                                       :locale="$i18n.locale"
                                       :max="maxDate"
                                       :min="minDate"
                                       @input="showTimePicker"
                                       full-width
                        />
                    </v-tab-item>
                    <v-tab-item key="timer">
                        <v-time-picker
                                color="primary"
                                item-color="primary"
                                style="-webkit-box-shadow: none"
                                ref="timer"
                                class="v-time-picker-custom"
                                v-model="time"
                                v-bind="timePickerProps"
                                :locale="$i18n.locale"
                                format="24hr"
                                full-width
                        />
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <slot name="actions" :parent="this">
                    <v-btn color="neuter" small text @click="clearHandler">{{ $t('common.buttons.clean') }}
                    </v-btn>
                    <v-btn color="primary" small elevation="0" class="t-bw-primary--text" @click="okHandler">{{ $t('common.buttons.confirm') }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {format, parse, isValid} from 'date-fns'
    import i18n from '@/plugins/i18n';
    import {mapGetters} from "vuex";

    const DEFAULT_DATE = null;
    const DEFAULT_TIME = null;
    const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
    const DEFAULT_TIME_FORMAT = 'HH:mm';
    const DEFAULT_DIALOG_WIDTH = 340;
    const DEFAULT_CLEAR_TEXT = i18n.t('common.buttons.cancel');
    const DEFAULT_OK_TEXT = i18n.t('common.buttons.confirm');

    export default {
        name: 'v-datetime-picker',
        model: {
            prop: 'datetime',
            event: 'input'
        },
        props: {
            showTime: {
                type: Boolean,
                default: false,
                required: true
            },
            showDate: {
                type: Boolean,
                default: false,
                required: true
            },
            datetime: {
                type: [Date, String, Array],
                default: null
            },
            disabled: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            label: {
                type: String,
                default: ''
            },
            dialogWidth: {
                type: Number,
                default: DEFAULT_DIALOG_WIDTH
            },
            dateFormat: {
                type: String,
                default: DEFAULT_DATE_FORMAT
            },
            timeFormat: {
                type: String,
                default: 'HH:mm'
            },
            clearText: {
                type: String,
                default: DEFAULT_CLEAR_TEXT
            },
            okText: {
                type: String,
                default: DEFAULT_OK_TEXT
            },
            textFieldProps: {
                type: Object,
                default: () => ({
                  color: 'primary',
                  class: 't-bw-primary--text',
                  outlined: true,
                  dense: true
                })
            },
            datePickerProps: {
                type: Object
            },
            timePickerProps: {
                type: Object
            },
            required: {
                type: Boolean,
                default: false
            },
            restrictDate: {
                type: Boolean,
                default: false
            },
            restrictYear: {
              type: [Number, String, null],
              default: null
            },
            hideDetails: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                display: false,
                activeTab: 0,
                date: DEFAULT_DATE,
                time: DEFAULT_TIME,
                generalDate: null,
                valid: false
            }
        },
        mounted() {
            this.init()
        },
        computed: {
            ...mapGetters({
                requireRules: 'general/requireRules',
                selectRule: 'general/selectRule'
            }),
            dateTimeFormat() {
                if (this.showDate === true && this.showTime === true) {
                    return this.dateFormat + ' ' + DEFAULT_TIME_FORMAT
                } else if (this.showDate === true) {
                    return this.dateFormat
                } else if (this.showTime === true) {
                    return DEFAULT_TIME_FORMAT
                }
            },
            defaultDateTimeFormat() {
                if (this.showDate === true && this.showTime === true) {
                    return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
                } else if (this.showDate === true) {
                    return DEFAULT_DATE_FORMAT
                } else if (this.showTime === true) {
                    return DEFAULT_TIME_FORMAT
                }
            },
            formattedDatetime() {
                return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : null
            },
            selectedDatetime() {
                if (this.showDate && this.showTime) {
                    if (this.date && this.time) {
                        let datetimeString = this.date + ' ' + this.time
                        return parse(datetimeString, this.defaultDateTimeFormat, new Date().setSeconds(0))
                    }
                } else if (this.showDate && !this.showTime) {
                    if (this.date) {
                        let dateString = this.date;
                        return parse(dateString, DEFAULT_DATE_FORMAT, new Date())
                    }
                } else if (!this.showDate && this.showTime) {
                    if (this.time) {
                        let timeString = this.time
                        return parse(timeString, DEFAULT_TIME_FORMAT, new Date().setSeconds(0))
                    }
                }
            },
            dateSelected() {
                return !this.date
            },
            maxDate() {
                return this.restrictDate ? format(new Date(), DEFAULT_DATE_FORMAT) : null;
            },
            minDate() {
                if (this.restrictYear) {
                    return format(new Date(this.restrictYear, 0, 1), DEFAULT_DATE_FORMAT);
                }
                return null;
            }
        },
        methods: {
            init() {
                console.log(this.datetime)
                let initDateTime

                if (!this.datetime) {
                  this.$emit('update:datetime', '');
                  initDateTime = '';
                }

                if (this.datetime instanceof Date) {
                    initDateTime = this.datetime;
                } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
                    // see https://stackoverflow.com/a/9436948
                    if (this.showDate && this.showTime) {
                        // Caso: fecha y hora
                        // Asegurarse de que la cadena tenga la forma "yyyy-MM-ddTHH:mm:ss"
                        initDateTime = new Date(this.cleanDateTimeString(this.datetime));
                    } else if (this.showDate) {
                        // Caso: solo fecha
                        // Asegurarse de que la cadena tenga la forma "yyyy-MM-dd"
                        initDateTime = new Date(this.cleanDateString(this.datetime));
                    } else if (this.showTime) {
                        // Caso: solo hora
                        // Asegurarse de que la cadena tenga la forma "HH:mm:ss"
                        initDateTime = new Date(this.cleanTimeString(this.datetime));
                    }
                } else if (this.datetime instanceof Array) {
                    if (this.datetime) {
                        initDateTime = new Date(this.datetime[0]);
                    } else {
                        initDateTime = new Date();
                    }
                }

                if (initDateTime instanceof Date) {
                    this.date = initDateTime ? format(new Date(initDateTime), DEFAULT_DATE_FORMAT) : format(new Date(), DEFAULT_DATE_FORMAT);
                    this.time = initDateTime ? format(new Date(initDateTime), DEFAULT_TIME_FORMAT) : format(new Date(), DEFAULT_TIME_FORMAT);

                    this.$emit('update:datetime', initDateTime)
                }

                this.generalDate = this.formattedDatetime;
            },
            cleanDateTimeString(dateTimeString) {
                // Limpia y formatea la cadena de fecha y hora
                let cleanedString = dateTimeString;

                // Asegurar que tenga la forma "yyyy-MM-ddTHH:mm:ss"
                // Agregar 'T' si falta
                if (!cleanedString.includes('T')) {
                    let parts = cleanedString.split(' ');
                    cleanedString = parts[0] + 'T' + (parts[1] ? parts[1] : '00:00:00');
                }

                // Asegurar que tenga la zona horaria
                if (!cleanedString.endsWith('Z')) {
                    cleanedString += 'Z';
                }

                return cleanedString;
            },

            cleanDateString(dateString) {
                // Limpia y formatea la cadena de solo fecha
                // Asegurar que tenga la forma "yyyy-MM-dd"
                let cleanedString = dateString;

                // Agregar ceros si falta algún componente de la fecha
                let parts = cleanedString.split('-');
                if (parts[0].length < 4) {
                    parts[0] = parts[0].padStart(4, '0');
                }
                if (parts[1] && parts[1].length < 2) {
                    parts[1] = parts[1].padStart(2, '0');
                }
                if (parts[2] && parts[2].length < 2) {
                    parts[2] = parts[2].padStart(2, '0');
                }

                return parts.join('-');
            },

            cleanTimeString(timeString) {
                // Limpia y formatea la cadena de solo hora
                // Asegurar que tenga la forma "HH:mm:ss"
                let cleanedString = timeString;

                // Agregar ceros si falta algún componente de la hora
                let parts = cleanedString.split(':');
                if (parts[0].length < 2) {
                    parts[0] = parts[0].padStart(2, '0');
                }
                if (parts[1] && parts[1].length < 2) {
                    parts[1] = parts[1].padStart(2, '0');
                }
                if (parts[2] && parts[2].length < 2) {
                    parts[2] = parts[2].padStart(2, '0');
                } else if (!parts[2]) {
                    parts.push('00');
                }

                return parts.join(':');
            },
            okHandler() {
                this.generalDate = this.formattedDatetime;
                this.resetPicker()
                this.$emit('input', this.selectedDatetime)
            },
            clearDate(){
                this.date = null;
                this.generalDate = null
                this.$refs.form.resetValidation()
            },
            clearTime(){
                this.time = null;
                this.generalDate = null
                this.$refs.form.resetValidation()
            },
            clearDateTime(){
                this.date = null;
                this.time = null;
                this.generalDate = null
                this.$refs.form.resetValidation()
            },
            clearHandler() {
                this.resetPicker()
                this.date = null;
                this.time = null;
                this.generalDate = null;
                this.$emit('input', null);
            },
            resetPicker() {
                this.display = false
                this.activeTab = 0
                if (this.$refs.timer) {
                    this.$refs.timer.selectingHour = true
                }
            },
            showTimePicker() {
               this.activeTab = 1
            },
            replace(event){
                event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
            }
        },
        watch: {
            datetime: function () {
                this.init()
            },
            valid() {
              console.log(this.valid)
              this.$emit('valid:datetime', this.valid)
            }
        }
    }
</script>
