import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import DocumentsService from "../../../services/DocumentsService";
import StorageService from "../../../services/StorageService";
import _ from "lodash";
import Locations from "@/services/Locations";
import CompactImages from "@/services/CompactImages";

export default {
    loadingON({commit, state}) {
        state.documents = [];
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async fetchListDocuments({dispatch, commit, state}, [toast]) {
        dispatch('loadingON');
        try {
            const {data} = await DocumentsService.documents();
            commit(types.SET_DOCUMENTS_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchListVersions({dispatch, commit, state}, [id, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await DocumentsService.versionsByDocumentId(id);
            commit(types.SET_VERSIONS_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchDocumentById({dispatch, commit, state}, [id, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await DocumentsService.documentById(id);
            commit(types.SET_DOCUMENT, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchListDocumentsFiltered({dispatch, commit, state}, [filter, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await DocumentsService.documentsFiltered(filter);
            commit(types.SET_FILTERS_DOCUMENTS_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchListDocumentsBySearch({dispatch, commit, state}, [filter, toast]) {
        try {
            const {data} = await DocumentsService.filterSearch(filter);
            commit(types.SET_FILTERS_DOCUMENTS_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async saveDocument({dispatch, commit, state}, [document, file, storagesDevices, editOrSave, fileEditChange, renew, toast]) {
        dispatch('loadingON');
        try {
            await DocumentsService.save(document, file, storagesDevices, editOrSave, fileEditChange, renew);
            toast.success(i18n.t('documents.notification.save'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.info(i18n.t('documents.notification.inUse'), {
                    icon: 'mdi-information-slab-circle',
                    queueable: true
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('documents.notification.saveError'), {
                        icon: "mdi-alert-circle",
                        queueable: true
                    });
            }
        }
    },
    async fetchListStorage({dispatch, commit}, [profile, toast]) {
        dispatch('loadingON');
        try {
            const company = profile.company_id;
            const {data} = await StorageService.allStoragesByCompany(company);
            commit(types.SET_STORAGE_IN_DOCUMENT_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storage.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async exportCSV({dispatch, commit}, [filters, toast]) {
        dispatch('loadingON');
        try {
            const { data } = await DocumentsService.exportCSV(filters)
            const FileSaver = require('file-saver');
            const blob = new Blob([data], {
                type: 'application/xlsx'
            });
            FileSaver.saveAs(blob, `${i18n.t('documents.label').toLowerCase()}.xlsx`);

            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.download'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            dispatch('loadingOFF');
        }
    },
    async deleteDocument({commit, state}, [id, toast]) {
        try {
            await DocumentsService.delete(id);
            toast.success(i18n.t('documents.notification.deleted'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('documents.notification.inUse'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('documents.notification.deletedError'), {
                        icon: "mdi-alert-circle",
                        queueable: true
                    });
            }
        }
    },

    addEvidences({commit}, payload) {
        const images = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/png']
        const documents = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'text/csv', 'application/pdf', 'application/zip', 'application/x-zip-compressed', 'application/vnd.rar'];

        if (payload && payload.length > 0) {
            _.forEach(payload, (doc) => {
                if (images.includes(doc.type)) {
                    CompactImages.downscaleImage(0.7, doc, function (data) {
                        if (data.size <= 5000000)
                            commit('SET_EVIDENCES', data)
                    });
                }
                else if (documents.includes(doc.type)) {
                    commit('SET_EVIDENCES', doc)
                }
            });
        } else {
            commit('CLEAR_EVIDENCES')
        }
    },

    clearEvidences({commit}) {
        commit('CLEAR_EVIDENCES')
    },

    removeEvidence({commit}, payload) {
        commit('REMOVE_EVIDENCE', payload)
    }
}
