import * as types from './mutations-type.js';

export default {
    [types.SET_PROCCESS_LIST](state, data) {
        state.proccess = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING](state, data) {
        state.loading = data;
    },
    [types.SET_PROCESS](state, data) {
        state.procces = data;
    },
    [types.SET_GROUP_SELECTED](state, data) {
        state.groupStructuresSelected = data;
    },
    [types.SET_STRUCTURES_SELECTED](state, data) {
        state.structuresSelected = data;
    },
    [types.SET_ORIGINAL_STATE_PROCESS](state) {
        state.procces = {
            company: null,
            language_key: null,
            sdt_to_show: null,
            processType: null,
            isInternal: true,
            processStructureOrganized: null,
            reportType: null,
            automaticTraceability: false,
            massBalanceConfiguration: false,
            convertionFactor: null,
            printLimit: 0,
            redirectQr: null,
            isOrderType: false,
            inheritStatus: false,
            checkStock: false,
            automaticStock: true,
            appearance: {
                icon: 'mdi-sitemap',
                color: '#424242',
            },
        };
        state.structuresSelected = [];
        state.groupStructuresSelected = [];
    },
}
