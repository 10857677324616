import _ from "lodash";
import i18n from '@/plugins/i18n';
import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";

export default {
    loading: state => state.loading,
    error: state => state.error,
    surveyStateEnum() {
        return [
            {id: 1, text: i18n.t('survey.status.EDITING'), type: 'EDITING'},
            {id: 2, text: i18n.t('survey.status.READY_TO_FILL'), type: 'READY_TO_FILL'},
            {id: 3, text: i18n.t('survey.status.FILLING'), type: 'FILLING'},
            {id: 4, text: i18n.t('survey.status.READY_TO_REVIEW'), type: 'READY_TO_REVIEW'},
            {id: 5, text: i18n.t('survey.status.REVIEWING'), type: 'REVIEWING'},
            {id: 6, text: i18n.t('survey.status.PUBLISHED'), type: 'PUBLISHED'}
        ]
    },
    surveyList: (state, getters) => {
        return _.map(state.surveyList, (survey) => {
            const status = _.find(getters.surveyStateEnum, {type: survey.surveyStateType});
            return {
                ...survey,
                surveyStateTypeName: status ? status.text : survey.surveyStateType
            }
        });
    },
    surveyTemplateList: (state, getters) => {
        return sortList.orderListByUppercase(_.map(state.surveyTemplateList, (survey) => {
            const status = _.find(getters.surveyStateEnum, {type: survey.surveyStateType});
            return {
                ...survey,
                surveyStateTypeName: status ? status.text : survey.surveyStateType,
                name: survey.name.trim()
            }
        }), 'name')
    },
    allTemplates: (state, getters) => {
        return sortList.orderListByUppercase(_.map(state.allTemplates, (survey) => {
            const status = _.find(getters.surveyStateEnum, {type: survey.surveyStateType});
            return {
                ...survey,
                surveyStateTypeName: status ? status.text : survey.surveyStateType,
                name: survey.name.trim()
            }
        }), 'name')
    },
    templatesForFilters: (state) => state.templatesForFilters,
    surveyEdit: (state, getters) => {
        const status = _.find(getters.surveyStateEnum, {type: state.surveyEdit.surveyStateType});
        state.surveyEdit.surveyStateTypeName = status ? status.text : state.surveyEdit.surveyStateType;
        state.surveyEdit.questionSectionList = _.map(state.surveyEdit.questionSectionList,(questionSection)=>{
            return{
                ...questionSection,
                questions: _.map(questionSection.questions,(question)=>{
                    const answerFound = _.find(question.possibleAnswer,{answer: question.answer});
                    if (question.extraQuestionFatherRef) {
                        question.extraQuestionFatherRef.possibleAnswer = _.map(question.extraQuestionFatherRef.possibleAnswer, (answerFather) => {
                            return {
                                ...answerFather,
                                answer_name: LanguajeService.getKey3(answerFather.answer)
                            }
                        })
                    }
                    return{
                        ...question,
                        answer_name: LanguajeService.getKey3(question.answer),
                        answer: answerFound ? answerFound : question.answer,
                        possibleAnswer: _.map(question.possibleAnswer,(answer)=>{
                            return{
                                ...answer,
                                answer_name: LanguajeService.getKey3(answer.answer)
                            }
                        })
                    }
                })
            }
        });
        return state.surveyEdit;
    },
    sectionEdit: (state) => {
        state.sectionEdit.questions = _.map(state.sectionEdit.questions,(question)=>{
            const answerFound = _.find(question.possibleAnswer,{answer: question.answer});
            if (question.extraQuestionFatherRef) {
                question.extraQuestionFatherRef.possibleAnswer = _.map(question.extraQuestionFatherRef.possibleAnswer, (answerFather) => {
                    return {
                        ...answerFather,
                        answer_name: LanguajeService.getKey3(answerFather.answer)
                    }
                })
            }
            return{
                ...question,
                answer_name: LanguajeService.getKey3(question.answer),
                answer: answerFound ? answerFound : question.answer,
                possibleAnswer: _.map(question.possibleAnswer,(answer)=>{
                    return{
                        ...answer,
                        answer_name: LanguajeService.getKey3(answer.answer)
                    }
                })
            }
        });
        return state.sectionEdit;
    },
    deletedQuestionsNotifications: state => state.deletedQuestionsNotifications,
}