import i18n from "@/plugins/i18n";
import _ from "lodash";

export default {
  loading: (state) => state.loading,
  loadingMessage: (state) => state.loadingMessage,
  loadingIcon: (state) => state.loadingIcon,
  layout: (state) => state.layout,
  changed: (state) => state.changed,
  config: (state) => state.config,
  color: (state) => state.color,
  countryList: (state) => _.orderBy(state.countryList),
  languageStructure: (state) => state.languageStructure,
  detectCircularReference(obj) {
    let keys = [];
    let stack = [];
    let stackSet = new Set();
    let detected = false;

    function detect(obj, key) {
      if (obj && typeof obj != "object") {
        return;
      }

      if (stackSet.has(obj)) {
        // Si detecta que el objeto es ciclico, hace un print del mismo.
        let oldindex = stack.indexOf(obj);
        let l1 = keys.join(".") + "." + key;
        let l2 = keys.slice(0, oldindex + 1).join(".");
        detected = true;
        return;
      }

      keys.push(key);
      stack.push(obj);
      stackSet.add(obj);
      for (let k in obj) {
        //navegar a traves de los objetos hijos
        if (Object.prototype.hasOwnProperty.call(obj, k)) {
          detect(obj[k], k);
        }
      }

      keys.pop();
      stack.pop();
      stackSet.delete(obj);
      return;
    }

    detect(obj, "obj");
    return detected;
  },
  ethereumAddress() {
    const rules = [];
    const required = (v) => /^0x[a-fA-F0-9]{40}$/.test(v) || 'Invalid address';
    rules.push(required);
    return rules;
  },
  requireRules() {
    const rules = [];
    const required = (v) => v !== null && v !== undefined && v !== '' || i18n.t("common.rules.required");
    rules.push(required);
    return rules;
  },
  checkPrintLimit: () => (oldLimit) => {
    const rules = [];
    const required = (v) => (v > oldLimit) || i18n.t("reports.titles.rulePrintLimit");
    rules.push(required);
    return rules;
  },
  requireAndFlowRules: () => (flow) => {
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    rules.push(required);

    if (!flow) rules.push(i18n.t("common.rules.torra"));

    return rules;
  },
  limitEnterKeyPress() {
    const rules = [];
    const enterKeyPress = (v) =>
      !/\n/.test(v) || i18n.t("common.rules.limitEnterKey");
    rules.push(enterKeyPress);
    return rules;
  },
  onlyDecimalFormat() {
    const rules = [];
    const onlyDecimal = (v) =>
      /^$|^\d*(\.\d)?\d?$/.test(v) || i18n.t("common.rules.onlyNumberFormat");
    rules.push(onlyDecimal);
    return rules;
  },
  onlyCurrencyFormat() {
    const rules = [];
    const onlyDecimal = (v) => {
      console.log(v)
      return /^$|^\d+(\,\d{3})*(\.\d+)?$/.test(v) || i18n.t("common.rules.onlyNumberFormat");
    }

    rules.push(onlyDecimal);
    return rules;
  },
  onlyNumberFormat() {
    const rules = [];
    const onlyNumberFormat = (v) =>
      /^$|^([+-]?0(\.\d+)|[+-]?[1-9]+[0-9]*(\.?\d+)?|0|null)$/.test(v) ||
      i18n.t("common.rules.onlyNumberFormat");
    rules.push(onlyNumberFormat);
    return rules;
  },
  phoneRules() {
    const rules = [];
    const phone = (v) => {
      if (!v || v.trim() === "") return true;
      return /^\+\d{2} \(\d{2}\) \d{4,5}-\d{4}$/im.test(v) || i18n.t("common.rules.phone");
    };

    rules.push(phone);
    return rules;
  },

  latitudeRules() {
    const rules = [];
    const latitude = (v) => {
      if (v === null || v == -1 || 0) return true;
      return /^-?([0-8]?[0-9]\.\d+|90\.0+)$/.test(v) || i18n.t("common.rules.latitude");
    };
    rules.push(latitude);
    return rules;
  },

  longitudeRules() {
    const rules = [];
    const longitude = (v) => {
      if (v === null || v == -1 || 0) return true;
      return /^-?(180(\.0+)?|((1[0-7][0-9])|(0?[0-9]?[0-9]))(\.\d+)?)$/.test(v) || i18n.t("common.rules.longitude");
    };
    rules.push(longitude);
    return rules;
  },

  onlyPositiveNumberFormat() {
    const rules = [];
    const onlyPositiveNumberFormat = (v) =>
      /^[0-9]\d*(\.\d+)?$/.test(v) || i18n.t("common.rules.onlyNumberFormat");
    rules.push(onlyPositiveNumberFormat);
    return rules;
  },

  validUrlOrNull() {
    const rules = [];
    const validUrlOrNull = (v) => {
      if (v === "" || v === null) return null;

      const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      return pattern.test(v) || i18n.t("common.rules.invalidUrlFormat");
    };
    rules.push(validUrlOrNull);
    return rules;
  },

  onlyPositiveNumberFormatAndNull() {
    const rules = [];
    const onlyPositiveNumberFormatAndNull = (v) => {
      if (v === null) return true;
      return /^[0-9]\d*(\.\d+)?$/.test(v) || i18n.t("common.rules.onlyNumberFormat");
    }
    rules.push(onlyPositiveNumberFormatAndNull);
    return rules;
  },
  equalObjectsCompareRule: (state) => (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  },
  lotRules() {
    const rules = [];
    const lot = (v) =>
      /^([\x21-\x22\x25-\x2F\x30-\x39\x3A-\x3F\x41-\x5A\x5F\x61-\x7A]{0,20})$/.test(
        v
      ) || i18n.t("common.rules.gs1Lot");
    rules.push(lot);
    return rules;
  },
  requireAndLotRules() {
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    const lot = (v) =>
      /^([\x21-\x22\x25-\x2F\x30-\x39\x3A-\x3F\x41-\x5A\x5F\x61-\x7A]{0,20})$/.test(
        v
      ) || i18n.t("common.rules.gs1Lot");
    rules.push(required);
    rules.push(lot);
    return rules;
  },
  requireAndMaxRules: (state) => (limit) => {
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    rules.push(required);
    const length = (v) =>
      (v || "").length <= limit ||
      i18n.t("common.rules.length", { limit: limit });
    rules.push(length);
    return rules;
  },

  emailRules() {
    const rules = [];
    const email = (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid';
    rules.push(email);
    return rules;
  },
  requireAndCompareMax: (state) => (limitMin, limitMax) => {
    const rules = [];
    const required = (v) => (v !== null && v !== undefined) || i18n.t("common.rules.required");
    rules.push(required);
    const lengthMax = (v) =>
        (v !== null && v !== undefined && v <= limitMax) ||
        i18n.t("common.rules.lengthAfterPoint", { limit: limitMax });
    const lengthMin = (v) =>
        (v !== null && v !== undefined && v >= limitMin) ||
        i18n.t("common.rules.lengthAfterPointMin", { limit: limitMin });
    rules.push(lengthMin);
    rules.push(lengthMax);
    return rules;
  },
  compareMax: (state) => (limitMin, limitMax) => {
    const rules = [];
    const lengthMax = (v) =>
        (v || "") <= limitMax ||
        i18n.t("common.rules.lengthAfterPoint", { limit: limitMax });
    const lengthMin = (v) =>
        (v || "") >= limitMin ||
        i18n.t("common.rules.lengthAfterPointMin", { limit: limitMin });
    rules.push(lengthMin);
    rules.push(lengthMax);
    return rules;
  },
  validateMoreThan: (state) => (limitMin, limitMax) => {
    const rules = [];
    const lengthMax = (v) =>
        (v || "") <= limitMax ||
        i18n.t("common.rules.max", { limit: limitMax });
    const lengthMin = (v) =>
        (v || "") >= limitMin ||
        i18n.t("common.rules.min", { limit: limitMin });
    rules.push(lengthMin);
    rules.push(lengthMax);
    return rules;
  },
  maxRules: (state) => (limit, item) => {
    const rules = [];
    let length = null;
    if (item && item.includes(".")) {
      length = (v) =>
        (v || "").length <= limit + 1 ||
        i18n.t("common.rules.length", { limit: limit });
    } else {
      length = (v) =>
        (v || "").length <= limit ||
        i18n.t("common.rules.length", { limit: limit });
    }

    rules.push(length);
    return rules;
  },
  requireAndMinRules: (state) => (limit) => {
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    rules.push(required);
    const length = (v) =>
      (v || "").length > limit ||
      i18n.t("common.rules.lengthMin", { limit: limit });
    rules.push(length);
    return rules;
  },
  requireAndMinMax: (state) => (min, max) => {
    const rules = [];
    const minRule = (v) =>
      (v === null || v === undefined || v >= min) ||
      i18n.t("common.rules.minValue", { limit: min });
    rules.push(minRule);
    const maxRule = (v) =>
      (v === null || v === undefined || v <= max) ||
      i18n.t("common.rules.maxValue", { limit: max });
    rules.push(maxRule);
    return rules;
  },
  minMax: (state) => (min, max) => {
    const rules = [];

    const minRule = (v) => {
      if (!v) return true;
      return v >= min || i18n.t("common.rules.minValue", { limit: min });
    };
    rules.push(minRule);

    const maxRule = (v) => {
      if (!v) return true;
      return v <= max || i18n.t("common.rules.maxValue", { limit: max });
    };
    rules.push(maxRule);

    return rules;
  },
  onlyAllowInteger: (state) => () => {
    const rules = [];

    const noAllowInteger = (v) => {
      if (!v) return true;
      const pattern = /^\d+$/;
      return pattern.test(v) || i18n.t("common.rules.onlyNumberFormat");
    };

    rules.push(noAllowInteger);
    return rules;
  },

  arraySelectionRules: (state) => ({ min = 0, max = Number.MAX_SAFE_INTEGER, required = false } = {}) => {
    const rules = [];

    if (required) {
      const isRequired = v => (!!v && v.length > 0) || i18n.t("common.rules.required");
      rules.push(isRequired);
    }

    const minSize = v => (!v || v.length >= min) || i18n.t("common.rules.minSelection", { min });
    rules.push(minSize);

    const maxSize = v => (!v || v.length <= max) || i18n.t("common.rules.maxSelection", { max });
    rules.push(maxSize);

    return rules;
  },
  requireAndGTIN14Rules: (state) => (limit) => {
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    rules.push(required);
    const length = (v) =>
      (v || "").length > limit ||
      i18n.t("common.rules.lengthGTIN14", { limit: limit });
    rules.push(length);
    return rules;
  },
  lengthRules: (state) => (limit) => {
    const rules = [];
    const length = (v) =>
      (v || "").length <= limit ||
      i18n.t("common.rules.length", { limit: limit });
    rules.push(length);
    return rules;
  },
  selectRule() {
    const rules = [];
    const required = (v) =>
      (!!v && Object.entries(v).length !== 0) ||
      i18n.t("common.rules.required");
    rules.push(required);
    return rules;
  },
  multiSelectRule() {
    const rules = [];
    const select = (v) => v >= 0 || i18n.t("common.rules.multiSelectRule");
    rules.push(select);
    return rules;
  },
  onlyImageRules: (state) => (file) => {
    const mimeType = [
      "image/jpeg",
      "image/jpg",
      "image/bmp",
      "image/png"
    ];
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    const size = (v) => !v || v.size <= 5000000 || i18n.t("common.rules.size");
    const fileType = (v) =>
        !v || mimeType.includes(v.type) || i18n.t("common.rules.fileTypeImage");
    rules.push(required);
    rules.push(fileType);
    rules.push(size);
    return rules;
  },
  validateImage: (state) => (file) => {
    const mimeType = [
      "image/jpeg",
      "image/jpg",
      "image/bmp",
      "image/png"
    ];
    const rules = [];
    const size = (v) => !v || v.size <= 5000000 || i18n.t("common.rules.size");
    const fileType = (v) =>
      !v || mimeType.includes(v.type) || i18n.t("common.rules.fileTypeImage");
    rules.push(fileType);
    rules.push(size);
    return rules;
  },
  verifyImageSizeAndFormat: (state) => (files) => {
    let sizeFile = true;
    let typeFile = true;
    const mimeType = [
      'image/jpeg',
      'image/jpg',
      'image/bmp',
      'image/png'
    ];
    if (files)
      _.forEach(files, (archivo) => {
        if (archivo.size > 5000000) {
          return (sizeFile = false);
        }
        if (!mimeType.includes(archivo.type)) {
          return (typeFile = false);
        }
      });

    const rules = [];
    const size = (v) => !v || sizeFile === true || i18n.t("common.rules.size");
    const fileType = (v) =>
      !v || typeFile === true || i18n.t("common.rules.fileSize");
    rules.push(fileType);
    rules.push(size);
    return rules;
  },
  fileTypeRulesAndSize: (state) => (files) => {
    let sizeFile = true;
    let typeFile = true;
    const mimeType = [
      'image/jpeg',
      'image/jpg',
      'image/bmp',
      'image/png',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'text/csv',
      'text/xml',
      'application/pdf',
      'application/zip',
      'application/x-zip-compressed',
      'application/vnd.rar',
    ];
    if (files)
      _.forEach(files, (archivo) => {
        if (archivo.size > 5000000) {
          return (sizeFile = false);
        }
        if (!mimeType.includes(archivo.type)) {
          return (typeFile = false);
        }
      });

    const rules = [];
    const size = (v) => !v || sizeFile === true || i18n.t("common.rules.size");
    const fileType = (v) =>
      !v || typeFile === true || i18n.t("common.rules.fileSize");
    rules.push(fileType);
    rules.push(size);
    return rules;
  },
  requireRulesAndSize: (state) => (file) => {
    const mimeType = [
      "image/jpeg",
      "image/jpg",
      "image/bmp",
      "image/png",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "text/csv",
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
      "application/vnd.rar",
      "text/xml",
    ];
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    const size = (v) => !v || v.size <= 5000000 || i18n.t("common.rules.size");
    const fileType = (v) =>
      !v || mimeType.includes(v.type) || i18n.t("common.rules.fileSize");
    rules.push(required);
    rules.push(fileType);
    rules.push(size);
    return rules;
  },
  requireRulesSizeAndType: (state) => (file) => {
    const mimeType = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    const size = (v) => !v || v.size <= 5000000 || i18n.t("common.rules.size");
    const fileType = (v) =>
      !v || mimeType.includes(v.type) || i18n.t("common.rules.fileTypeXLSX");
    rules.push(required);
    rules.push(fileType);
    rules.push(size);
    return rules;
  },
  requireSize() {
    const rules = [];
    const size = (v) => !v || v.size <= 5000000 || i18n.t("common.rules.size");
    rules.push(size);
    return rules;
  },
  // : (state) => (message) => {
  //   this.rules = [];
  //   const required = (v) => !!v || i18n.t("common.rules.required");
  //   rules.push(required);
    // const existingElement = (v) => message == '' || i18n.t(`notifications.locations.406.${message}`);
    // rules.push(existingElement);
  //   return rules;
  // },
  existingElementGln: (state) => (message) => {
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    rules.push(required);
    if(message != ''){
      const existingElement = (v) => !v || i18n.t(`notifications.locations.406.${message}`);
      rules.push(existingElement);
    }
    return rules;
  },
  existingElementGlnSecondary: (state) => (limit, message) => {
    const rules = [];
    const required = (v) => !!v || i18n.t("common.rules.required");
    rules.push(required);
    const length = (v) =>
      (v || "").length <= limit ||
      i18n.t("common.rules.length", { limit: limit });
    rules.push(length);
    if(message != ''){
      const existingElement = (v) => !v || i18n.t(`notifications.storage.406.${message}`);
      rules.push(existingElement);
    }
    return rules;
  },
  mapConfig: state => state.mapConfig,
  mapConfigFinal: () => {
    return {
      sources: [
        {
          id: "LocationPrimarySource",
          config:{
            type: "geojson",
            data: { type: "FeatureCollection", features: [] },
          }

        },
        {
          id: "LocationSecondarySource",
          config:{
            type: "geojson",
            data: { type: "FeatureCollection", features: [] },
          }
        },
      ],
      layers: [
        {
          id: "LocationPrimaryLayer",
          typeLayers: "fill-Location",
          source: "LocationPrimarySource",
        },
        {
          id: "LocationPrimaryNamesLayer",
          typeLayers: "symbol",
          source: "LocationPrimarySource",
        },
        {
          id: "LocationsBorderLayer",
          typeLayers: "line",
          source: "LocationPrimarySource",
        },
        {
          id: "LocationSecondaryLayer",
          typeLayers: "fill-Storage",
          source: "LocationSecondarySource",
        },
        {
          id: "LocationSecondaryNamesLayer",
          typeLayers: "symbol",
          source: "LocationSecondarySource",
        },
        {
          id: "LocationSecondaryBorderLayer",
          typeLayers: "line",
          source: "LocationSecondarySource",
        },
      ],
    };
  },

  // Records Barcode localStorage
  label_data: (state) => state.label_data,
  pre_records_barcode: (state) => state.pre_records_barcode,
  // Record Edit localStorage
  record_edit: (state) => state.record_edit,
  getRegexValidationRule: (state) => (type) => {
    const regex = new RegExp(state.regex[type]);
    return [
      v => !v || regex.test(v) || i18n.t('common.rules.invalidFormat')
    ];
  }
};
