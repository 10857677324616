import {default as http} from './HttpService'
import i18n, { getFallbackMessage } from '@/plugins/i18n';
import _ from 'lodash';
import { TRANSLATIONS_CONFIG } from '@/module/configuration/translations/configuration';

class LanguajeService {
    constructor() {
        this.customTranslations = {};
        this.pendingUpdates = new Map();
    }

    // Métodos existentes
    capitalizeFirstLetter(text) {
        return text.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }

    languajeKeys() {
        return http.client.get(`v1/language-key`);
    }

    async languageStructure() {
        return await http.client.get(`v1/language-key/structure`);
    }

    getLenguajeName() {
        return this.getLenguajeNameByKey(i18n.locale);
    }

    getLenguajeNameByKey(languajeKey) {
        let languaje = '';
        switch (languajeKey) {
            case 'pt-br':
                languaje = 'PORTUGUESE';
                break;
            case 'en-us':
                languaje = 'ENGLISH';
                break;
            case 'es-es':
                languaje = 'ESPANNOL';
        }
        return languaje;
    }

    getKeyByLenguajeName(languajeName) {
        let key = '';
        switch (languajeName) {
            case 'PORTUGUESE':
                key = 'pt-br';
                break;
            case 'ENGLISH':
                key = 'en-us';
                break;
            case 'ESPANNOL':
                key = 'es-es';
        }
        return key;
    }

    // Nuevos métodos para manejar traducciones personalizadas
    async fetchCustomTranslations(locale, filterModules = false) {
        console.log('=== TRANSLATION SOURCE TRACKING ===');
        console.log(`🔍 Buscando traducciones para locale: ${locale}`);
        try {
            // Obtener el UUID de la compañía del localStorage
            const company = JSON.parse(localStorage.getItem('company:current'));
            if (!company?.identity) {
                console.warn('No company identity found in localStorage');
                return {};
            }

            // Construir URL de S3
            const s3Url = `${process.env.VUE_APP_LANG_URL}/${company.identity}/translations.json`;
            console.log(`🔗 URL de S3: ${s3Url}`);
            
            // Obtener traducciones directamente de S3
            const response = await fetch(s3Url, {
                cache: 'no-store',
                headers: {
                    'Cache-Control': 'no-cache'
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch translations: ${response.statusText}`);
            }

            const translations = await response.json();
            console.log('📦 Traducciones del SERVIDOR:', translations);
            
            // Filtrar módulos excluidos solo si se solicita explícitamente
            if (filterModules) {
                this.customTranslations = Object.keys(translations).reduce((acc, key) => {
                    acc[key] = _.omit(translations[key], TRANSLATIONS_CONFIG.excludedModules);
                    return acc;
                }, {});
            } else {
                this.customTranslations = translations;
            }
            
            this.updateI18nMessages(locale);
            this.printTranslationStats(locale);
            
            return this.customTranslations[locale] || {};
        } catch (error) {
            console.error('❌ Error cargando traducciones:', error);
            return {};
        }
    }

    mergeWithFallback(customTranslations, fallbackMessages) {
        const merged = {};
        
        // Función recursiva para procesar objetos anidados
        const processObject = (custom, fallback, currentPath = '') => {
            // Procesar primero las keys del fallback para asegurarnos de incluir todas
            Object.keys(fallback).forEach(key => {
                const newPath = currentPath ? `${currentPath}.${key}` : key;
                
                if (typeof fallback[key] === 'object' && fallback[key] !== null) {
                    // Si es un objeto, crear la estructura y procesar recursivamente
                    merged[newPath] = merged[newPath] || {};
                    processObject(
                        custom && custom[key] ? custom[key] : {},
                        fallback[key],
                        newPath
                    );
                } else {
                    // Si es un valor, usar el customizado si existe, sino el fallback
                    const customValue = _.get(custom, key);
                    merged[newPath] = customValue !== undefined ? customValue : fallback[key];
                }
            });

            // Procesar las keys adicionales del custom que no estén en el fallback
            if (custom) {
                Object.keys(custom).forEach(key => {
                    const newPath = currentPath ? `${currentPath}.${key}` : key;
                    if (!_.has(fallback, key)) {
                        if (typeof custom[key] === 'object' && custom[key] !== null) {
                            processObject(custom[key], {}, newPath);
                        } else {
                            merged[newPath] = custom[key];
                        }
                    }
                });
            }
        };

        processObject(customTranslations, fallbackMessages);
        return merged;
    }

    async saveCustomTranslation(locale, key, value) {
        console.log(`[Translations] Saving translation - Locale: ${locale}, Key: ${key}, Value: ${value}`);
        try {
            if (!this.customTranslations[locale]) {
                this.customTranslations[locale] = {};
            }

            if (value === null) {
                // Si el valor es null, eliminar la traducción personalizada
                _.unset(this.customTranslations[locale], key);
            } else {
                // Actualizar el valor en el objeto de traducciones
                _.set(this.customTranslations[locale], key, value);
            }

            // Enviar todas las traducciones al servidor
            await http.client.post('v1/configuration/translations', this.customTranslations);

            await this.forceUpdateMessages(locale, key, value);
            const finalMessages = i18n.getLocaleMessage(locale);
            return { success: true, messages: finalMessages };
        } catch (error) {
            console.error('[Translations] Error saving translation:', error);
            throw error;
        }
    }

    async forceUpdateMessages(locale, updatedKey, updatedValue) {
        console.log('[Debug] Force updating messages');
        
        // 1. Empezar con los mensajes base
        let newMessages = _.cloneDeep(getFallbackMessage(locale));
        
        // 2. Aplicar todas las personalizaciones existentes
        const customMessages = this.customTranslations[locale] || {};
        
        // 3. Función recursiva para aplicar traducciones
        const applyCustomTranslations = (custom, base, path = '') => {
            Object.entries(custom).forEach(([key, value]) => {
                const currentPath = path ? `${path}.${key}` : key;
                
                if (value && typeof value === 'object') {
                    if (!base[key]) base[key] = {};
                    applyCustomTranslations(value, base[key], currentPath);
                } else if (value !== undefined && value !== null) {
                    _.set(base, key, value);
                }
            });
        };

        // 4. Aplicar traducciones personalizadas existentes
        Object.entries(customMessages).forEach(([moduleKey, moduleTranslations]) => {
            if (!newMessages[moduleKey]) {
                newMessages[moduleKey] = {};
            }
            applyCustomTranslations(moduleTranslations, newMessages[moduleKey], moduleKey);
        });

        // 5. Aplicar la nueva traducción si existe
        if (updatedValue !== undefined && updatedValue !== null) {
            _.set(newMessages, updatedKey, updatedValue);
        }

        // 6. Actualizar mensajes en i18n
        i18n.setLocaleMessage(locale, newMessages);
    }

    updateCustomTranslation(locale, key, value) {
        if (!this.customTranslations[locale]) {
            this.customTranslations[locale] = {};
        }
        this.customTranslations[locale][key] = value;
        
        // Actualizar i18n
        const messages = i18n.getLocaleMessage(locale);
        _.set(messages, key, value);
        i18n.setLocaleMessage(locale, messages);
    }

    updateI18nMessages(locale) {
        console.log('[Debug] Updating i18n messages for locale:', locale);
        
        // 1. Obtener mensajes base sin filtrar módulos
        const fallbackMessages = getFallbackMessage(locale);
        const customMessages = this.customTranslations[locale] || {};
        
        // 2. Crear copia profunda de los mensajes base
        const newMessages = _.cloneDeep(fallbackMessages);
        
        // 3. Función recursiva para aplicar traducciones personalizadas
        const applyCustomTranslations = (custom, base, path = '') => {
            Object.entries(custom).forEach(([key, value]) => {
                const currentPath = path ? `${path}.${key}` : key;
                
                if (value && typeof value === 'object') {
                    // Si es un objeto, procesar recursivamente
                    if (!base[key]) base[key] = {};
                    applyCustomTranslations(value, base[key], currentPath);
                } else if (value !== undefined && value !== null) {
                    // Si es un valor, aplicar la traducción personalizada
                    _.set(base, key, value);
                    console.log(`[Debug] Applied custom translation: ${currentPath} = ${value}`);
                }
            });
        };

        // 4. Aplicar traducciones personalizadas por módulo
        Object.entries(customMessages).forEach(([moduleKey, moduleTranslations]) => {
            if (!newMessages[moduleKey]) {
                newMessages[moduleKey] = {};
            }
            applyCustomTranslations(moduleTranslations, newMessages[moduleKey], moduleKey);
        });

        console.log('[Debug] Final messages structure:', {
            fallback: Object.keys(fallbackMessages),
            custom: Object.keys(customMessages),
            merged: Object.keys(newMessages)
        });
        
        // 5. Actualizar i18n con los mensajes mezclados
        i18n.setLocaleMessage(locale, newMessages);
    }

    async initializeTranslations() {
        console.log('[Translations] Initializing translations');
        const locale = i18n.locale;
        console.log(`[Translations] Current locale: ${locale}`);
        await this.fetchCustomTranslations(locale);
        console.log('[Translations] Initialization complete');
    }

    async changeLanguaje(languajeName) {
        const newLocale = this.getKeyByLenguajeName(languajeName);
        if (newLocale === i18n.locale) return; // No hacer nada si es el mismo idioma
        
        try {
            i18n.locale = newLocale;
            await this.fetchCustomTranslations(newLocale);
            return true;
        } catch (error) {
            console.error('Error changing language:', error);
            throw error;
        }
    }

    // Métodos existentes actualizados para usar traducciones personalizadas
    getKey(key) {
        let language = this.getLenguajeName();
        let translates = JSON.parse(localStorage.getItem('languaje_keys'));
        const customTranslation = this.customTranslations[i18n.locale]?.[key];
        return customTranslation || (translates[language][key] ? translates[language][key] : false);
    }

    getKey2(key, uppercase) {
        let language = this.getLenguajeName();
        let translates = JSON.parse(localStorage.getItem('languaje_keys'))[language];
        const customTranslation = this.customTranslations[i18n.locale]?.[key];
        const translation = customTranslation || (translates[key] ? translates[key] : key);
        return uppercase ? translation.toUpperCase() : translation;
    }

    // Los métodos getKey3, setKey3 y validateLabels se mantienen igual
    getKey3(translatesArrayText, uppercase) {
        if (translatesArrayText) {
            let language = this.getLenguajeName();
            let translates = _.find(translatesArrayText, {'language': language});
            return translates ? (uppercase ? translates.label.toUpperCase() : translates.label) : translatesArrayText;
        } else {
            return null
        }
    }

    setKey3(key, translatesArrayText, languageStructure) {
        let language = this.getLenguajeName();
        if (languageStructure)
            translatesArrayText = languageStructure;

        if (key === null)
            key = "";

        return _.map(translatesArrayText, (translate) => {
            return {
                ...translate,
                label: translate.language === language ? key : languageStructure ? key : translate.label
            }
        });
    }

    validateLabels(array) {
        if (Array.isArray(array))
            return array.every(item => item.label === "" || item.label === undefined);

        return true;
    }

    calculateTranslationStats(locale) {
        // No filtrar módulos aquí
        const fallbackMessages = getFallbackMessage(locale);
        const serverTranslations = this.customTranslations[locale] || {};

        // Función para contar strings en un objeto anidado
        const countStrings = (obj, prefix = '') => {
            const result = new Map();
            
            Object.entries(obj).forEach(([key, value]) => {
                const fullKey = prefix ? `${prefix}.${key}` : key;
                
                if (typeof value === 'string') {
                    result.set(fullKey, value);
                } else if (typeof value === 'object' && value !== null) {
                    const childStrings = countStrings(value, fullKey);
                    childStrings.forEach((val, key) => result.set(key, val));
                }
            });
            
            return result;
        };

        // Obtener traducciones como Maps para fácil comparación
        const localStrings = countStrings(fallbackMessages);
        const serverStrings = countStrings(serverTranslations);

        // Módulos raíz (primer nivel de objetos)
        const localModules = new Set(Object.keys(fallbackMessages));
        const serverModules = new Set(Object.keys(serverTranslations));

        // Calcular intersecciones
        const allKeys = new Set([...localStrings.keys(), ...serverStrings.keys()]);
        const commonKeys = new Set([...localStrings.keys()].filter(x => serverStrings.has(x)));
        const uniqueLocalKeys = new Set([...localStrings.keys()].filter(x => !serverStrings.has(x)));
        const uniqueServerKeys = new Set([...serverStrings.keys()].filter(x => !localStrings.has(x)));

        // Contar overrides reales
        let overrideCount = 0;
        commonKeys.forEach(key => {
            if (serverStrings.get(key) !== localStrings.get(key)) {
                overrideCount++;
            }
        });

        return {
            localCount: localStrings.size,
            serverCount: serverStrings.size,
            commonCount: commonKeys.size,
            uniqueLocalCount: uniqueLocalKeys.size,
            uniqueServerCount: uniqueServerKeys.size,
            totalMerged: allKeys.size,
            overrideCount,
            moduleCount: new Set([...localModules, ...serverModules]).size
        };
    }

    printTranslationStats(locale) {
        const stats = this.calculateTranslationStats(locale);
        
        console.log('\n=== TRANSLATION STATISTICS ===');
        console.table({
            'Locale': {
                value: locale,
                details: 'Current language'
            },
            'Root Modules': {
                value: stats.moduleCount,
                details: 'Top level translation modules'
            },
            'Local Files': {
                value: stats.localCount,
                details: 'Translations from local JSON files'
            },
            'Server': {
                value: stats.serverCount,
                details: 'Translations from backend'
            },
            'Common Keys': {
                value: stats.commonCount,
                details: 'Keys present in both sources'
            },
            'Only in Local': {
                value: stats.uniqueLocalCount,
                details: 'Keys only in local files'
            },
            'Only in Server': {
                value: stats.uniqueServerCount,
                details: 'Keys only in server'
            },
            'Modified by Server': {
                value: stats.overrideCount,
                details: 'Server overriding local values'
            }
        });
    }

    getFilteredStats(moduleId, translations) {
        const countTranslations = (obj, basePath = '') => {
            let count = 0;
            let customCount = 0;

            const traverse = (current, path = '') => {
                for (const key in current) {
                    const fullPath = path ? `${path}.${key}` : key;
                    
                    if (typeof current[key] === 'string') {
                        count++;
                        // Verificar si existe una traducción diferente en el servidor
                        const serverValue = _.get(this.customTranslations[i18n.locale], fullPath);
                        const localValue = current[key];
                        
                        if (serverValue !== undefined && serverValue !== localValue) {
                            customCount++;
                            console.log(`[Debug] Modified translation found at ${fullPath}:`, {
                                local: localValue,
                                server: serverValue
                            });
                        }
                    } else if (typeof current[key] === 'object' && current[key] !== null) {
                        traverse(current[key], fullPath);
                    }
                }
            };

            const moduleData = _.get(obj, basePath);
            if (moduleData) {
                traverse(moduleData);
            }

            return {
                total: count,
                customized: customCount,
                groups: moduleData ? Object.keys(moduleData).filter(key => 
                    typeof moduleData[key] === 'object' && moduleData[key] !== null
                ).length : 0
            };
        };

        return countTranslations(translations, moduleId);
    }

    async restoreTranslations() {
        await http.client.post('v1/configuration/translations', {});
    }
}

export default new LanguajeService();