import _ from "lodash";
import LanguajeService from "../../../services/LanguajeService";
import state from "./state";

export default {
    loading: state => state.loading,
    record: state => state.record,
    recordToShow: state => {
        if (state.record) {
            return {
                ...state.record,
                process: state.record.process ? {
                    ...state.record.process,
                    name: LanguajeService.getKey3(state.record.process.language_key)
                } : null
            }
        }
        return null
    },
    evidences: state => state.evidences,
    tabIndex: state => state.tabIndex,
    source: state => state.source,
    request: state => state.request,
    defaultHeaders: state => state.defaultHeaders,
    headers: state => state.headers,
    cantToExport: state => state.cantToExport,
    recordsForPrint: state => state.recordsForPrint,
    dialogDetails: state => state.dialogDetails,

    recordsByCompany: state => _.map(state.recordsByCompany, (item) => {
            return {
                ...item,
                process_name: LanguajeService.getKey3(item.process.language_key),
                sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label ? item.sdi_origin.control_label : 'N/A',
                sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.control_label : 'N/A',
                person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
                sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            };
    }),
    recordsCompanyById: state =>{
        return {
            process_name: LanguajeService.getKey3(state.record.process.language_key),
            sdi_origin: state.record.sdi_origin && state.record.sdi_origin.control_label  ? state.record.sdi_origin.primaryLocation.name + ' | ' + state.record.sdi_origin.control_label : 'N/A',
            sdi_destination:  state.record.sdi_destination && state.record.sdi_destination.control_label ? state.record.sdi_destination.primaryLocation.name + ' | ' + state.record.sdi_destination.control_label : 'N/A',
            person_in_charge_name: state.record.person_in_charge && state.record.person_in_charge.name ? state.record.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(state.record.process.sdt_to_show.language_key),
            record_date_time: state.record.record_date_time,
            sent_date_time: state.record.sent_date_time,
            uniqueIdRepresentation: state.record.uniqueIdRepresentation
        };
    },
    recordHeaders: state => state.recordHeaders,
    recordsByCompanyTotalCount: state => state.recordsByCompanyTotalCount,

    recordsByCompanyWithoutStage: state => _.map(state.recordsByCompanyWithoutStage, (item) => {
        return {
            ...item,
            process_name: LanguajeService.getKey3(item.process.language_key) ,
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label ? item.sdi_origin.control_label : 'NaN',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.control_label : 'NaN',
            sdi_origin_location_name: item.sdi_origin && item.sdi_origin.control_label  ? item.sdi_origin.primaryLocation.name + ' | ' + item.sdi_origin.control_label: 'NaN',
            sdi_destination_location_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.primaryLocation.name + ' | ' + item.sdi_destination.control_label: 'NaN',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : 'NaN',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key)
        };
    }),
    recordsByCompanyTotalCountWithoutStage: state => state.recordsByCompanyTotalCountWithoutStage,

    recordsToExports: state => _.map(state.recordsToExports, (item) => {
        return {
            ...item,
            process_name: LanguajeService.getKey3(item.process.language_key),
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label  ? item.sdi_origin.primaryLocation.name + ' | ' + item.sdi_origin.control_label: '-',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.primaryLocation.name + ' | ' + item.sdi_destination.control_label: '-',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            recordStructureValueDTOListNames: item.recordStructureValues && item.recordStructureValues.length > 0 ? _.map(item.recordStructureValues, (recordStructure) => {
                return {
                    ...recordStructure,
                    structure_name_translate: LanguajeService.getKey3(recordStructure.structure.language_key),
                    groupStructure_name: recordStructure.groupStructures ? ((d) => {d['group_details_name'] = LanguajeService.getKey3(d.languageKey); return [d];}) (recordStructure.groupStructures) : [],
                }
            }) : item.recordStructureValues
        };
    }),

    recordsToTraceabilityReport: state => _.map(state.recordsToTraceabilityReport, (item) => {
        return {
            ...item,
            process_name: LanguajeService.getKey3(item.process.language_key) ,
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label ? item.sdi_origin.control_label : '-',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.control_label : '-',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            recordStructureValueDTOListNames: item.recordStructureValues && item.recordStructureValues.length > 0 ? _.map(item.recordStructureValues, (recordStructure) => {
                return {
                    ...recordStructure,
                    structure_name_translate: recordStructure ? LanguajeService.getKey3(recordStructure.structure.language_key) : recordStructure.structure.language_key,
                    groupStructure_name: recordStructure.groupStructures ? ((d) => {d['group_details_name'] = LanguajeService.getKey3(d.languageKey); return [d];}) (recordStructure.groupStructures) : [],
                }
            }) : item.recordStructureValues
        };
    }),

    recordsIdsByLot: state => _.map(state.recordsToTraceabilityReport, (item) => {
        return item.id;
    }),

    recordsIds: state => _.map(state.recordsByCompanyFiltered, (item) => {
        return item.id;
    }),

    exportUrl: state => state.exportUrl,

    structures:  state => _.orderBy(_.map(state.structures, (item) => {
        return {
            ...item,
            structure_name: LanguajeService.getKey3(item.language_key)
        };
    }), ['structure_name']),

    recordsByCompanyFiltered: state => _.map(state.recordsByCompanyFiltered, (item) => {
        return {
            ...item,
            process_name:LanguajeService.getKey3(item.process.language_key) ,
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label  ? item.sdi_origin.primaryLocation.name + ' / ' + item.sdi_origin.control_label: '-',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.primaryLocation.name + ' / ' + item.sdi_destination.control_label: '-',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            recordStructureValueDTOListNames: item.recordStructureValues && item.recordStructureValues.length > 0 ? _.map(item.recordStructureValues, (recordStructure) => {
                return {
                    ...recordStructure,
                    structure_name_translate: recordStructure ? LanguajeService.getKey3(recordStructure.structure.language_key) : recordStructure.structure.language_key,
                    groupStructure_name: recordStructure.groupStructures ? ((d) => {d['group_details_name'] = LanguajeService.getKey3(d.languageKey); return [d];}) (recordStructure.groupStructures) : [],
                }
            }) : item.recordStructureValues
        };
    }),

    recordLog: state => state.recordLog,
    checkBurdensError: state => state.checkBurdensError,
    checkBurdensErrorData: state => state.checkBurdensErrorData
}
