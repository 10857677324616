<template>
  <v-overlay :value="showing" color="#FFFFFF" opacity="1" z-index="9999">
    <v-progress-linear top indeterminate fixed color="neuter" />
    <v-row justify="center">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <v-icon class="my-4" color="gray darken-1" size="80">{{ icon }}</v-icon>
        <transition name="fade">
          <div class="loading-text mx-4 neuter--text"><code>{{ label }}</code></div>
        </transition>
      </v-col>
    </v-row>
    <div class="footer__splash">
      <p class="py-1">powered by <strong>AgTrace</strong></p>
    </div>
  </v-overlay>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import { mapGetters } from "vuex"

  export default {
    name: "Splash",
    computed: {
      ...mapGetters({
        loadInitData: "general/loading",
        loadingMessage: "general/loadingMessage",
        loadingIcon: "general/loadingIcon",
      }),
      label() {
        return this.loadingMessage || i18n.t("common.titles.loading")
      },
      icon() {
        return this.loadingIcon || ''
      },
      showing() {
        return this.loadInitData
      },
    },
  }
</script>

<style scoped lang="scss">
  .loading-text {
    font-size: 1.2em;
    color: #333;
  }

  .v-overlay {
    color: var(--v-secondary-base) !important;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  :deep(svg) {
    width: 500px !important;
    height: 500px !important;
  }

  .svg-container {
    width: 500px;
    height: 350px;
    overflow: hidden;
    position: relative;
  }

  .footer__splash {
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--v-neuter-base);
    color: var(--v-t-bw-neuter-base);
    text-align: center;
  }
</style>
