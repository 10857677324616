import * as types from "../store/mutations-type";
import i18n from '@/plugins/i18n';
import InspectionsService from "../../../services/InspectionsService"
import moment from "moment";
import AppointmentsService from '@/services/AppointmentsService'

export default {
    loadingON({commit, state}) {
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    errorON({commit}) {
        commit(types.SET_ERROR_ON, true);
    },
    errorOFF({commit}) {
        commit(types.SET_ERROR_OFF, false);
    },
    async fetchListSurvey({dispatch, commit, state}, [filter, toast]) {
        dispatch('loadingON');
        state.inspectionList = [];
        try {
            const {data} = await InspectionsService.survey(filter);
            commit(types.SET_SURVEY_BY_COMPANY_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                dispatch('loadingOFF');
                toast.error(i18n.t('survey.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },
    async fetchListInspectionsBySearch({dispatch, commit, state}, [filter, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await InspectionsService.filterSearch(filter);
            commit(types.SET_SURVEY_BY_COMPANY_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });

            dispatch('loadingOFF');
        }
    },
    async saveQuestion({commit, state, dispatch}, [item, files, toast]) {
        try {
            const {data} = await InspectionsService.questionEdit(item, files);
            commit(types.SET_QUESTION, data);
            toast.success(i18n.t('survey.notification.save'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.saveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            dispatch('errorON');
        }
    },
    async saveSurvey({commit, state, dispatch}, [item, editOrSave, files, document, signature, toast]) {
        try {
            const {data} = await InspectionsService.save(item, editOrSave, files, document, signature);
            commit(types.SET_INSPECTION, data);
            toast.success(i18n.t('survey.notification.save'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.saveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            dispatch('errorON');
        }
    },
    async fetchInspectionById({dispatch, commit, state}, [id, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await InspectionsService.inspectionById(id);
            commit(types.SET_INSPECTION, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchRangeScore({dispatch, commit, state}, [company]) {
        try {
            const {data} = await InspectionsService.getRangeScore(company);
            commit(types.SET_SCORE, data);
        } catch (error) {
        }
    },
    async fetchInspectionsToExport({dispatch, commit, state}, [filters, toast]) {
        state.surveyToExports = [];
        try {
            const { data } = await InspectionsService.getInspectionsToExport(filters);
            commit(types.SET_INSPECTION_TO_EXPORT, data);
            if(!data.length > 0){
                toast.error(i18n.t('survey.notification.surveyCero'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchCantToExport({dispatch, commit, state}, [filters, toast]) {
        state.cantToExport = 0
        try {
            const { data } = await InspectionsService.cantToExport(filters);
            commit(types.SET_CANT_TO_EXPORT, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    icon: 'mdi-check-circle',
                    queueable: true
                });
        }
    },
    async exportInspections({commit, state}, [filters, type, toast, isAsync = false]) {
        try {
            const { data } = await InspectionsService.export(filters, type, isAsync);

            if(!isAsync){
                if(!data){
                    toast.info(i18n.t('records.notification.recordsCero'), {
                        icon: 'mdi-information-slab-circle',
                        queueable: true
                    });
                }

                const FileSaver = require('file-saver');

                switch(type) {
                    case "pdf":
                        FileSaver.saveAs(data, `${i18n.t('inspections.label').toLowerCase()}.pdf`);
                        return
                    case "csv":
                        FileSaver.saveAs(data, `${i18n.t('inspections.label').toLowerCase()}.xlsx`);
                        return
                }
            } else {
                toast.success(i18n.t('common.notification.exportAsyncRecords'), {
                    queueable: true,
                    icon: 'mdi-check-circle',
                });
            }
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('records.notification.exportError'), {
                    icon: 'mdi-check-circle',
                    queueable: true
                });
        }
    },

    async fetchTemplate({ commit, state }, [options, toast]) {
        try {
            const { data } = await InspectionsService.fetchTemplate(options)

            const FileSaver = require('file-saver')
            const blob = new Blob([data], {
                type: 'application/xlsx',
            })
            FileSaver.saveAs(blob, `inspection-template.xlsx`)

            toast.success(i18n.t('common.notification.downloadTemplateSuccess'), {
                queueable: true,
                icon: 'mdi-check-circle',
            })
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('common.notification.downloadTemplateError'), {
                    queueable: true,
                })
        }
    },

    importInspections({ commit, state }, [options, file, toast]) {
        return new Promise((resolve, reject) => {
            InspectionsService.import(options, file)
              .then((res) => {
                  switch (res.data.importState) {
                      case "ERRORED":
                          toast.error(i18n.t('common.notification.importError'), {
                              queueable: true,
                          })
                          reject(res.data)
                          break
                      case "SUCCESSFUL":
                          toast.success(i18n.t('common.notification.importSuccess'), {
                              queueable: true,
                              icon: 'mdi-check-circle',
                          })
                          resolve(res.data)
                          break
                      case "PARTIALLY":
                          toast.warning(i18n.t('common.notification.importSuccess'), {
                              queueable: true,
                              icon: "mdi-alert",
                          })
                          resolve(res.data)
                          break
                  }
              })
              .catch((err) => {
                  reject(err)

                  if (err.message !== 'cancelRequest')
                      toast.error(i18n.t('common.notification.importError'), {
                          queueable: true,
                      })
              })
        })
    },
    async fetchCertificate({commit}, payload) {
        const { data }  = await InspectionsService.fetchCertificate(payload)

        try {
            if (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(data.type)) {
                const file = new Blob([data], { type: "image/jpeg" })
                commit('SET_BLOB', URL.createObjectURL(file))
            } else {
                const file = new Blob([data], { type: "application/pdf" })
                commit('SET_BLOB', URL.createObjectURL(file))
            }
        } catch (e) {
            console.log(e)
        }

    },

    downloadCertificate({ state }) {
        if (state.blob) {
            InspectionsService.downloadPdfFromBlob(state.blob, 'certificate.pdf');
        } else {
            console.error('No blob found in store to download.');
        }
    }
}
