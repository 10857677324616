import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import AuthorityService from "../../../../services/AuthorityService";
import RolesService from "../../../../services/RolesService";
import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import store from '../../../../store'

export default {
    loadingON({commit, state}) {
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async fetchListAuthority({dispatch, commit, state}, [profile, toast]) {
        state.authorities = [];
        dispatch('loadingON');
        try {
            const company = profile.company_id;
            const { data } = await AuthorityService.authorities(company);
            commit(types.SET_AUTHORITY_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchAuthority({commit, state}, [id, toast]) {
        state.authority = [];
        try {
            const { data } = await AuthorityService.authorityById(id);
            commit(types.SET_AUTHORITY, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchListAuthorityProcess({dispatch, commit, state}, [authority, toast]) {
        state.authoritiesProcess = [];
        dispatch('loadingON');
        try {
            const {data} = await AuthorityService.authoritiesProcess(authority);
            commit(types.SET_AUTHORITY_PROCESS_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },

    async getLocationsByAuthority({dispatch, commit, state}, [payload, toast]) {
        state.authoritiesLocations = [];
        dispatch('loadingON');
        try {
            const {data} = await AuthorityService.authoritiesLocations(payload);
            commit(types.SET_AUTHORITY_LOCATIONS_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },

    async getAuthoritiesStorageDevices({dispatch, commit, state}, [payload, toast]) {
        state.authoritiesStoragesDevices = [];
        dispatch('loadingON');
        try {
            const {data} = await AuthorityService.authoritiesStorageDevices(payload);
            commit(types.SET_AUTHORITY_STORE_DEVICE, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async fetchListAuthorityProcessEdit({dispatch, commit, state}, [authority, toast]) {
        state.authoritiesProcessEdit = [];
        dispatch('loadingON');
        try {
            const {data} = await AuthorityService.authoritiesProcess(authority);
            commit(types.SET_AUTHORITY_PROCESS_LIST_EDIT, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },

    async fetchListAuthorityRoles({dispatch, commit, state}, [profile, toast]) {
        state.roles = [];
        dispatch('loadingON');
        try {
            const {data} = await RolesService.roles(profile.company_id);
            let final_data = [];
            data.map((role) => {
                final_data.push({id: role.id, language_key: role.language_key})
            });
            commit(types.SET_AUTHORITY_ROLE_LIST, final_data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },

    async saveAuthority({commit, state}, [item, editOrSave, toast]) {
        try {
            await AuthorityService.save(item, editOrSave);
            toast.success(i18n.t('authority.notification.save'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.info(i18n.t('authority.notification.inUse'), {
                    queueable: true,
                    icon: "mdi-alert-circle",
                });
            } else if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('authority.notification.saveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },
    async pushProcess({commit, state}, [item, toast]) {
        try {
            await AuthorityService.pushProcess(item);
            toast.success(i18n.t('authority.notification.save'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.info(i18n.t('authority.notification.inUse'), {
                    queueable: true,
                    icon: "mdi-alert-circle",
                });
            } else if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('authority.notification.saveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },
    async pushLocations({commit, state}, [item, toast]) {
        try {
            await AuthorityService.pushLocations(item);
            toast.success(i18n.t('authority.notification.save'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.info(i18n.t('authority.notification.inUse'), {
                    queueable: true,
                    icon: "mdi-alert-circle",
                });
            } else if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('authority.notification.saveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },

    async createConfigUser({commit, state}, [config, toast]) {
        try {
            await AuthorityService.authorityConfigCreate(config);
            toast.success(i18n.t('common.notification.configSave'), {
                icon: 'mdi-check-circle',
                queueable: true
            });
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('common.notification.configSaveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },

    async loadConfigUser({commit, state, rootGetters, rootState}, [toast]) {
        const storages = rootGetters['storage_type/storageDeviceTypesActiveByCompany']

        try {
            await AuthorityService.authorityConfigLoad().then((response) => {
                console.log(response.data)
                const { data } = response

                if (storages.length > 0) {
                    _.forEach(storages, (stage) => {
                        rootState.general.config.structures.byGroup[stage.storage_device_type_id] = {
                            headers: [],
                            processed: [],
                        }
                    })
                }

                rootState.general.config.structures.simples = {
                    headers: [],
                    processed: [],
                }

                _.forEach(data.configurations_grouped_by_storage_device_type, (type) => {
                    const storage  = _.find(storages, { 'storage_device_type_id': type.storage_device_type_id })
                    if (storage) {
                        _.forEach(type.structures, (structure) => {
                            if (rootState.general.config.structures.byGroup[storage.storage_device_type_id]) {
                                rootState.general.config.structures.byGroup[storage.storage_device_type_id].headers.push({
                                    ...structure,
                                    structure_name: LanguajeService.getKey3(structure.language_key)
                                })
                                rootState.general.config.structures.byGroup[storage.storage_device_type_id].processed.push({
                                    text: LanguajeService.getKey3(structure.language_key),
                                    value: LanguajeService.getKey3(structure.language_key),
                                    val: LanguajeService.getKey3(structure.language_key),
                                    type: 'text',
                                    width: LanguajeService.getKey3(structure.language_key).length * 10,
                                    sortable: false,
                                })
                            }
                        })
                    }
                })

                _.forEach(data.simple_configurations.structures, (structure) => {
                    rootState.general.config.structures.simples.headers.push({
                        ...structure,
                        structure_name: LanguajeService.getKey3(structure.language_key)
                    })
                    rootState.general.config.structures.simples.processed.push({
                        text: LanguajeService.getKey3(structure.language_key),
                        value: LanguajeService.getKey3(structure.language_key),
                        val: LanguajeService.getKey3(structure.language_key),
                        type: 'text',
                        width: LanguajeService.getKey3(structure.language_key).length * 10,
                        sortable: false,
                    })
                })

                // --------------------------------
                // General (Report)
                // -------------------------------
                rootState.general.config.reports.general.userData = data.general_report.show_company_data

                // --------------------------------
                // Company (Export)
                // -------------------------------
                rootState.general.config.company.export.recordExportLimit = data.companyConfigurations && data.companyConfigurations.recordExportLimit
                    ? data.companyConfigurations.recordExportLimit
                    : 0
                rootState.general.config.company.export.inspectionExportLimit = data.companyConfigurations && data.companyConfigurations.inspectionExportLimit
                    ? data.companyConfigurations.inspectionExportLimit
                    : 0
                rootState.general.config.company.import.recordImportLimit = data.companyConfigurations && data.companyConfigurations.recordImportLimit
                    ? data.companyConfigurations.recordImportLimit
                    : 0
                
                // Fix: Corrected the condition logic for min and max height
                rootState.general.config.company.locations.minHeight = data.companyConfigurations && data.companyConfigurations.locationMinHeight !== undefined
                    ? data.companyConfigurations.locationMinHeight
                    : null
                rootState.general.config.company.locations.maxHeight = data.companyConfigurations && data.companyConfigurations.locationMaxHeight !== undefined
                    ? data.companyConfigurations.locationMaxHeight
                    : null

                // --------------------------------
                // Company (After Point)
                // -------------------------------
                rootState.general.config.company.traceability.structure.number.afterPoint =
                  data.companyConfigurations && data.companyConfigurations.companyStructuresConfigurations
                    ? data.companyConfigurations.companyStructuresConfigurations.afterPoint
                    : 0;
            })
            // commit the config
        } catch (error) {
            if (storages.length > 0) {
                _.forEach(storages, (stage) => {
                    rootState.general.config.structures.byGroup[stage.storage_device_type_id] = {
                        headers: [],
                        processed: [],
                    }
                })
            }

            if (error.code !== 'ERR_CANCELED') {
                toast.error(i18n.t('Error al cargar la configuracion'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
        finally {
            if (localStorage.getItem('config')) {
                localStorage.removeItem('config')
                localStorage.setItem('config', JSON.stringify(rootState.general.config))
            } else {
                localStorage.setItem('config', JSON.stringify(rootState.general.config))
            }

            const userConfig = JSON.parse(localStorage.getItem('config'))
            store.commit('general/SET_CONFIG', userConfig)
        }
    },
}
