<template>
  <v-list dense style="padding: 30px">
    <v-list-item-content
      class="pa-0"
      v-for="item in newArrayFilters"
      :key="item.title"
      v-if="checkFilterType(item.title)"
      link
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title class="d-inline-flex align-center">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          {{ $t('common.fields.filterBy') +" "+ $t(item.translationPath) }}
        </v-list-item-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-if="item.type === 'text'"
              small
              flat
              solo
              background-color="#EAEAEA80"
              color="primary"
              :label="$t('common.fields.enterText')"
              clearable
              v-mask="item.mask"
              v-model="producerFilters[item.title]"
            ></v-text-field>
            <v-text-field
              v-if="item.type === 'phone'"
              small
              flat
              solo
              background-color="#EAEAEA80"
              color="primary"
              :label="$t('common.fields.enterText')"
              clearable
              v-mask="item.mask"
              @input="item.action"
              v-model="producerFilters[item.title]"
            ></v-text-field>
            <v-autocomplete
              v-if="item.type === 'autocomplete'"
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              color="primary"
              clearable
              item-color="primary"
              :items="item.options"
              item-text="name"
              item-value="value"
              v-model="producerFilters.isActive"
              small-chips
              :label="$t(item.placeholder)"
            >
              <template v-slot:selection="{ item:option }">
                <v-chip>
                  <span>{{ option.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-if="item.type === 'country'"
              :items="item.options()"
              :loading="loadingCountries"
              :error="errorCountries"
              :no-data-text="$t('common.fields.noDataAvailable')"
              small
              flat
              solo
              background-color="#EAEAEA80"
              color="primary"
              item-text="name"
              item-value="value"
              v-model="producerFilters.country"
              clearable
              small-chips
              :label="$t(item.translationPath)"
            />
            <LocationFilters
              v-if="item.type === 'locations'"
              :storage-label="$t('storage.label')"
              :location-label="$t('locations.label')"
              storage-ref="storageProducerFilters"
              location-ref="locationProducerFilters"
              @update:locations:origin="$set(producerFilters, 'locations', $event)"
              @update:storages:origin="$set(producerFilters, 'storageDevices', $event)"
              @on:valid-form="$emit('on:valid-form', $event)"
              :show-origin="true"
              :show-destination="true"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item-content>
  </v-list>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import LocationFilters from "@/components/filters/LocationFilters.vue"
  import MapsServices from "@/services/GeolocationService";

  export default {
    components: { LocationFilters },
    data() {
      return {
        loadingCountries: false,
        errorCountries: false,
        countries: [],
        isValidSelection: true,
        newArrayFilters: [],
        fields: ["locations", "name", "cpf", "rg", "inscriptionCode", "address", "state", "city", "country", "zipCode", "phone", "email", "stringFilter", "isActive"],
        orderList: [
          35,
          36,
          46,
          44,
          45,
          40,
          43,
          37,
          38,
          39,
          41,
          42,
          47,
          48,
        ],
        filters: [
          {
            title: "locations",
            icon: "mdi-map-marker-radius-outline",
            translationPath: "records.filters.locations",
            section: null,
            disposition: 35,
            type: "locations"
          },
          {
            title: "stringFilter",
            icon: "mdi-text",
            translationPath: "producer.fields.text",
            section: null,
            disposition: 36,
            type: "text"
          },
          {
            title: "name",
            icon: "mdi-account-outline",
            translationPath: "producer.fields.name",
            section: null,
            disposition: 37,
            type: "text"
          },
          {
            title: "cpf",
            icon: "mdi-card-account-details-outline",
            translationPath: "producer.fields.cpf",
            section: null,
            disposition: 38,
            type: "text",
            mask: '###.###.###-##'
          },
          {
            title: "inscriptionCode",
            icon: "mdi-barcode-scan",
            translationPath: "producer.fields.inscriptionCode",
            section: null,
            disposition: 39,
            type: "text"
          },
          {
            title: "rg",
            icon: "mdi-card-account-details-outline",
            translationPath: "producer.fields.rg",
            section: null,
            disposition: 40,
            type: "text"
          },
          {
            title: "zipCode",
            icon: "mdi-map-marker-outline",
            translationPath: "producer.fields.zipCode",
            section: null,
            disposition: 41,
            type: "text"
          },
          {
            title: "phone",
            icon: "mdi-phone-outline",
            translationPath: "producer.fields.phone",
            section: null,
            disposition: 42,
            type: "phone",
            mask: "+55 (##) #####-####",
            action: (e) => this.updateMask(e)
          },
          {
            title: "email",
            icon: "mdi-email-outline",
            translationPath: "producer.fields.email",
            section: null,
            disposition: 43,
            type: "text"
          },
          {
            title: "address",
            icon: "mdi-home-outline",
            translationPath: "producer.fields.address",
            section: null,
            disposition: 44,
            type: "text"
          },
          {
            title: "state",
            icon: "mdi-map-outline",
            translationPath: "producer.fields.state",
            section: null,
            disposition: 45,
            type: "text"
          },
          {
            title: "city",
            icon: "mdi-city",
            translationPath: "producer.fields.city",
            section: null,
            disposition: 46,
            type: "text"
          },
          {
            title: "country",
            icon: "mdi-flag-outline",
            translationPath: "producer.fields.country",
            section: null,
            disposition: 47,
            type: "country",
            options: () => this.countries
          },
          {
            title: "isActive",
            icon: "mdi-check-circle-outline",
            translationPath: "producer.fields.isActive",
            placeholder: "documents.titles.selectStatus",
            section: null,
            disposition: 48,
            type: "autocomplete",
            options: [
              {
                id: 1,
                value: true,
                name: i18n.t('documents.filters.isActive.activated')
              },
              {
                id: 2,
                value: false,
                name: i18n.t('documents.filters.isActive.desactivated')
              }
            ]
          },
        ]
      }
    },
    created() {
      this.filterAndSortItems(this.filters);
      this.fetchCountries();
      this.source = 'producer'
    },
    computed: {
      producerFilters: {
        get() {
          return this.$store.getters["ui/GET_PRODUCER_FILTERS"]
        },
        set(value) {
          this.$store.commit("ui/PRODUCER_TO_FILTERS", value)
        },
      },
      source: {
        get() {
          return this.$store.getters['ui/GET_FILTER_SOURCE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_SOURCE', val)
        },
      },
    },
    methods: {
      updateMask(value) {
        console.log(value)
        if (value && value.length >= 6 && value[5] !== '(') {
          this.phoneMask = '+55 (##) #####-####';
        } else {
          this.phoneMask = '+55 (##) ####-####';
        }
      },
      async fetchCountries() {
        this.loadingCountries = true;
        this.errorCountries = false;
        try {
          const response = await MapsServices.fetchAllCountries();
          this.countries = response.data.countries;
        } catch (error) {
          this.errorCountries = true;
          console.error("Failed to fetch countries", error);
        } finally {
          this.loadingCountries = false;
        }
      },
      filterAndSortItems(items, sortBy = "disposition") {
        this.$set(this, 'newArrayFilters', []);

        if (!(this.orderList.length > 0)) {
          this.$set(this, 'newArrayFilters', items);
        } else {
          const filteredItems = items.filter(item => this.orderList.includes(item[sortBy]));

          const sortedItems = this.orderList.map(value => filteredItems.find(item => item[sortBy] === value)).filter(item => item !== undefined);

          this.$set(this, 'newArrayFilters', sortedItems);
        }

        console.log(this.newArrayFilters)
      },
      checkFilterType(title) {
        const validTitles = [...this.fields];
        return validTitles.includes(title)
      },
    },
  }
</script>
